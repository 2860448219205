import React, { memo, useMemo } from "react"
import dynamic from "next/dynamic"

export type KosmoMapContextType = {
  KosmoMap: React.FC<{}> | undefined
}

export const KosmoMapContext = React.createContext<KosmoMapContextType>({
  KosmoMap: undefined,
})

export const MapProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const MemoedMap = useMemo(
    () => dynamic(() => import("@/modules/shared/Map/Map"), { ssr: false }),
    []
  )
  return (
    <KosmoMapContext.Provider
      value={{
        KosmoMap: memo((props) => {
          return (
            <div className="w-full h-full" id="kosmo-map" key="kosmo-map">
              <MemoedMap {...props} />
            </div>
          )
        }),
      }}
    >
      {children}
    </KosmoMapContext.Provider>
  )
}
