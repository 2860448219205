import { useEffect, useState } from "react"
import { FormInstance } from "antd"
import {
  CoordinatesLatLng,
  DropoffDetails,
  PickupDetailsType,
  ICreateSingleOrder,
} from "@/types/createSingleOrder.types"
import useInputPhoneNumber from "./useInputPhoneNumber"
import { ICustomer } from "@/api/customers"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import { postStore } from "@/api/stores"
import { v4 as uuidv4 } from "uuid"
import { IPrediction } from "./autocomplete/GooglePlacesAutocomplete.types"
import { cloneDeep } from "lodash"
import { CountryCode } from "libphonenumber-js"
import { StopType } from "@/types/orders.types"
import { useGeocoding } from "./useGeocoding"
import { KosmoGeocodeResult } from "@/types/here.types"
import { getDateTimesFromDayRange } from "@/utils/arrivalTimes"
import { useClients } from "./useClients"

interface IUseKosmoFormsHooks {
  onLocationSearchChange: (e: any) => void
  handlePhoneSelect: (countryCode: string) => void
  handleCustomerSelection: (
    selectedCustomer: ICustomer,
    id?: string,
    callback?: any,
    previousDropoffs?: DropoffDetails[]
  ) => any
  geocodeAutocompletePrediction: (
    prediction: IPrediction,
    country?: string,
    latLng?: CoordinatesLatLng
  ) => Promise<KosmoGeocodeResult | undefined>
  verifyPickupFormCompletion: () => void
  saveStore: (store: PickupDetailsType) => Promise<PickupDetailsType>
  postCode: string | undefined
  searchValue: string
  countryCode: string
  phoneCode: string
  isAddressValid: boolean
  formPickupCoordinates: CoordinatesLatLng
  setIsAddressValid: (value: boolean) => void
  isDisabled: boolean
  isLoading: boolean
  setIsDisabled: (isDisabled: boolean) => void
  setSearchValue: (searchValue: string) => void
  city: string | undefined
}

export const useDeliveryForm = (
  form: FormInstance,
  type: "pickup" | "dropoff" = "pickup"
): IUseKosmoFormsHooks => {
  const [postCode, setPostCode] = useState<string>()
  const [city, setCity] = useState<string>()
  const [searchValue, setSearchValue] = useState("")
  const [isAddressValid, setIsAddressValid] = useState(type === "dropoff")
  const [isDisabled, setIsDisabled] = useState(type === "pickup")
  const [isLoading, setIsLoading] = useState(false)
  const [formPickupCoordinates, setFormPickupCoordinates] = useState<CoordinatesLatLng>({
    lat: 0,
    lng: 0,
  })
  const { countryCode, handlePhoneSelect, phoneCode } = useInputPhoneNumber(form, "phone")
  const { geocodeByAddress } = useGeocoding()
  const saveStore = async (store: PickupDetailsType) => {
    try {
      setIsLoading(true)
      setIsDisabled(true)
      const { data } = await postStore(store)
      return data
    } catch (error) {
      throw error
    } finally {
      setIsDisabled(false)
      setIsLoading(false)
    }
  }
  const { clients } = useClients()

  const geocodeAutocompletePrediction = async (
    prediction: IPrediction,
    country?: string,
    latLng?: CoordinatesLatLng
  ): Promise<KosmoGeocodeResult | undefined> => {
    try {
      const result = await geocodeByAddress(prediction?.description, { country, latLng })
      if (!result) {
        throw "No result available"
      }
      const { postalCode, city } = result?.address
      setCity(city)
      setPostCode(postalCode || undefined)
      setSearchValue(result?.rawAddress)
      if (type === "pickup") {
        setFormPickupCoordinates(result?.location)
      }

      setIsAddressValid(true)
      return result
    } catch (error) {
      console.log(error, "predictions")
      return
    }
  }

  const onLocationSearchChange = (e: any) => {
    setSearchValue(e.target?.value)
    form.setFieldsValue({
      address: e.target?.value,
    })
    // TODO: In review => this change means as soon as a customer changes the address it is considered incorrect
    // until he reselects a prediction
    setIsAddressValid(false)
  }

  const handleCustomerSelection = async (
    selectedCustomer: ICustomer,
    id?: string,
    callback?: any,
    previousDropoffs?: DropoffDetails[]
  ) => {
    try {
      let geocodeResult: KosmoGeocodeResult | undefined

      if (
        !selectedCustomer?.latitude ||
        !selectedCustomer.longitude ||
        selectedCustomer.latitude === 0 ||
        selectedCustomer.longitude === 0
      ) {
        geocodeResult = await geocodeByAddress(selectedCustomer.address)
      }

      setSearchValue(selectedCustomer.address)
      let fromCustomerData: Partial<ICreateSingleOrder>
      const client = clients.find((client) => client.clientId === selectedCustomer.clientId)

      // If id, means edit a stop point
      if (id && previousDropoffs?.findIndex((dropOff) => dropOff.id === id) !== -1) {
        const newDropOffDetails = cloneDeep(previousDropoffs)
        const index = newDropOffDetails?.findIndex((dropOff) => dropOff.id === id)
        if ((index && index < 0 && index !== undefined) || !newDropOffDetails) {
          return
        }
        newDropOffDetails[index!] = {
          ...newDropOffDetails[index!],
          name: selectedCustomer.firstName,
          address: selectedCustomer.address,
          buildingNumber: selectedCustomer.buildingNumber,
          phone: selectedCustomer?.phone
            ? formatPhoneNumber(selectedCustomer.phone, countryCode as CountryCode)
            : "",
          email: selectedCustomer.email,
          latitude: selectedCustomer.latitude || geocodeResult?.location.lat || 0,
          longitude: selectedCustomer.longitude || geocodeResult?.location.lng || 0,
          postalCode: selectedCustomer.postalCode || geocodeResult?.address?.postalCode,
          metadata: {
            ecommerceIntegrationId: "",
            clientId: selectedCustomer.clientId,
          },
          expectedTimeAtStop: (client?.timeAtStop || 0) * 60,
          ...(client?.arrivalTimes && {
            expectedArrival: getDateTimesFromDayRange(client?.arrivalTimes),
          }),
          type: StopType.DROPOFF,
        }

        fromCustomerData = { dropOffDetails: newDropOffDetails }

        if (callback) {
          await callback(newDropOffDetails)
        }
      }
      // Else means add a stop point
      else {
        fromCustomerData = {
          dropOffDetails: [
            {
              id: uuidv4(),
              name: selectedCustomer.firstName,
              address: selectedCustomer.address,
              buildingNumber: selectedCustomer.buildingNumber,
              phone: selectedCustomer?.phone
                ? formatPhoneNumber(selectedCustomer.phone, countryCode as CountryCode)
                : "",
              notes: "",
              email: selectedCustomer.email,
              latitude: selectedCustomer.latitude || geocodeResult?.location.lat || 0,
              longitude: selectedCustomer.longitude || geocodeResult?.location.lng || 0,
              postalCode: geocodeResult?.address?.postalCode || geocodeResult?.address?.label,
              metadata: {
                ecommerceIntegrationId: "",
                clientId: selectedCustomer.clientId,
              },
              expectedTimeAtStop: (client?.timeAtStop || 0) * 60,
              ...(client?.arrivalTimes && {
                expectedArrival: getDateTimesFromDayRange(client?.arrivalTimes),
              }),
              type: StopType.DROPOFF,
            },
          ],
        }
      }

      if (callback) {
        callback(fromCustomerData?.dropOffDetails || [])
      }

      form.setFieldsValue({
        name: selectedCustomer.firstName,
        address: selectedCustomer.address,
        buildingNumber: selectedCustomer.buildingNumber,
        phone: selectedCustomer.phone
          ? formatPhoneNumber(selectedCustomer.phone, countryCode as CountryCode)
          : "",
        email: selectedCustomer.email,
        postalCode: selectedCustomer.postalCode || geocodeResult?.address?.postalCode,
        expectedTimeAtStop: (client?.timeAtStop || 0) * 60,
        ...(client?.arrivalTimes && {
          expectedArrival: getDateTimesFromDayRange(client?.arrivalTimes),
        }),
      })
      setIsAddressValid(true)

      return geocodeResult
    } catch (error) {
      console.log(error, "parsing customer")
      return
    }
  }

  // This could be removed
  const verifyPickupFormCompletion = () => {
    const { name, alias } = form.getFieldsValue()
    const shouldDisable = !isAddressValid || !name || !alias
    setIsDisabled(shouldDisable)
  }
  useEffect(() => {
    if (type === "pickup") {
      verifyPickupFormCompletion()
    }
  }, [phoneCode, isAddressValid])

  return {
    city,
    saveStore,
    geocodeAutocompletePrediction,
    onLocationSearchChange,
    handlePhoneSelect,
    searchValue,
    postCode,
    countryCode: countryCode,
    phoneCode: phoneCode,
    verifyPickupFormCompletion,
    handleCustomerSelection,
    isAddressValid,
    setIsAddressValid,
    isDisabled,
    isLoading,
    setIsDisabled,
    setSearchValue,
    formPickupCoordinates,
  }
}
