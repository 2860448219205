import axios from "@/modules/shared/AxiosInterceptor"
import {
  AppConfiguration,
  PriceConfiguration,
  PriceConfigurations,
} from "@/types/drivesAppSettings"

export async function getPriceConfiguration() {
  return axios
    .get<PriceConfigurations>("/ownfleet/config/price")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putPriceConfiguration(config: PriceConfiguration) {
  return axios
    .put<PriceConfigurations>("/ownfleet/config/price", { ...config })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function deletePriceConfiguration(configId: string) {
  return axios
    .delete(`/ownfleet/config/price/${configId}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getAppConfiguration() {
  return axios
    .get<AppConfiguration>("/ownfleet/config/app")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putAppConfiguration(config: AppConfiguration) {
  return axios
    .put<AppConfiguration>("/ownfleet/config/app", { ...config })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
