import { FloatInput } from "@/UI/Inputs/FloatInput"
import { faBuilding } from "@fortawesome/free-solid-svg-icons"
import { Form } from "antd"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import React from "react"

interface IBusinessNameFormItemProps {
  validationErrorText: string
  placeholder: string
  name: string
  required?: boolean
  size?: SizeType
}

export const BusinessNameFormItem: React.FC<IBusinessNameFormItemProps> = ({
  validationErrorText,
  placeholder,
  name,
  required = false,
  size,
}) => {
  return (
    <Form.Item
      id={name}
      name={name}
      validateTrigger="onSubmit"
      rules={[
        {
          required: required,
          message: validationErrorText,
        },
      ]}
    >
      <FloatInput
        name={name}
        prefixIcon={faBuilding}
        size={size}
        // Error label does not exist
        // @ts-ignore
        placeholder={placeholder}
      />
    </Form.Item>
  )
}
