import React, { useEffect, useContext, useState } from "react"
import axios from "axios"

import { Switch, Typography, Space } from "antd"
import { useTranslation } from "react-i18next"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"

import { NEW_ORDERS_PAGE_PATH } from "@/constants/routes"

export type SandboxContextType = {
  enabled: boolean
}

export const SandboxContext = React.createContext<SandboxContextType>({
  enabled: false,
})

export const SandboxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [enabled, setEnabled] = useState(false)
  const { sandboxRouter } = useSandboxRouter()

  const toggleHeader = () => {
    if (sandboxRouter.isSandboxPath()) {
      setEnabled(true)
    } else {
      setEnabled(false)
      delete axios.defaults.headers.common["x-sandbox"]
    }
  }

  useEffect(() => {
    toggleHeader()
  }, [sandboxRouter.asPath])

  useEffect(() => {
    toggleHeader()
  }, [])

  return <SandboxContext.Provider value={{ enabled }}>{children}</SandboxContext.Provider>
}

const SandboxSwitcher: React.FC<{}> = () => {
  const { t } = useTranslation()
  const sandbox = useContext(SandboxContext)
  const { sandboxRouter } = useSandboxRouter()

  const isDisabled = sandboxRouter.pathname === NEW_ORDERS_PAGE_PATH

  return (
    <Space>
      <label htmlFor="sandbox-switcher">
        <Typography.Text
          strong
          className={`select-none cursor-pointer ${isDisabled ? "text-gray-400" : ""}`}
        >
          {t("common.Nav.sandboxSwitcherTitle")}
        </Typography.Text>
      </label>
      <Switch
        id="sandbox-switcher"
        disabled={isDisabled}
        checked={sandbox.enabled}
        onChange={() => {
          if (sandbox.enabled) {
            sandboxRouter.push(sandboxRouter.pathname.replace("/test", ""))
          } else {
            sandboxRouter.push(`/test${sandboxRouter.pathname}`)
          }
        }}
      />
    </Space>
  )
}

export default SandboxSwitcher
