import React, { Dispatch, ReactElement, SetStateAction, useState } from "react"
import { Modal, Typography } from "antd"

import { useTranslation } from "react-i18next"
import CreateLocationSettingsForm from "@/modules/shared/StoreForms/CreateLocationSettingsForm"

interface IAddLocationModalProps {
  button: ReactElement
  parentModalOpenControl?: boolean
  setParentModalOpenControl?: Dispatch<SetStateAction<boolean>>
}

const AddLocationModal: React.FC<IAddLocationModalProps> = ({
  button,
  parentModalOpenControl,
  setParentModalOpenControl,
}) => {
  const { t } = useTranslation()
  const { Title } = Typography
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  return (
    <>
      {React.cloneElement(button, {
        onClick: setParentModalOpenControl ? () => setParentModalOpenControl(true) : showModal,
      })}
      <Modal
        open={isModalVisible || parentModalOpenControl}
        centered={true}
        className="editProfileModal"
        footer={null}
        closable={false}
        destroyOnClose={true}
        zIndex={3000}
      >
        <Title style={{ marginBottom: "0.5em" }} level={4}>
          {t("profile.AddStoreModal.addNewLocation")}
        </Title>
        <CreateLocationSettingsForm
          callback={() => {
            if (setParentModalOpenControl) {
              setParentModalOpenControl(false)
            } else {
              setIsModalVisible(false)
            }
          }}
        />
      </Modal>
    </>
  )
}

export default AddLocationModal
