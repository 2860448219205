import { DropoffDetails } from "@/types/createSingleOrder.types"
import { LocationDetailsType } from "@/types/orders.types"
import { CountryCode } from "libphonenumber-js"

export interface ICountry {
  countryCode: CountryCode
  phoneCode: string
}

const { countries }: { countries: ICountry[] } = require("@/constants/countryCodes.json")

export const countryPhoneCodeMap = countries.reduce(
  (result: Record<any, ICountry>, current: ICountry): Record<any, ICountry> => {
    const phoneCodeFormatted = current.phoneCode
    result[phoneCodeFormatted] = current
    return result
  },
  {}
)

export const countryCodeMap = countries.reduce(
  (result: Record<any, ICountry>, current: ICountry): Record<any, ICountry> => {
    result[current.countryCode] = current
    return result
  },
  {}
)

export const findCountryCodeFromPhone = (phone: string) => {
  let countryCode: ICountry | undefined = undefined
  let i = 1
  while (!countryCode && i <= phone.length) {
    countryCode = countryPhoneCodeMap[phone.slice(0, i) + " "]
    i++
  }

  return countryCode
}

export const getDisplayAddress = (location?: LocationDetailsType, isShort: boolean = false) => {
  if (!location) {
    return ""
  }
  if (isShort) {
    return location.address.split(",", 2)?.join(", ")
  }
  return `${location.address}${location.buildingNumber ? `, #${location.buildingNumber} ` : ""}`
}

export const getDropoffDetailsAddress = (dropoffDetails?: DropoffDetails, isShort: boolean = false) => {
  if (!dropoffDetails) {
    return ""
  }
  if (isShort) {
    return dropoffDetails.address.split(",", 2)?.join(", ")
  }
  return `${dropoffDetails.address}${dropoffDetails.buildingNumber ? `, #${dropoffDetails.buildingNumber} ` : ""}`
}

// Converts SG to SGP
export const convertCountryCodeTo3Size = (countryCode: string) => {
  switch (countryCode) {
    case "SG":
      return "SGP"
    case "MY":
      return "MYS"
    case "ID":
      return "IDN"
    case "TH":
      return "THA"
    case "PH":
      return "PHL"
    case "BR":
      return "BRA"
    case "CO":
      return "COL"
    case "DE":
      return "DEU"
    case "IT":
      return "ITA"
    case "ES":
      return "ESP"
    case "FR":
      return "FRA"
    case "NL":
      return "NLD"
    case "HK":
      return "HKG"
    case "JP":
      return "JPN"
    default:
      return countryCode
  }
}
