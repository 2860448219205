import {
  ErrorNotification,
  ErrorNotificationForRouteOptimization,
} from "@/UI/Notifications/NotificationTemplate.component"
import { abortControllerAtom } from "@/atoms/abortControllerAtom"

import { defaultUserState, userAtom } from "@/atoms/userAtom"
import { NEW_ORDERS_PAGE_PATH } from "@/constants/routes"
import { getKosmoError } from "@/utils/errors"
import { getActiveOrg, getActiveTokens, setTokens } from "@/utils/localStorage"
import { useAuth0 } from "@auth0/auth0-react"
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import React from "react"

const validPaths = [NEW_ORDERS_PAGE_PATH]

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ROUTE,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  withCredentials: true,
})

// Axios instance without interceptors (avoid infinite loop)
const uninterceptedAxiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ROUTE,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  withCredentials: true,
})

const AxiosInterceptor: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  const setUser = useSetRecoilState(userAtom)
  const user = useRecoilValue(userAtom)
  const [abortController, setAbortController] = useRecoilState(abortControllerAtom)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (user.id) {
      axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
        if (config && config.headers) {
          config.headers["x-user-id"] = user.id
          axios.defaults.headers.common["x-user-id"] = user.id
        }

        return config
      })
    } else {
      delete axios.defaults.headers.common["x-user-id"]
    }
  }, [user.id])

  useEffect(() => {
    // Set token interceptor
    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      const { accessToken } = getActiveTokens()
      if (!accessToken) {
        delete axios.defaults.headers.common["Authorization"]
        return config
      }
      if (config && config.headers) {
        config.headers["Authorization"] = `Bearer ${accessToken}`
      }
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`

      return config
    })

    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config && config.headers) {
        config.headers["app-version"] = process.env.NEXT_PUBLIC_APP_VERSION || ""
      }
      axios.defaults.headers.common["app-version"] = process.env.NEXT_PUBLIC_APP_VERSION || ""

      return config
    })
    // Set x-org-id interceptor
    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      // For referral we do not use x-org-id as user does not have org at this stage and proc 403
      if (config.url === "users/referral" && config.method === "patch") {
        delete axios.defaults.headers.common["x-org-id"]
        return config
      }
      const activeOrg = getActiveOrg()
      if (config && config.headers && activeOrg) {
        config.headers["x-org-id"] = activeOrg
        axios.defaults.headers.common["x-org-id"] = activeOrg
      } else {
        delete axios.defaults.headers.common["x-org-id"]
      }

      return config
    })

    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.url === "/draft-order/" && config.method === "get") {
        if (abortController?.getDraftOrders?.abort) {
          abortController?.getDraftOrders?.abort()
        }
        const getDraftController = new AbortController()
        setAbortController((controller) => ({
          ...controller,
          getDraftOrders: getDraftController,
        }))
        config.signal = getDraftController.signal
      }
      return config
    })

    axiosInstance.interceptors.response.use((response: AxiosResponse) => {
      if (response.config?.url === "/draft-order/" && response?.config?.method === "get") {
        setAbortController((controller) => ({
          ...controller,
          getDraftOrders: null,
        }))
      }
      return response
    })

    // Refresh token and error interceptor - Can be improved
    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        if (error?.response?.status === 401) {
          try {
            const token = await getAccessTokenSilently({
              authorizationParams: {
                scope: "openid profile email offline_access",
              },
            })

            setTokens({ accessToken: token })
            const retryConfig = {
              ...error.config,
              headers: {
                ...error.config.headers,
                ["Authorization"]: `Bearer ${token}`,
              },
            }

            return uninterceptedAxiosInstance
              .request(retryConfig)
              .then((response) => response)
              .catch((error) => {
                throw error
              })
          } catch (error: any) {
            if (error?.reponse?.status === 401 || error?.error === "login_required") {
              setUser({ ...defaultUserState, userFetched: true })
              const currentLocation = window.location.pathname.split("/")[1] as any
              if (validPaths.includes(currentLocation)) {
                window.location = "/" as Location & string
              }
            }
          }
        }

        const data = error?.response?.data
        const kosmoErr = getKosmoError(data?.key?.toString())
        if (!kosmoErr.skip && error?.message !== "canceled") {
          if (data?.key === "route_optimization_failed") {
            // "code":            tpError.Code,
            // "message":         tpError.Message,
            // "possible_reason": tpError.PossibleReason,
            // "solution":        tpError.Solution,
            ErrorNotificationForRouteOptimization(
              t,
              t(kosmoErr?.translationKey || kosmoErr.message),
              data?.metadata?.message,
              data?.metadata?.possible_reason,
              data?.metadata?.solution
            )
          } else {
            ErrorNotification(t(kosmoErr?.translationKey || kosmoErr.message), "")
          }
        }
        throw error
      }
    )
  }, [])

  return <>{children}</>
}

export default axiosInstance

export { AxiosInterceptor }
