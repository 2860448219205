import { userAtom } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"
import moment from "moment-timezone"
import { getTimezonesForCountry } from "countries-and-timezones"

const useTimezone = () => {
  const { org } = useRecoilValue(userAtom)
  const { country } = org
  return getTimezone(country)
}

// TODO: Remove this function after all countries are migrated to the new timezone operations
// where timezone is configured per account / or somehow else
export const getTimezone = (country: string): string => {
  const tzSetting = localStorage.getItem("userTimezone")
  let tz = getDefaultTimezoneForCountry(country)
  if (tzSetting === "auto") {
    tz = moment.tz.guess()
  } else if (tzSetting === "default") {
    tz = getDefaultTimezoneForCountry(country)
  } else if (tzSetting) {
    tz = tzSetting
  }
  return tz
}

const getDefaultTimezoneForCountry = (country: string): string => {
  switch (country) {
    case "RU":
      return "Europe/Moscow"
    case "US":
      return "America/Los_Angeles"
    case "AU":
      return "Australia/Sydney"
    case "CN":
      return "Asia/Shanghai"
    case "CA":
      return "America/Toronto"
    case "KZ":
      return "Asia/Almaty"
    case "BR":
      return "America/Sao_Paulo"
    case "ID":
      return "Asia/Jakarta"
    case "IN":
      return "Asia/Kolkata"
    case "MY":
      return "Asia/Kuala_Lumpur"
    case "BR":
      return "America/Sao_Paulo"
    case "ES":
      return "Europe/Madrid"
    case "FR":
      return "Europe/Paris"
    case "NL":
      return "Europe/Amsterdam"
    case "UZ":
      return "Asia/Tashkent"
    case "GB":
      return "Europe/London"
    case "UA":
      return "Europe/Kiev"
    case "NZ":
      return "Pacific/Auckland"
    case "MN":
      return "Asia/Ulaanbaatar"
    case "MX":
      return "America/Mexico_City"
    default:
      const tz = getTimezonesForCountry(country)?.[0]?.name
      return tz || "Asia/Singapore"
  }
}

export default useTimezone
