import axios from "axios"

export async function getVersion(): Promise<{ version: string }> {
  try {
    const response = await axios
      .get("/api/version")

    return response.data
  } catch (error) {
    throw error
  }
}
