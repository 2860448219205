import { defaultDimensions } from "@/constants/parcel"
import axios from "@/modules/shared/AxiosInterceptor"
import { Location, StopType } from "@/types/orders.types"
import { IQuotePayload } from "@/types/quotes.types"
import {
  DropoffDetails,
  PickupDetailsType,
  ICreateSingleOrder,
} from "@/types/createSingleOrder.types"
import { splitFullName } from "@/utils/formatFullName"

export function getQuotes(quotePayload: Partial<IQuotePayload>, orgId?: string) {
  let baseUrl = `/get-quotes`

  return axios
    .post(`${baseUrl}`, { ...quotePayload, orgId, returnFailed: true })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function patchQuote(quoteId: string, phone: string) {
  const baseUrl = `/quotes`

  return axios
    .patch(`${baseUrl}`, { quoteId, phone })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function formatQuotePayload(createOrderPayload: ICreateSingleOrder) {
  const { pickupDetails, dropOffDetails, deliveryType } = createOrderPayload
  const isPickupValid =
    pickupDetails.id || (pickupDetails.latitude !== 0 && pickupDetails.longitude !== 0)

  if (!isPickupValid) {
    return {}
  }

  // TODO: handle empty object
  const quotePayload: Partial<IQuotePayload> = {}
  quotePayload.recipients = dropOffDetails?.map((dropoff) => formatSenderRecipientPayload(dropoff))

  if (deliveryType?.schedule) {
    quotePayload.schedule = {
      pickup_at: deliveryType?.schedule,
      pickupAt: deliveryType?.schedule,
    }
  }

  if (pickupDetails.id) {
    // Handle store or coordinates payload
    quotePayload.sender = {
      id: pickupDetails.id,
      type: StopType.PICKUP,
    }

    return quotePayload
  } else if (pickupDetails.latitude !== 0 && pickupDetails.longitude !== 0) {
    quotePayload.sender = formatSenderRecipientPayload(pickupDetails)

    return quotePayload
  } else {
    return {}
  }
}

export function formatSenderRecipientPayload(
  dropoffDetails: PickupDetailsType | DropoffDetails
): Location {
  const splitName = splitFullName(dropoffDetails.name)

  const payload: Location = {
    firstName: splitName.firstName || "",
    lastName: splitName.lastName || "",
    email: dropoffDetails?.email,
    organizationName: "",
    phone: dropoffDetails.phone || "".replace(/\+/g, "").replace(/\s/g, ""),
    location: {
      ...(dropoffDetails?.id ? { id: dropoffDetails?.id } : {}),
      alias: "alias" in dropoffDetails ? dropoffDetails.alias : "",
      address: dropoffDetails.address || "",
      latitude: dropoffDetails.latitude,
      longitude: dropoffDetails.longitude,
      postalCode: (dropoffDetails.postalCode as string) || "",
      city: dropoffDetails.city || "",
      country: dropoffDetails.country || "",
      buildingNumber: dropoffDetails?.buildingNumber || "",
      isWarning: dropoffDetails?.isWarning,
      driverEdited: dropoffDetails?.driverEdited,
    },
    notes: dropoffDetails?.notes || "",
    orderReferenceId: dropoffDetails.orderReferenceId,
    productType: dropoffDetails.productType,
    dropoffEta: dropoffDetails.dropoffEta?.toMillis(),
    expectedArrival: {
      from: dropoffDetails.expectedArrival?.from?.toMillis() || undefined,
      to: dropoffDetails.expectedArrival?.to?.toMillis() || undefined,
    },
    plannedArrivalAt: dropoffDetails.plannedArrivalAt,
    plannedDepartureAt: dropoffDetails.plannedDepartureAt,
    expectedTimeAtStop: dropoffDetails.expectedTimeAtStop,
    trackingUrl: dropoffDetails?.trackingUrl,
    metadata: dropoffDetails?.metadata,
    type: dropoffDetails.type,
    priority: dropoffDetails.priority,
  }

  payload.parcels =
    dropoffDetails.parcels?.map((parcel) => ({
      description: parcel.description || "",
      value: parcel.value || 0,
      dimensions: parcel.dimensions ? parcel.dimensions : defaultDimensions,
      quantity: parcel.quantity || undefined,
    })) || []

  if (dropoffDetails.cashOnDeliveryAmount) {
    payload.cashOnDeliveryAmount = dropoffDetails.cashOnDeliveryAmount
  }
  return payload
}
