import { atom, selector } from "recoil"
import { IUser } from "@/types/user.types"
import { IRoles } from "@/constants/role"
import { CountryKeys, DefaultDistanceUnit } from "@/types/localization.types"
import { isEmpty } from "lodash"
import { DefaultPaymentMethodType } from "@/types/paymentMethod.types"
import { COUNTRIES_WITH_3PL } from "@/constants/providerDetails"
import { IPreference } from "@/types/organizations.types"
import { BASKIT_BRANDING } from "@/modules/shared/ThemeProvider/InjectBranding"
import { DATE_FORMAT_REGIONS } from "@/utils/datetime"

export const defaultUserState = {
  id: "",
  phone: "",
  firstName: "",
  lastName: "",
  createdAt: 0,
  email: "",
  stores: [],
  userFetched: false,
  orgID: "",
  orgs: [],
  org: {
    id: "",
    name: "",
    phone: "",
    address: "",
    email: "",
    country: "" as CountryKeys,
    hasStripeAccount: false,
    hasValidPaymentMethod: false,
    hasActiveSubscription: false,
    enableNotifications: false,
    cometChatEnabled: false,
  },
  preference: {
    notificationsSettings: {},
    notificationsConfig: {},
    joinedKosmoNetwork: false,
    distanceUnit: DefaultDistanceUnit,
  } as IPreference,
  role: IRoles.ADMIN,
  locationId: undefined,
  newVersionAvailable: "",
  preferredDateFormat: DATE_FORMAT_REGIONS.EU
}

export const kosmoBranding = {
  primaryColor: "#4100FF",
  secondaryColor: undefined,
  textColor: undefined
}

//Stores Auth User /profile call
export const userAtom = atom({
  key: "user", // unique ID (with respect to other atoms/selectors)
  default: defaultUserState as IUser, // default value (aka initial value)
})

// // Store public portal user
// export const publicUserAtom = atom({
//   key: "publicUser",
//   default: defaultUserState as IPublicUser,
// })

export const isCardDefaultPaymentMethodAtom = selector({
  key: "isCardDefaultPaymentMethod",
  get: ({ get }) => {
    const user = get(userAtom)

    return user.preference?.defaultPaymentMethod !== DefaultPaymentMethodType.BANK_TRANSFER
  },
})

export const isBankTransferSelector = selector({
  key: "isBankTransferSelector",
  get: ({ get }) => {
    const user = get(userAtom)

    return user.preference?.defaultPaymentMethod === DefaultPaymentMethodType.BANK_TRANSFER
  },
})

export const isOwnerAtom = selector({
  key: "isOwner",
  get: ({ get }) => {
    const { role } = get(userAtom)

    return role === IRoles.OWNER
  },
})

export const isAdminAtom = selector({
  key: "isAdmin",
  get: ({ get }) => {
    const { role } = get(userAtom)

    return role === IRoles.ADMIN
  },
})

export const isManagerSelector = selector({
  key: "isManager",
  get: ({ get }) => {
    const { role } = get(userAtom)

    return role === IRoles.MANAGER
  },
})

export const isSupportRoleSelector = selector({
  key: "isSupportRole",
  get: ({ get }) => {
    const { role } = get(userAtom)

    return role === IRoles.SUPPORT
  },
})

export const isManagerWithLocationSelector = selector<[boolean, string | undefined]>({
  key: "isManagerWithLocation",
  get: ({ get }) => {
    const { role, locationId } = get(userAtom)

    return [role === IRoles.MANAGER && !isEmpty(locationId), locationId]
  },
})

export const isAddingCardRequiredAtom = selector({
  key: "isAddingCardRequired",
  get: ({ get }) => {
    const isCardDefaultPaymentMethod = get(isCardDefaultPaymentMethodAtom)
    const { org } = get(userAtom)

    return (
      isCardDefaultPaymentMethod && !org.hasValidPaymentMethod
    )
  },
})

export const hasActiveSubscriptionAtom = selector({
  key: "hasActiveSubscription",
  get: ({ get }) => {
    const { org } = get(userAtom)

    return !!org.hasActiveSubscription
  },
})

export const hasEnableNotificationsAtom = selector({
  key: "hasEnableNotifications",
  get: ({ get }) => {
    const { org } = get(userAtom)

    return !!org.enableNotifications
  },
})

export const isPayingUserSelector = selector({
  key: "isPayingUserSelector",
  get: ({ get }) => {
    const isBankTransfer = get(isBankTransferSelector)
    const hasActiveSubscription = get(hasActiveSubscriptionAtom)

    return isBankTransfer || hasActiveSubscription
  }
}
)

// export const isOnboardingIncompleteAtom = selector({
//   key: "isOnboardingIncomplete",
//   get: ({ get }) => {
//     const { org } = get(userAtom)
//     const hasActiveSubscription = get(hasActiveSubscriptionAtom)
//     const isCardDefaultPaymentMethod = get(isCardDefaultPaymentMethodAtom)

//     return !hasActiveSubscription || (isCardDefaultPaymentMethod && !hasActiveSubscription && org.hasValidPaymentMethod)
//   },
// })

export const isSuspendingAtom = selector({
  key: "isSuspending",
  get: ({ get }) => {
    const { org } = get(userAtom)

    return org.suspendedAt !== undefined
  },
})

export const isSuspendedAtom = selector({
  key: "isSuspended",
  get: ({ get }) => {
    const { org } = get(userAtom)

    return org.suspendedAt !== undefined && Date.now() < org.suspendedAt
  },
})

export const orgCountryAtom = selector({
  key: "orgCountry",
  get: ({ get }) => {
    const { org } = get(userAtom)

    return org?.country
  },
})

export const isAuthAtom = selector({
  key: "isAuth",
  get: ({ get }) => {
    const user = get(userAtom)

    return !!user.id
  },
})

export const hasOrgAtom = selector({
  key: "hasOrg",
  get: ({ get }) => {
    const user = get(userAtom)

    return !!user.org.id
  },
})
// TODO: Rename with locations
export const hasStoresAtom = selector({
  key: "hasStores",
  get: ({ get }) => {
    const user = get(userAtom)

    return !isEmpty(user?.stores)
  },
})


export const isCodAvailableCountryAtom = selector({
  key: "isCodAvailableCountry",
  get: ({ get }) => {
    const { org } = get(userAtom)

    return org.country === CountryKeys.PH
  },
})

export const isKosmoNetworkSelector = selector({
  key: "isKosmoNetwork",
  get: ({ get }) => {
    const { preference } = get(userAtom)

    return preference?.joinedKosmoNetwork
  },
})

export const is3PLsEnabledSelector = selector({
  key: "is3PLsEnabled",
  get: ({ get }) => {
    const { org } = get(userAtom)

    return COUNTRIES_WITH_3PL.includes(org?.country)
  },
})

export const locationsSelector = selector({
  key: "locationsSelector",
  get: ({ get }) => {
    const { stores } = get(userAtom)
    return stores || [];
  }
})

export const brandingColorsSelector = selector({
  key: "primaryColorSelector",
  get: ({ get }) => {
    const { preference, customSubdomain } = get(userAtom)
    // Subdomain hardcoded branding
    if (customSubdomain === "baskit") {
      return { ...BASKIT_BRANDING, primaryColor: "#FF8B00" }
    }

    if (!preference?.branding?.platformEnabled) {
      return kosmoBranding
    }

    return { primaryColor: preference?.branding?.primaryColor || "#4100FF", secondaryColor: preference?.branding?.secondaryColor, textColor: preference?.branding?.textColor || "" }
  }
})
