import { CURRENCIES } from "@/constants/currencies"

export interface Localization {
  locale: string
  defaultCoordinates?: {
    lat: number
    lng: number
  }
}

export enum CountryKeys {
  SG = "SG",
  BR = "BR",
  PH = "PH",
  ID = "ID",
  MY = "MY",
  TH = "TH",
  CO = "CO",
  DE = "DE",
  IT = "IT",
  ES = "ES",
  FR = "FR",
  NL = "NL",
  HK = "HK",
  JP = "JP",
  PA = "PA",
  AT = "AT",
  HU = "HU",
  VE = "VE",
  TW = "TW",
}

// export enum LocaleCodes {
//   SG = "sg",
//   BR = "br",
//   PH = "ph",
//   ID = "id",
//   MY = "my",
//   TH = "th",
//   CO = "co",
//   DE = "de",
//   IT = "it",
//   ES = "es",
//   FR = "fr",
//   NL = "nl",
//   HK = "hk",
//   JP = "jp",
//   PA = "pa",
//   AT = "at",
//   HU = "hu",
// }

export type Locale = {
  // locale: LocaleCodes
  currencyCode: keyof typeof CURRENCIES
  headerLanguageCode: string
  displayName: string
  timezone: string
  timezoneDisplay: string
  defaultCoordinates: {
    lat: number
    lng: number
  }
}

export enum DistanceUnit {
  KM = "km",
  MI = "mi",
}

export const DefaultDistanceUnit: DistanceUnit = DistanceUnit.KM

export const DistanceUnits: { [key: string]: { nameKey: string, value: DistanceUnit } } = {
  KM: { nameKey: "common.kilometer", value: DistanceUnit.KM },
  MI: { nameKey: "common.mile", value: DistanceUnit.MI },
} as const
