import React from "react"
import { Modal } from "antd"
import { Loader } from "../Loader"

type LoadingModalProps = {
  id?: string
  title: string
  subtitle: React.ReactNode | string
  open: boolean
}

const LoadingModal: React.FC<LoadingModalProps> = ({ id, title, subtitle, open }) => {
  return (
    <>
      <Modal open={open} closable={false} footer={false} destroyOnClose={true}>
        <div id={id} className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col text-center">
          <Loader fontSize={24} />
          <p className="font-bold text-xl p-2">{title}</p>
          <p className="text-gray-500">{subtitle}</p>
        </div>
      </Modal>
    </>
  )
}

export default LoadingModal
