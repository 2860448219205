import { ContactDriverButton } from "@/UI/Buttons/ContactDriverButton"
import { dispatchRulesAtom } from "@/atoms/dispatchRulesAtom"
import { driversAtom } from "@/atoms/driversAtom"
import { IDispatchRule } from "@/types/dispatchRules.types"
import { AssignmentTypes, DraftOrder, assignmentTypeNames } from "@/types/draftOrder.types"
import { Driver } from "@/types/drivers.types"
import { IOrder } from "@/types/orders.types"
import { OrderStatusesTypes } from "@/types/statuses.types"
import { Team } from "@/types/teams.types"
import { VehicleType } from "@/types/vehicle.types"
import { vehicleImage } from "@/utils/providers"
import { Typography } from "antd"
import Image from "next/image"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"

interface IDisplayOwnFleetProps {
  driverID: string
  team?: Team
  order?: DraftOrder | IOrder
  compact?: boolean
  fontStyle?: string
  className?: string
  vehicleType?: VehicleType
  onClick?: (e: React.MouseEvent<HTMLElement>) => any
}

const { Text } = Typography
const DisplayOwnFleet: React.FC<IDisplayOwnFleetProps> = ({
  team,
  driverID,
  compact,
  order,
  vehicleType,
  fontStyle,
  className,
}) => {
  const { t } = useTranslation()
  const allDrivers = useRecoilValue(driversAtom)

  let driverName = allDrivers.find((driver: Driver) => driver.id === driverID)?.name
  const driverPhone = allDrivers.find((driver: Driver) => driver.id === driverID)?.phone
  let showDeleted = false

  const isCreatedOrder = order && "assignmentType" in order && order?.id
  const isDraftOrder = order && "metadata" in order && order?.metadata?.assignmentType

  const showAssignmentType =
    isCreatedOrder &&
    !!order?.assignmentType &&
    [OrderStatusesTypes.CREATED, OrderStatusesTypes.SUBMITTED].includes(order?.status) &&
    order?.assignmentType === AssignmentTypes.BLAST

  if (!driverName && order) {
    // if order is IOrder
    if ("driver" in order && order.driver) {
      driverName = order.driver.name
      showDeleted = true
    } else {
      // if order is IDraftOrders
      driverName = "Deleted"
    }
  }

  let appliedRule: IDispatchRule | undefined
  if (order && "ruleId" in order) {
    const dispatchRules = useRecoilValue(dispatchRulesAtom)
    appliedRule = dispatchRules.find((rule) => rule.ruleId === order?.ruleId)
  }

  return (
    <div className="flex flex-col w-full">
      {team && (
        <p className="flex gap-x-1 items-center capitalize font-semibold whitespace-pre">
          {team?.name}
        </p>
      )}
      {driverID && (
        <div className={`flex flex-row gap-x-1.5 items-center w-full ${className}`}>
          <div
            className={`flex items-center capitalize gap-x-2 w-full ${
              !compact ? `my-0 ${fontStyle}` : `my-0 ${fontStyle} whitespace-nowrap`
            }`}
          >
            {vehicleType && (
              <div className="flex min-w-[24px]">
                <Image src={vehicleImage(vehicleType)} priority={true} width={24} height={24} />
              </div>
            )}
            {/* {showPendingTooltip && <PendingTooltip />} */}
            <Text ellipsis className="text-ellipsis overflow-hidden whitespace-nowrap">
              {!!driverName && ` ${driverName}`}
            </Text>
            {!showDeleted && isCreatedOrder && driverPhone && (
              <ContactDriverButton phone={driverPhone} driverID={driverID} />
            )}
            {showDeleted && <span className="text-sm text-gray-500"> (Deleted)</span>}
          </div>
        </div>
      )}
      <div>
        {isDraftOrder && (
          <p className="capitalize text-sm">
            {t(assignmentTypeNames[order?.metadata?.assignmentType || AssignmentTypes.EMPTY])}
          </p>
        )}
        {/* Display rule and assignment type before a rider accepts */}
        {isCreatedOrder && (
          <div className="flex flex-row space-x-1">
            {showAssignmentType && (
              <p className="text-sm">{t(assignmentTypeNames[order?.assignmentType || ""])}</p>
            )}
            {showAssignmentType && !!appliedRule && <p className="capitalize text-sm">|</p>}
            {!!appliedRule && <p className="capitalize text-sm">{appliedRule?.name}</p>}
          </div>
        )}
      </div>
    </div>
  )
}

export default DisplayOwnFleet
