import React from "react"
import { isAuthAtom, userAtom } from "@/atoms/userAtom"
import { useUser } from "@/hooks/useUser"
import { useRecoilValue } from "recoil"
import { useInterval } from "usehooks-ts"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import { routes } from "@/modules/HOC/WithAuth/routes"
import { IState } from "flagsmith/types"
import { isEmpty } from "lodash"
import { useFlagsmith } from "flagsmith/react"

interface PollerProps {}

const EXECUTION_INTERVAL = 5 * 60

const Poller: React.FC<PollerProps> = () => {
  const flagsmith = useFlagsmith()
  const { sandboxRouter } = useSandboxRouter()
  const isAuth = useRecoilValue(isAuthAtom)
  const user = useRecoilValue(userAtom)
  const { hydrateUserAccount, verifyAppVersion } = useUser()

  const refreshFlags = async (orgId: string) => {
    try {
      await flagsmith.identify(user.id, { org_id: orgId })
    } catch (error) {
      const flagsmithState = JSON.parse(localStorage?.getItem("BULLET_TRAIN_DB") || "{}")
      if (!!flagsmithState && flagsmithState?.api && !isEmpty(flagsmithState?.flags)) {
        flagsmith.setState(flagsmithState as IState)
      }
    }
  }

  const route = routes[sandboxRouter.pathname]
  useInterval(
    () => {
      verifyAppVersion()
      hydrateUserAccount()
    },
    !!route && !route?.isPublic && isAuth && user?.orgs?.length > 0
      ? 1000 * EXECUTION_INTERVAL
      : null
  )

  useInterval(
    () => {
      refreshFlags(user.org.id)
    },
    isAuth && user?.orgs?.length > 0 ? 1000 * 10 * 60 : null
  )

  return <></>
}

export default Poller
