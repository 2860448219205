import { userAtom } from "@/atoms/userAtom"
import { findCountryCodeFromPhone } from "@/utils/addresses"
import { Form, FormInstance } from "antd"
import { CountryCode } from "libphonenumber-js"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"

const { countries } = require("@/constants/countryCodes.json")

interface IUseInputPhoneNumber {
  countryCode: string
  phoneCode: string
  handlePhoneSelect: (value: string) => void
}

const useInputPhoneNumber = (
  form: FormInstance,
  phoneFieldName = "phone"
): IUseInputPhoneNumber => {
  const [user, _] = useRecoilState(userAtom)
  const { org } = user
  const [currentCountryCode, setCurrentCountryCode] = useState((org?.country as string) || "SG")

  const orgCountry = countries.find((country: any) => country.countryCode === org?.country)
  const [currentPhoneCode, setCurrentPhoneCode] = useState(orgCountry?.phoneCode || "+65")
  const phoneNumber = Form.useWatch(phoneFieldName, form)

  const handlePhoneSelect = (value: string) => {
    const selectedCountry = countries.find(
      (country: { countryCode: CountryCode }) => value === country.countryCode
    )
    // TODO: TEMPORARY PATCH FOR OLACLICK PILOT
    if (selectedCountry.countryCode === "BR") {
      form.setFieldsValue({
        phone: "+55 AANNNNNNNNN",
      })
    } else {
      form.setFieldsValue({
        phone: selectedCountry.phoneCode,
      })
    }
    setCurrentCountryCode(selectedCountry.countryCode)
    setCurrentPhoneCode(selectedCountry.phoneCode)
  }

  useEffect(() => {
    if (phoneNumber) {
      const typedCountry = findCountryCodeFromPhone(phoneNumber)

      setCurrentCountryCode(typedCountry?.countryCode || "SG")
      setCurrentPhoneCode(typedCountry?.phoneCode || "+65")
    }

    if (!form.getFieldValue(phoneFieldName)) {
      if (!phoneNumber && org?.id) {
        form.setFieldsValue({
          phone: orgCountry?.phoneCode,
        })
        return
      }
      form.setFieldsValue({
        [phoneFieldName]: currentPhoneCode,
      })
    }
  }, [])

  useEffect(() => {
    if (!phoneNumber || phoneNumber.length < 4) {
      return
    }

    if (!phoneNumber.includes("+")) {
      form.setFieldsValue({
        [phoneFieldName]: "+" + phoneNumber,
      })
    }

    const typedCountry = findCountryCodeFromPhone(phoneNumber)

    if (!typedCountry) {
      return
    }

    // This is awful, not going into how to change without breaking UX (hard to change phone code)
    if (phoneNumber.includes(" ")) {
      setCurrentCountryCode(typedCountry?.countryCode)
      setCurrentPhoneCode(typedCountry?.phoneCode)
      return
    }

    form.setFieldsValue({
      [phoneFieldName]: phoneNumber.replace(typedCountry.phoneCode.trim(), typedCountry.phoneCode),
    })

    setCurrentCountryCode(typedCountry?.countryCode)
    setCurrentPhoneCode(typedCountry?.phoneCode)
  }, [phoneNumber])

  return {
    handlePhoneSelect,
    phoneCode: currentPhoneCode,
    countryCode: currentCountryCode,
  }
}

export default useInputPhoneNumber
