import { CURRENCIES } from "@/constants/currencies"
import { ProvidersType } from "./providers.types"
import { PriceBreakdownType } from "./pricing.types"
import { AvailableStatuses, Location } from "./orders.types"
import { Driver } from "./drivers.types"
import { OrderStatusesTypes } from "./statuses.types"

export enum RoutesStops {
  ROUTES = "routes",
  STOPS = "stops"
}

export interface Stop {
  id: string
  stopIndex: number
  availableStatuses: AvailableStatuses
  status: OrderStatusesTypes
  pickupAt?: number
  dropoffAt?: never
  cancelledAt?: never
  deliveryType?: never
  deliveryEta?: never
  driver: Driver,
  amount: number
  currency: keyof typeof CURRENCIES
  provider: ProvidersType
  providerOrderID?: string
  recipient: Location
  sender: Location
  orderID: string
  createdAt: number
  pickupStartEta: number
  priceBreakdown: PriceBreakdownType
  pickupEndEta: number
  trackingUrl?: string
  ruleId?: string
  teamId?: number
  schedule?: {
    pickup_at: number
    pickupAt: number
  }
  isSingleStop: boolean
}
