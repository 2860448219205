import axios from "@/modules/shared/AxiosInterceptor"
import { IDispatchRule, PutDispatchRulePayload } from "@/types/dispatchRules.types"
import { AxiosResponse } from "axios"

export async function putDispatchRule(
  rule: PutDispatchRulePayload,
  id?: string
): Promise<AxiosResponse<IDispatchRule>> {
  try {
    return axios.put("/dispatch/rule", { ...rule, id })
  } catch (error) {
    throw error
  }
}

export async function getDispatchRules(): Promise<AxiosResponse<IDispatchRule[]>> {
  try {
    //   Todo: endpoint is supposed to change, need to update once done
    return axios.get("/dispatch/rules")
  } catch (error) {
    throw error
  }
}

export async function deleteDispatchRule(id: string): Promise<AxiosResponse<IDispatchRule>> {
  try {
    return axios.delete(`/dispatch/rule/${id}`)
  } catch (error) {
    throw error
  }
}
