import { CURRENCIES } from "@/constants/currencies"
import { ServerDeliveryType } from "./deliveryTypes.types"
import { PriceBreakdownType } from "./pricing.types"
import { IProductType, ProductTypes } from "./productTypes.types"
import { ProvidersType } from "./providers.types"
import { OrderStatusesTypes } from "./statuses.types"
import { VehicleType } from "./vehicle.types"
import { IssueTypes } from "./issues.types"
import { AssignmentTypes } from "./draftOrder.types"
import { Driver } from "./drivers.types"
import { TaskFailureReason } from "./taskFailureReasons.types"
import { RoutesDirections } from "./map"

export const MAX_STOPS_PER_ORDER = 100
export const MAX_STOPS_PER_OPTIMIZATION = 350

export enum StopType {
  PICKUP = "pickup",
  DROPOFF = "dropoff",
  ARRIVAL = "arrival",
  BREAK = "break",
}

export type AvailableStatuses = { statuses?: OrderStatusesTypes[], stops?: Record<number, DestinationStatuses[]> }

export enum DestinationPriority {
  DEFAULT = "",
  FIRST = "first",
  LAST = "last"
}

export const defaultPickupDetails = {
  address: "",
  alias: "",
  id: "",
  name: "",
  phone: "",
  notes: "",
  postalCode: "",
  productType: ProductTypes.OTHER,
  latitude: 0,
  longitude: 0,
  type: StopType.PICKUP
}

export const defaultDropOffForm = {
  id: "",
  address: "",
  buildingNumber: "",
  name: "",
  phone: "",
  notes: "",
  latitude: 0,
  longitude: 0,
  type: StopType.DROPOFF
}

export const defaultPickupForm = {
  address: "",
  alias: "",
  id: "",
  name: "",
  phone: "",
  notes: "",
  postalCode: "",
  productType: ProductTypes.OTHER,
  latitude: 0,
  longitude: 0,
  type: StopType.PICKUP
}


// TODO Think whether to merge with request rider
export interface IOrder {
  id: string
  deliveryType: ServerDeliveryType
  status: OrderStatusesTypes
  currency: keyof typeof CURRENCIES
  trackingUrl: string
  labelUrl?: string
  vehicleType: VehicleType
  productType: IProductType
  sender: Location
  recipients: Location[]
  recipient?: Location
  driver?: Driver
  teamId?: number
  isReassigned: boolean
  amount: number
  provider: ProvidersType
  orderID: string
  providerOrderID: string
  schedule: {
    pickup_at: number
  }
  cancelReason: string
  priceBreakdown: PriceBreakdownType
  isScheduled: boolean
  userId?: string

  pickupStartEta: number
  pickupEndEta: number
  // pickupTillEta?: number
  deliveryEta: number
  cancelledAt?: number
  pickupAt?: number
  dropoffAt?: number
  driverAssignedAt?: number

  issue?: Issue

  assignmentType?: AssignmentTypes
  assignmentInProgress?: boolean
  autoassignToAll?: boolean

  createdAt: number
  updatedAt: number

  availableStatuses?: AvailableStatuses

  ruleId?: string
  eventHistory: OrderEventHistoryItem[]

  metadata?: {
    totalDistance?: number
    assignmentType?: AssignmentTypes
    endAddress?: string
    endLatitude?: number
    endLongitude?: number
    driverId?: string
  }

  routeDirections?: RoutesDirections
  arrival?: Arrival
  routeId?: string
}

export enum EventType {
  DeliveryReassigned = "delivery_reassigned",
  DestinationDuplicated = "destination_duplicated",
  DestinationLocationUpdated = "destination_location_updated",
}


export const getEventTypeTextKey = (event: EventType) => {
  switch (event) {
    case EventType.DeliveryReassigned:
      return "common.order-event.deliveryReassigned"
    case EventType.DestinationDuplicated:
      return "common.order-event.destinationDuplicated"
    case EventType.DestinationLocationUpdated:
      return "common.AddressEdited"
  }
}

export interface OrderEventHistoryItem {
  id: number
  status: OrderStatusesTypes | EventType
  createdAt: number
  driverName?: string
  recipientFullName?: string
  recipientStatus?: string
  recipientFailureReason?: TaskFailureReason
  recipientFailureReasonNote?: string
  recipientOriginalDropoffETA?: number
  recipientDropoffETA?: number
  driverNote?: string
  stopIndex?: number
  latitude?: number
  longitude?: number
  oldLocation?: {
    latitude: number
    longitude: number
  },
  newLocation?: {
    latitude: number
    longitude: number
  }
}

export interface Issue {
  issueType: IssueTypes
}

export interface GlobalOrdersMetadata {
  total_successful_deliveries: number
  total_successful_deliveries_cost: number
  avg_cost_per_successful_deliveries: number
  ongoingDeliveriesPerDriver: Record<string, number>
  total: number
  totalStops: number
  totalRoutes: number
  submitted: number
  created: number
  to_pickup: number
  picked_up: number
  delivered: number
  cancelled: number
  returning: number
  returned: number
  at_hub: number
  to_dropoff: number
  delayed: number
  pickup_delayed: number
  delivery_delayed: number
  route_duration_limit: number
  route_vehicle_capacity_limit: number
  not_accepted: number
  driver_rejected: number
  no_available_drivers: number
  cost: number
  deliveriesPerDriver: Record<string, number>
  byLocationId: Record<string, number>
  byTags: Record<string, number>
}

export enum DestinationStatuses {
  DELIVERED = "delivered",
  RETURNING = "returning",
  RETURNED = "returned",
  CANCELED = "canceled",
  CREATED = "created",
  TO_DROPOFF = "to_dropoff",
  EMPTY = ""
}

export const SORTED_DESTINATION_STATUSES = [
  DestinationStatuses.EMPTY,
  DestinationStatuses.CREATED,
  DestinationStatuses.TO_DROPOFF,
  DestinationStatuses.RETURNING,
  DestinationStatuses.DELIVERED,
  DestinationStatuses.RETURNED,
  DestinationStatuses.CANCELED,
]


export interface RecipientMetadata {
  ecommerceFulfillmentId?: string
  ecommerceIntegrationId: string
  ecommerceOrderId?: string
  ecommerceShippingId?: string
  ecommerceStoreId?: string
  refId?: string
  source?: string
  clientId?: string
  tags?: string[]
  attemptCount?: number
}

export interface PODObject {
  lat: string,
  lng: string,
  url: string
  uploadUrl: string
  formData: Record<string, string>
}

export type ExpectedArrivalType = {
  from?: number
  to?: number
}

export type BarcodeItem = {
  id: string
  createdAt: number
  scannedAt?: number
}

export type Parcel = {
  description?: string
  value?: number
  dimensions?: ParcelDimensions
  quantity?: number
  barcode?: BarcodeItem
}

// TODO: to clean up
export interface Location {
  id?: string
  address?: string // deprecated
  buildingNumber?: string // deprecated

  firstName?: string
  lastName?: string

  phone?: string
  postalCode?: string // deprecated
  location?: LocationDetailsType
  notes?: string
  noteFromDriver?: string

  organizationName?: string
  productType?: IProductType

  orderReferenceId?: string

  proofOfDeliveries?: PODObject[]

  email?: string

  status?: DestinationStatuses

  originalDropoffEta?: number
  dropoffEta?: number

  parcels?: Parcel[]
  cashOnDeliveryAmount?: number
  clientPricingDeliveryFee?: number
  clientPricingTips?: number

  expectedArrival?: ExpectedArrivalType
  expectedTimeAtStop?: number
  plannedArrivalAt?: number
  plannedDepartureAt?: number
  optimizationFailureReasons?: string[]

  metadata?: RecipientMetadata

  trackingUrl?: string

  isDelayed?: boolean

  failureReason?: TaskFailureReason
  failureReasonNote?: string
  zoneIds?: string[]

  finishedAt?: number
  type: StopType

  priority?: DestinationPriority
}

// TODO disgusting must be cleaned from above
export interface ISenderRecipientPayload {
  firstName: string
  lastName: string
  organizationName: string
  phone: string
  email?: string
  location: LocationDetailsType
  notes: string
  orderReferenceId?: string
  productType?: IProductType
  parcel?: {
    description?: string
    value?: number
    dimensions?: ParcelDimensions
  }
  cashOnDeliveryAmount?: number
  expectedArrival?: {
    from?: number
    to?: number
  }
  plannedArrivalAt?: number
  plannedDepartureAt?: number
  metadata?: RecipientMetadata
}

export interface LocationDetailsType {
  id?: string
  alias?: string
  address: string
  latitude: number
  longitude: number
  postalCode?: string
  city?: string,
  country?: string,
  buildingNumber?: string
  zoneId?: string
  isWarning?: boolean
  driverEdited?: string
}

export type ParcelDimensions = {
  length: number
  width: number
  height: number
  weight: number
}


export interface TableOrder extends IOrder {
  index?: number
  parentId?: string
  orderReferenceId?: string
  parentStatus?: OrderStatusesTypes
  children: (Location & { isChildren: boolean, parentId: string, stopIndex: number })[]
  isChildren: boolean
  stopIndex?: number
}

export interface TableOrderChild {
  key: string | undefined
  orderID: string | number,
  parentId: string,
  // TODO: this might be confusing, the naming of etas/planned are not the same but needs to to be displayed in DropoffList
  plannedArrivalAt: number | undefined,
  dropoffEta: number | undefined,
  expectedArrival?: ExpectedArrivalType,
  parentStatus: OrderStatusesTypes,
  trackingUrl?: string,
  provider: ProvidersType,
  sender: Location,
  recipients: Location[],
  recipient: Location,
  index: number,
  status?: DestinationStatuses,
  deliveryType: ServerDeliveryType,
  orderReferenceId?: string,
  availableStatuses?: { statuses?: OrderStatusesTypes[], stops?: Record<number, DestinationStatuses[]> },
  isChildren: true
  type: StopType
  stopIndex?: number
  metadata?: RecipientMetadata
  currency: keyof typeof CURRENCIES
}

export type Contact = {
  name: string
  phone: string
  email: string
  notes: string
}

export type Arrival = {
  contact: Contact;
  location: LocationDetailsType
  scheduleAt?: number
  plannedArrivalAt?: number
  finishedAt?: number
}

export interface StopDescription { id: string; orderId: string; index: number }

export enum OrderType {
  ACTIVE = "active",
  DRAFT = "draft"
}

export interface DispatchRoute {
  assignmentType?: AssignmentTypes
  createdAt: number
  driverId?: string
  teamId?: number
  orderId: string
  orderType: OrderType,
  stops: number
  id: string
  status: OrderStatusesTypes
}
