import React from "react"
import { OrderStatusesTypes } from "@/types/statuses.types"
import StatusIcon from "./StatusIcon"
import { getOrderStatusTextKey, getStopStatusTextKey } from "@/utils/status"
import { useTranslation } from "react-i18next"
import { RoutesStops } from "@/types/stops.types"

interface OrderStatusProps {
  status: OrderStatusesTypes
  withIcon?: boolean
  type?: RoutesStops
}

const OrderStatus: React.FC<OrderStatusProps> = ({
  status,
  withIcon,
  type = RoutesStops.ROUTES,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row gap-x-1 items-center">
      {withIcon && <StatusIcon status={status} />}
      <p className="font-medium text-black">
        {type === RoutesStops.ROUTES
          ? t(getOrderStatusTextKey(status))
          : t(getStopStatusTextKey(status as any))}
      </p>
    </div>
  )
}

export default OrderStatus
