import { atom } from "recoil"

export type ChatIsOpen = { isOpen: boolean, guid?: string }

export const chatIsOpenAtom = atom({
  key: "chatIsOpenAtom",
  default: { isOpen: false, guid: "" } as ChatIsOpen,
})

export type ChatUnreadMessagesType = Record<string, number>

export const chatUnreadMessagesAtom = atom({
  key: "chatUnreadMessagesAtom",
  default: {} as ChatUnreadMessagesType,
})

