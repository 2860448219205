import React from "react"
import { Tooltip } from "antd"
import { ReactComponentElement, ReactNode } from "react"
import { TooltipPlacement } from "antd/lib/tooltip"

interface IDisabledTooltipProps {
  enable: boolean
  label: ReactNode | string
  children: ReactComponentElement<any>
  placement?: TooltipPlacement
}

const DisabledTooltip: React.FC<IDisabledTooltipProps> = ({
  enable,
  label,
  children,
  placement = "top",
  ...props
}) => {
  return enable ? (
    <Tooltip title={label} showArrow={false} className="w-fit" placement={placement}>
      <div style={{ position: "relative", display: "inline-block", cursor: "not-allowed" }}>
        <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }} />
        {children}
      </div>
    </Tooltip>
  ) : (
    React.cloneElement(children, { ...props })
  )
}

export default DisabledTooltip
