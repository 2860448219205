import { LOGIN_PAGE_PATH, ONBOARDING_PAGE_PATH } from "@/constants/routes"
import { Route } from "@/types/routes"

export const profileRoute: Route = {
  isPublic: false,
  shouldRedirect: ({ isAuth, hasOrg }) =>
    !isAuth || !hasOrg,
  redirectTo: ({ isAuth, hasOrg, query }) => {
    if (isAuth && !hasOrg) {
      return { newPath: ONBOARDING_PAGE_PATH, queryParams: query }
    } else {
      return { newPath: LOGIN_PAGE_PATH, queryParams: query }
    }
  },
}
