import React from "react"

interface ISharedTextProps {
  children: React.ReactNode
  className?: string
  as?: "p" | "label" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

export const MediumText: React.FC<ISharedTextProps> = ({ children, className, as = "p" }) => {
  return <>{React.createElement(as, { className: `font-medium ${className}` }, children)}</>
}

export const SecondaryText: React.FC<ISharedTextProps> = ({ children, className, as = "p" }) => {
  return (
    <>{React.createElement(as, { className: `text-gray-600 font-light ${className}` }, children)}</>
  )
}

export const DefaultText: React.FC<ISharedTextProps> = ({ children, className, as = "p" }) => {
  return <>{React.createElement(as, { className: `font-regular ${className}` }, children)}</>
}

export const LabelText: React.FC<ISharedTextProps> = ({ children, className, as = "p" }) => {
  return (
    <>
      {React.createElement(as, { className: `text-[#111827] font-regular ${className}` }, children)}
    </>
  )
}

export const SemiBoldLabelText: React.FC<ISharedTextProps & { semiBold?: boolean }> = ({
  children,
  className,
}) => {
  return (
    <p className={`p-0 mb-1 text-md font-semibold ${className ? className : ""}`}>{children}</p>
  )
}
