import { VehicleType } from "@/types/vehicle.types"
import { ServerDeliveryType } from "./deliveryTypes.types"
import { Arrival, Location } from "@/types/orders.types"
import { IQuote } from "@/types/quotes.types"
import { isEmpty } from "lodash"
import { dropoffValidation } from "@/modules/new-orders/helpers"
import { t } from "i18next"

export interface DraftOrder {
  id: string
  index: number
  bestQuote?: IQuote
  status: DraftOrderStatus
  sender: Location
  recipients: Location[]
  children: Pick<
    DraftOrder,
    "isChild" | "checkable" | "index" | "id" | "recipients" | "children"
  >[]
  metadata?: DraftOrderMetadata
  checkable?: boolean
  error: DraftOrderError
  isChild?: boolean
  optimizationSummary?: OptimizationSummary
  createdAt: number
  routeDirections?: string
  arrival?: Arrival
  routeId?: string
}

export interface DraftOrderMetadata {
  orderNumber: string
  deliveryType: ServerDeliveryType
  vehicleType: VehicleType
  schedule: number
  isManuallyAssigned?: boolean
  isDuplicated?: boolean
  isCreatedForTest?: boolean
  ruleId?: string
  source?: string
  ecommerceOrderId?: string
  driverId?: string
  teamId?: number
  assignmentType?: AssignmentTypes
  autoassignToAll?: boolean
  ownFleetDeliveryFee?: number
  totalDistance?: number
  endAddress?: string
  endLatitude?: number
  endLongitude?: number
  optimize?: boolean
}

export enum DraftOrderStatus {
  DRAFT = "draft",
  QUOTING = "quoting",
  QUOTING_SUCCESS = "quoting_success",
  QUOTING_FAILED = "quoting_failed",
  ORDERING = "ordering",
  ORDERING_SUCCESS = "ordering_success",
  ORDERING_FAILED = "ordering_failed",
}

export enum DraftOrderTableStatusFilter {
  TOTAL = "total",
  ERROR = "error",
  WARNING = "warning",
}

export type GlobalMetadataDrivers = Record<string, number>
export type GlobalMetadataZones = Record<string | "", number>
export type GlobalMetadataLocations = Record<string | "", number>
export type GlobalMetadataSources = Record<string | "", number>
export type GlobalMetadataTags = Record<string | "", number>
export type GlobalMetadataPickupTime = Record<number, number>

export interface DraftOrdersGlobalMetadata {
  byDriver: GlobalMetadataDrivers
  byZone: GlobalMetadataZones
  byLocationId: GlobalMetadataZones
  bySource: GlobalMetadataSources
  byPickupTime: GlobalMetadataPickupTime
  byTags: GlobalMetadataTags
  error: number
  warning: number
  total: number
  totalReady: number
}

export const isProcessing = (status: DraftOrderStatus) =>
  [DraftOrderStatus.QUOTING, DraftOrderStatus.ORDERING].includes(status)
export const isDraft = (item: DraftOrder) => !isReady(item)
export const isReady = (item: DraftOrder) => {
  const isAssigned =
    !!item.bestQuote?.id ||
    !!item?.metadata?.driverId ||
    (!!item?.metadata?.teamId && !!item?.metadata?.assignmentType)

  const hasValidPickupAndDropoffs =
    !!item?.sender?.location?.latitude &&
    !isEmpty(item?.recipients) &&
    item?.recipients?.every(
      (recipient) => recipient?.location?.latitude !== 0 && recipient?.location?.longitude !== 0
    )
  return item.bestQuote?.id || (isAssigned && hasValidPickupAndDropoffs)
}
export const isError = (item: DraftOrder) => {
  const dropoffErrors = dropoffValidation(t, item.recipients)

  return [DraftOrderStatus.QUOTING_FAILED, DraftOrderStatus.ORDERING_FAILED].includes(item.status) || dropoffErrors?.length || 0 > 0
}

export interface PatchDraftFleetOrdersRequest {
  driverId?: string
  teamId?: number
  assignmentType?: AssignmentTypes
}

export enum AssignmentTypes {
  BLAST = "blast",
  MANUAL = "manual",
  THREE_PL = "3pl",
  EMPTY = ""
}

// human readable for AssignmentTypes
export const assignmentTypeNames = {
  [AssignmentTypes.BLAST]: "common.autoassign",
  [AssignmentTypes.MANUAL]: "common.manual",
  [AssignmentTypes.THREE_PL]: "common.3pl",
  [AssignmentTypes.EMPTY]: "",
}

export interface PatchDraftOrdersRequest {
  driverId?: string
  teamId?: number
  storeId?: string | null
  deliveryType?: ServerDeliveryType
  vehicleType?: VehicleType | ""
  schedule?: number | null
  pickup?: {
    firstName: string
    lastName: string
    email?: string
    location: {
      address: string
      buildingNumber?: string
      latitude: number
      longitude: number
      postalCode: string | undefined
    }
    phone: string
    notes?: string
  }
  ruleId?: string
  arrivalETA?: number
  arrival?: Arrival | null
}

export enum DraftOrderError {
  QUOTE_EXPIRED = "Quote expired",
  NO_QUOTES_FOUND = "No quotes found",
}

export interface OptimizationSummary {
  totalDistance: number
  totalDuration: number
  routes: OptimizationRouteSummary[]
  initialOrderCount: number
  unassigned: {
    count: number
  }
  totalStops: number
}

export interface OptimizationRouteSummary {
  orderId: string
  distance: number
  duration: number
}

export interface DraftOrderOptimizationResult {
  draftOrders: DraftOrder[]
  optimizationSummary: OptimizationSummary
}
