import { notification, Space, Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons"

import { ReactNode } from "react"
import { NotificationPlacement } from "antd/es/notification/interface"
import { TFunction } from "i18next"

const { Text, Paragraph } = Typography

const ErrorNotification = (
  message: string,
  desc: string | ReactNode,
  placement?: NotificationPlacement,
  durationNull?: boolean
) => {
  notification.open({
    message: message,
    duration: durationNull ? null : 5,
    description: desc,
    placement: placement,
    icon: (
      <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#A91B0D", height: "1.2em" }} />
    ),
  })
}

const ErrorNotificationForRouteOptimization = (
  t: TFunction,
  message: string,
  desc: string,
  possibleReason: string,
  solution: string
) => {
  notification.open({
    message: message,
    description: (
      <Space direction="vertical" size="middle">
        <Paragraph>
          <Text strong>{t("common.description")}:</Text> {desc}
        </Paragraph>
        <Paragraph>
          <Text strong>{t("common.possible_reason")}:</Text> {possibleReason}
        </Paragraph>
        <Paragraph>
          <Text strong>{t("common.solution")}:</Text> {solution}
        </Paragraph>
      </Space>
    ),
    placement: "topRight",
    icon: (
      <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#A91B0D", height: "1.2em" }} />
    ),
  })
}

const SuccessNotification = (message: string, desc: string, placement?: NotificationPlacement) => {
  notification.open({
    message: message,
    description: desc,
    placement: placement,
    icon: <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#41c219", height: "1.2em" }} />,
  })
}

export { ErrorNotification, ErrorNotificationForRouteOptimization, SuccessNotification }
