import { atom, selector, selectorFamily } from "recoil"
import { DispatchCourierType, IDispatchRule } from "@/types/dispatchRules.types"
import { DateTime } from "luxon"
import { teamsAtom } from "./teamsAtom"

export const dispatchRulesAtom = atom({
  key: "dispatchRules",
  default: [] as IDispatchRule[],
})

export const sortedDispatchRulesAtom = selector({
  key: "sortedDispatchRules",
  get: ({ get }) => {
    const dispatchRules = get(dispatchRulesAtom)

    const sortedRules = [...dispatchRules]?.sort((a, b) => {
      return DateTime.fromMillis(a.createdAt) > DateTime.fromMillis(b.createdAt) ? -1 : 1
    })

    return [...sortedRules]
  },
})

export const getSingleRuleCouriers = selectorFamily({
  key: "singleRuleCouriers",
  get: (ruleId) => ({ get }) => {
    const teams = get(teamsAtom)
    const selectedRule = get(dispatchRulesAtom).find((rule) => rule.id === ruleId)

    const formattedCouriers = selectedRule?.couriers
      ?.map((courier) => {
        if (courier?.type === DispatchCourierType.OWN_FLEET) {
          return teams?.find((team) => team?.id === courier?.courierId)?.name
        } else {
          return courier?.provider
        }
      })
      ?.filter((courier) => courier) as string[]

    return formattedCouriers || []
  },
})
