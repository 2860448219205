import { IProductType, ProductTypes } from "@/types/productTypes.types"
import { Form, Select } from "antd"
import { useTranslation } from "react-i18next"
import React from "react"
import { ProductTypeIcon } from "../../ProductTypeIcon"
import { SizeType } from "antd/lib/config-provider/SizeContext"

interface IProductTypeFormItemProps {
  handleProductTypeChange: (productType: IProductType) => void
  name: string
  placeholder: string
  validationErrorText: string
  required?: boolean
  label?: React.ReactNode
  size?: SizeType
}

export const ProductTypeFormItem: React.FC<IProductTypeFormItemProps> = ({
  handleProductTypeChange,
  validationErrorText,
  name,
  placeholder,
  required = false,
  label,
  size,
}) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      validateTrigger="onSubmit"
      label={label}
      rules={[
        {
          required,
          message: validationErrorText,
        },
      ]}
    >
      <Select
        placeholder={placeholder}
        className="custom-error"
        onChange={handleProductTypeChange}
        size={size}
      >
        {Object.values(ProductTypes).map((productType, index) => (
          <Select.Option key={index} value={productType}>
            <div className="flex flex-row py-1 items-center">
              <ProductTypeIcon name={productType} />
              {t(productType)}
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
