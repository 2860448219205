import React, { useContext, useEffect } from "react"
import { useRecoilValue } from "recoil"
import axios from "@/modules/shared/AxiosInterceptor"
import { SandboxContext } from "@/layout/Nav/SandboxSwitcher"
import { userAtom } from "@/atoms/userAtom"
import { useLocalization } from "@/hooks/useLocalization"

const Localization = () => {
  const user = useRecoilValue(userAtom)
  const { initTimezoneAndCountry } = useLocalization()
  const sandbox = useContext(SandboxContext)

  useEffect(() => {
    initTimezoneAndCountry(user?.org?.country)

    axios.defaults.headers.common["x-language"] = "en"
  }, [user?.org])

  useEffect(() => {
    if (sandbox.enabled) {
      axios.defaults.headers.common["x-sandbox"] = sandbox.enabled
    } else {
      delete axios.defaults.headers.common["x-sandbox"]
    }
  }, [sandbox.enabled])

  return <></>
}

export default Localization
