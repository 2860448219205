export const v22Properties = [
  "date",
  "scheduledTime",
  "arrivalTimeFrom",
  "arrivalTimeTo",
  "timeAtStop",

  "senderName",
  "senderAddress",
  "senderBuildingNumber",
  "senderPhone",
  "senderEmail",
  "senderNote",

  "recipientName",
  "recipientAddress",
  "buildingNumber",
  "recipientPhone",
  "recipientEmail",
  "dropOffNote",

  "teamName",
  "driverName",

  "parcelValue",
  "parcelWeight",
  "parcelLength",
  "parcelHeight",
  "parcelWidth",

  "orderReferenceId",
]
