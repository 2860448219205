import { Route } from "@/types/routes"
import { ACTIVE_ORDERS_PAGE_PATH, NEW_ORDERS_PAGE_PATH } from "@/constants/routes"

export const loginRoute: Route = {
  isPublic: true,
  shouldRedirect: ({ isAuth, }) => isAuth,
  redirectTo: ({ query, sandboxEnabled }) => {
    if (sandboxEnabled) {
      return { newPath: ACTIVE_ORDERS_PAGE_PATH, queryParams: query }
    }
    return { newPath: NEW_ORDERS_PAGE_PATH, queryParams: query }
  },
}
