import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSquare,
  faCircleDot,
  faLocationDot,
  faCircleCheck,
  faCircleXmark,
  faCirclePlus,
  faWarehouse,
  faPersonWalkingArrowLoopLeft,
  faHouse,
} from "@fortawesome/free-solid-svg-icons"
import { OrderStatusesTypes } from "@/types/statuses.types"
import SquareIconContainer from "../../Icons/SquareIconContainer"
import { DestinationStatuses } from "@/types/orders.types"

type StatusIconsType = {
  name: OrderStatusesTypes | DestinationStatuses | null
  color: string
  component: React.ReactElement
}

const StatusIcon: React.FC<{ status: OrderStatusesTypes | DestinationStatuses }> = ({ status }) => {
  const StatusIcons: StatusIconsType[] = [
    {
      name: OrderStatusesTypes.SUBMITTED,
      color: "bg-amber-100",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-yellow-500 text-sm"
          icon={faCirclePlus}
        />
      ),
    },
    {
      name: OrderStatusesTypes.CREATED,
      color: "bg-amber-100",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-yellow-500 text-sm"
          icon={faCirclePlus}
        />
      ),
    },
    {
      name: OrderStatusesTypes.CREATED,
      color: "bg-amber-100",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-yellow-500 text-sm"
          icon={faCirclePlus}
        />
      ),
    },
    {
      name: OrderStatusesTypes.TO_PICKUP,
      color: "bg-blue-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-blue-600 text-sm"
          icon={faCircleDot}
        />
      ),
    },
    {
      name: OrderStatusesTypes.PICKED_UP,
      color: "bg-orange-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-pink-500 text-sm"
          icon={faHouse}
        />
      ),
    },
    {
      name: OrderStatusesTypes.AT_HUB,
      color: "bg-gray-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto first-line:text-sm"
          icon={faWarehouse}
        />
      ),
    },
    {
      name: OrderStatusesTypes.TO_DROPOFF,
      color: "bg-pink-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-pink-500 text-sm"
          icon={faLocationDot}
        />
      ),
    },
    {
      name: OrderStatusesTypes.DELIVERED,
      color: "bg-green-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-green-500  text-sm"
          icon={faCircleCheck}
        />
      ),
    },
    {
      name: OrderStatusesTypes.RETURNING,
      color: "bg-rose-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-rose-600  text-sm"
          icon={faPersonWalkingArrowLoopLeft}
        />
      ),
    },
    {
      name: OrderStatusesTypes.RETURNED,
      color: "bg-green-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-green-500  text-sm"
          icon={faCircleCheck}
        />
      ),
    },
    {
      name: OrderStatusesTypes.CANCELLED,
      color: "bg-rose-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-rose-600 text-sm"
          icon={faCircleXmark}
        />
      ),
    },
    {
      name: null,
      color: "bg-rose-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-kosmoBlue text-sm"
          icon={faSquare}
        />
      ),
    },
    // Stop statuses
    {
      name: DestinationStatuses.EMPTY,
      color: "bg-amber-100",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-yellow-500 text-sm"
          icon={faCirclePlus}
        />
      ),
    },
    {
      name: DestinationStatuses.TO_DROPOFF,
      color: "bg-pink-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-pink-500 text-sm"
          icon={faLocationDot}
        />
      ),
    },
    {
      name: DestinationStatuses.DELIVERED,
      color: "bg-green-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-green-500  text-sm"
          icon={faCircleCheck}
        />
      ),
    },
    {
      name: DestinationStatuses.RETURNED,
      color: "bg-green-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-green-500  text-sm"
          icon={faCircleCheck}
        />
      ),
    },
    {
      name: DestinationStatuses.CANCELED,
      color: "bg-rose-200",
      component: (
        <FontAwesomeIcon
          className="flex-shrink-0 h-3 mx-auto my-auto text-rose-600 text-sm"
          icon={faCircleXmark}
        />
      ),
    },
  ]

  const StatusIcon =
    StatusIcons.find((icon) => icon.name === status) ||
    StatusIcons.find((icon) => icon.name === null)!

  return <SquareIconContainer icon={StatusIcon.component} color={StatusIcon.color} />
}

export default StatusIcon
