import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { VehicleType } from "@/types/vehicle.types"
import { PROVIDER_DETAILS } from "@/constants/providerDetails"
import { ProvidersType } from "@/types/providers.types"
import _ from "lodash"
import { TFunction } from "i18next"

export const convertDeliveryTypeName = (deliveryType: ServerDeliveryType, t: TFunction) => {
  const deliveryTypeMap = {
    [ServerDeliveryType.INSTANT]: t("common.asap"),
    // [ServerDeliveryType.TWOHOURS]: t("common.twohours"),
    [ServerDeliveryType.FOURHOURS]: t("common.fourhours"),
    [ServerDeliveryType.NEXT_DAY]: t("common.nextDay"),
    [ServerDeliveryType.NEXT_THREE_DAYS]: t("common.nextThreeDays"),
  } as any

  return deliveryTypeMap[deliveryType]
}

export const formatMultipleServerDeliveryTypes = (deliveryTypes: ServerDeliveryType[], t: TFunction) => {
  const humanizedDeliveryTypes = deliveryTypes.map((deliveryType) => convertDeliveryTypeName(deliveryType, t))

  return humanizedDeliveryTypes.join(" / ")
}

export const convertVehicleTypeName = (vehicleType: VehicleType, t: TFunction) => {
  const vehicleTypeMap = {
    // [VehicleType.WALKER]: t("common.walker"),
    [VehicleType.BIKE]: t("common.bike"),
    [VehicleType.MOTORCYCLE]: t("common.motorcycle"),
    [VehicleType.VAN]: t("common.van"),
    [VehicleType.CAR]: t("common.car"),
    [VehicleType.LORRY]: t("common.lorry"),
  } as any

  return vehicleTypeMap[vehicleType]
}

export const vehicleImage = (vehicle: VehicleType) => {
  const images = {
    WALKER: "/assets/images/deliveryOptionIcons/Walker.svg",
    BIKE: "/assets/images/deliveryOptionIcons/Bike.svg",
    MOTORCYCLE: "/assets/images/deliveryOptionIcons/Scooter.svg",
    LALAGO: "/assets/images/deliveryOptionIcons/Scooter.svg",
    LALAPRO: "/assets/images/deliveryOptionIcons/Scooter.svg",
    MPV: "/assets/images/deliveryOptionIcons/Van.svg",
    VAN: "/assets/images/deliveryOptionIcons/Van.svg",
    CAR: "/assets/images/deliveryOptionIcons/Car.svg",
    mR: "/assets/images/deliveryOptionIcons/Scooter.svg",
    mC: "/assets/images/deliveryOptionIcons/Car.svg",
    mV: "/assets/images/deliveryOptionIcons/Van.svg",
    LORRY: "/assets/images/deliveryOptionIcons/Lorry.svg",
  }

  return images[vehicle]
}

export const isExpressProviders = (providerName: ProvidersType) => {
  return PROVIDER_DETAILS.find((providerDetail) =>
    providerDetail.name === providerName &&
    _.intersection(providerDetail?.deliveryTypes, [ServerDeliveryType.INSTANT, ServerDeliveryType.FOURHOURS]).length > 0)
}

export const isStandardDeliveryProviders = (providerName: ProvidersType) => {
  return PROVIDER_DETAILS.find((providerDetail) =>
    providerDetail.name === providerName &&
    _.intersection(providerDetail?.deliveryTypes, [ServerDeliveryType.NEXT_DAY, ServerDeliveryType.NEXT_THREE_DAYS]).length > 0)
}