import React, { Dispatch, SetStateAction } from "react"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import OwnDriverNameAndStatus from "@/UI/DataDisplay/OwnDriverCard/OwnDriverNameAndStatus"
import DisabledTooltip from "@/UI/DisabledTooltip"
import { Divider, Select, Space } from "antd"
import { Driver } from "@/types/drivers.types"
import { sortDrivers } from "@/utils/drivers"
import { OptimizationConfig } from "@/types/optimization"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus, faWarning } from "@fortawesome/free-solid-svg-icons"
import SelectPickupDropdown from "@/modules/new-orders/DraftOrderTable/components/BatchSelectors/components/SelectPickupDropdown"
import DriverModal from "@/modules/profile/DriversSettings/components/DriverModal"

interface OptimizationFormProps {
  drivers: Driver[]
  selectedDrivers: string[]
  setSelectedDrivers: Dispatch<SetStateAction<string[]>>
  setSelectedStoreId: Dispatch<SetStateAction<string | undefined>>
  selectedStoreId?: string
  optimizationConfig: OptimizationConfig | null
  isAllSelected: boolean
  orderCount: number
}

const OptimizationForm: React.FC<OptimizationFormProps> = ({
  drivers,
  selectedDrivers,
  setSelectedDrivers,
  setSelectedStoreId,
  optimizationConfig,
  isAllSelected,
  orderCount,
  selectedStoreId,
}) => {
  const { t } = useTranslation()
  const driversSortedByStatus = sortDrivers([...drivers])
  const showSelectStartLocation =
    optimizationConfig?.enableMultiplePickups &&
    drivers
      .filter((driver) => selectedDrivers.includes(driver.id))
      .some((driver) => !driver.config?.routeStartAddress)

  const selectAllDrivers = () => {
    setSelectedDrivers(drivers.map((driver) => driver.id))
  }

  const onlineDriversCount = drivers.filter((driver) => driver.status === "online").length
  const selectAllOnlineDrivers = () => {
    setSelectedDrivers(
      drivers.filter((driver) => driver.status === "online").map((driver) => driver.id)
    )
  }

  return (
    <div className="flex flex-col gap-y-2 mt-2">
      <div className="flex items-center gap-x-2">
        {isAllSelected ? (
          <>
            <FontAwesomeIcon icon={faWarning} />
            {t("new-orders.OptimizationForm.ordersCountToOptimize", { count: orderCount })}
          </>
        ) : (
          <>
            {t("new-orders.OptimizationForm.selectedOrdersCountToOptimize", { count: orderCount })}
          </>
        )}
      </div>
      {drivers.length > 0 && (
        <div className="flex flex-col gap-2 w-full">
          <span className="">{t("new-orders.DraftOrderTable.selectDrivers")}</span>
          <Select
            mode="multiple"
            size="large"
            placeholder={
              <span className="text-kosmoGray !text-sm">
                {t("new-orders.RouteOptimizationModal.selectDriversToOptimize")}
              </span>
            }
            value={selectedDrivers}
            onChange={setSelectedDrivers}
            optionFilterProp="label"
            allowClear
            filterSort={(a, b) => {
              return a.name - b.name
            }}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <DriverModal
                    button={
                      <div className="flex cursor-pointer">
                        <FontAwesomeIcon
                          className="flex flex-shrink-0 h-3 mx-auto my-auto text-kosmoBlue ml-1 mr-2"
                          icon={faCirclePlus}
                        />
                        <p className="whitespace-nowrap text-sm">{t("common.addDriver")}</p>
                      </div>
                    }
                  />
                </Space>
              </>
            )}
          >
            {driversSortedByStatus.map((driver) => (
              <Select.Option key={driver.id} value={driver.id} label={driver.name}>
                <OwnDriverNameAndStatus driver={driver} />
              </Select.Option>
            ))}
          </Select>
          <div className="flex w-full justify-end flex-wrap">
            <DisabledTooltip
              enable={onlineDriversCount === 0}
              label={t("new-orders.DraftOrderTable.thereIsNoOnlineDriver")}
            >
              <KosmoButton
                size="middle"
                type={"link"}
                classname={`text-kosmoPrimaryBlue`}
                isDisabled={selectedDrivers.length === drivers.length || onlineDriversCount === 0}
                onClick={selectAllOnlineDrivers}
              >
                {t("new-orders.DraftOrderTable.selectAllOnlineDrivers")}
              </KosmoButton>
            </DisabledTooltip>
            <KosmoButton
              size="middle"
              type={"link"}
              classname={` text-kosmoPrimaryBlue`}
              isDisabled={selectedDrivers.length === drivers.length}
              onClick={selectAllDrivers}
            >
              {t("new-orders.DraftOrderTable.selectAllDrivers")}
            </KosmoButton>
          </div>
        </div>
      )}
      {showSelectStartLocation && (
        <SelectPickupDropdown
          handleStore={async (storeId: string) => {
            setSelectedStoreId(storeId)
          }}
          selectedStore={selectedStoreId || null}
          disabled={false}
          loading={false}
        />
      )}
    </div>
  )
}

export default OptimizationForm
