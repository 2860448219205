import { useState } from "react"
import { useSetRecoilState } from "recoil"

import { orgUsersAtom } from "@/atoms/teamsAtom"

import { getAllOrgUsers } from "@/api/users"

interface IUseOrgUsers {
  fetchOrgUsers: () => Promise<void>
  isLoading: boolean
}

export const useOrgUsers = (): IUseOrgUsers => {
  const setOrgUsers = useSetRecoilState(orgUsersAtom)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fetchOrgUsers = async () => {
    setIsLoading(true)
    try {
      const { data } = await getAllOrgUsers()
      setOrgUsers(data?.users)
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return { fetchOrgUsers, isLoading }
}
