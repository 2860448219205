import React, { useState } from "react"
import { Input } from "antd"
import { SizeType } from "antd/lib/config-provider/SizeContext"

interface FloatInputPhoneProps {
  label: string
  name?: string
  value?: string
  placeholder: string
  required?: boolean
  prefix: React.ReactElement
  size?: SizeType
  onChange?: () => void
  inputRef?: any
  disabled?: boolean
}

//Custom Input on pickup/dropoff form
export const FloatInputPhone: React.FC<FloatInputPhoneProps> = (props) => {
  const [focus, setFocus] = useState(false)
  let { label, value, placeholder, size, disabled } = props

  if (!placeholder) placeholder = label
  if (disabled === undefined) disabled = false

  const isOccupied = focus || (value && value.length !== 0)

  const labelClass = isOccupied ? `${size} label as-label` : `${size} label as-phone-placeholder`

  return (
    <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <Input
        name={props.name}
        prefix={props.prefix}
        type={"tel"}
        ref={props.inputRef}
        value={props.value}
        onChange={props.onChange}
        size={size}
        className={"float-input phone-input"}
        style={{
          zIndex: "1",
        }}
        disabled={disabled}
        placeholder={placeholder || label}
      />
      <label style={{ zIndex: "1", fontSize: 8 }} className={labelClass}></label>
    </div>
  )
}
