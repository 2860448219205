import { Route } from "@/types/routes"
import { ACTIVE_ORDERS_PAGE_PATH, LOGIN_PAGE_PATH, NEW_ORDERS_PAGE_PATH, ONBOARDING_PAGE_PATH } from "@/constants/routes"

export const newOrdersRoute: Route = {
  isPublic: false,
  shouldRedirect: ({ isAuth, hasOrg, isSupportRole }) =>
    !isAuth || !hasOrg || isSupportRole,
  redirectTo: ({ hasOrg, isAuth, query, sandboxEnabled, isSupportRole }) => {
    if (isAuth) {
      if (!hasOrg) {
        return { newPath: ONBOARDING_PAGE_PATH, queryParams: query }
      } else if (isSupportRole) {
        return { newPath: ACTIVE_ORDERS_PAGE_PATH, queryParams: query }
      } else if (!sandboxEnabled) {
        return { newPath: NEW_ORDERS_PAGE_PATH, queryParams: query }
      }
    }
    return { newPath: LOGIN_PAGE_PATH, queryParams: query }
  },
}
