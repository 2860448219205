import React from "react"
import Image from "next/image"
import { vehicleImage } from "@/utils/providers"
import { IDisplayProviderProps, providerImage } from "./DisplayProvider"
import { getProviderName } from "@/types/providers.types"

const DisplayExternalCourier: React.FC<IDisplayProviderProps> = ({
  provider,
  driverId: serviceName,
  compact = false,
  logo = true,
  fontStyle = "font-semibold",
  showName = true,
  withVehicle = false,
  vehicleType,
  className = "",
}) => {
  const providerName = getProviderName(provider)

  return (
    <div className="flex flex-col space-y-1">
      <div className={`flex flex-row space-x-1.5 items-center ${className}`}>
        {withVehicle && vehicleType && (
          <div className="flex min-w-[24px]">
            <Image src={vehicleImage(vehicleType)} priority={true} width={24} height={24} />
          </div>
        )}
        {logo && (
          <div
            className={!compact ? "flex align-middle" : "flex items-center shrink-0"}
            style={{ width: 20, height: 20 }}
          >
            <Image src={providerImage(provider)} width={20} height={20} layout="fixed" />
          </div>
        )}
        {showName && (
          <p className={!compact ? `my-0 ${fontStyle}` : `my-0 ${fontStyle} whitespace-nowrap`}>
            {providerName}
            {!!serviceName && ` ${serviceName}`}
          </p>
        )}
      </div>
    </div>
  )
}

export default DisplayExternalCourier
