import React, { useCallback, useEffect, useRef } from "react"
import { Input, Form, Tooltip } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass, faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import _ from "lodash"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import { IFiltersState } from "@/hooks/useOrdersFilters"
import { useTranslation } from "react-i18next"
import { Loader } from "../Loader"

interface ISearchFilterProps {
  searchWidth?: string
  setSearchWidth?: (searchWidth: string) => void
  searchValue?: string
  filters?: IFiltersState
  handleSearchValue: (searchValue: string) => void
  size?: SizeType
  showTooltip?: boolean
  withAutocomplete?: boolean
  fluid?: boolean
  isLoading?: boolean
  allowEmpty?: boolean
  placeholder?: string
}

const SearchFilter: React.FC<ISearchFilterProps> = ({
  searchValue,
  handleSearchValue,
  searchWidth,
  filters = {},
  setSearchWidth,
  showTooltip = false,
  size = "large",
  withAutocomplete = true,
  fluid = false,
  isLoading = false,
  allowEmpty = false,
  placeholder = "",
}) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const inputRef = useRef(null)

  const onSearch = (e: any) => {
    handleSearchValue(e.target.value)
  }

  const debouncedSearch = useCallback(_.debounce(onSearch, 500), [searchValue, filters])

  const onBlur = () => {
    if (setSearchWidth) {
      setSearchWidth("3em")
    }
  }
  const onFocus = () => {
    if (setSearchWidth) {
      // TODO: This must be handled differently
      setSearchWidth("15em")
      //@ts-ignore
      if (inputRef?.current?.focus) {
        //@ts-ignore
        inputRef?.current?.focus()
      }
    }
  }

  useEffect(() => {
    if (searchValue || allowEmpty) {
      form.setFieldsValue({ search: searchValue })
    }
  }, [])

  return (
    <Form
      style={{ transition: "0.3s all", width: searchWidth }}
      form={form}
      name="search-box"
      className={`shrink-0 h-[28px] ${fluid ? "w-full" : ""}`}
    >
      <Form.Item name={"search"} className="mb-0">
        <Input
          ref={inputRef}
          style={{ maxWidth: searchWidth, lineHeight: 1.5715, height: 28 }}
          autoComplete={withAutocomplete ? "search" : "off"}
          prefix={
            <>
              {isLoading ? (
                <Loader fontSize={12} />
              ) : (
                <FontAwesomeIcon
                  onClick={onFocus}
                  className={"flex-shrink-0 mr-1 text-gray-600 text-sm"}
                  icon={faMagnifyingGlass}
                />
              )}
            </>
          }
          suffix={
            showTooltip && (
              <Tooltip title={t("common.activeOrders.searchTooltip")}>
                <FontAwesomeIcon
                  className={"flex-shrink-0 mr-1 text-gray-600 text-sm"}
                  icon={faQuestionCircle}
                />
              </Tooltip>
            )
          }
          className="max-h-[32px]"
          size={size}
          placeholder={placeholder || t("common.search")}
          onChange={debouncedSearch}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Form.Item>
    </Form>
  )
}

export default SearchFilter
