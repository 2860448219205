import { RedirectToParams, Route, ShouldRedirectParams } from "@/types/routes"
import { LOGIN_PAGE_PATH } from "@/constants/routes"

const ecommerceShouldRedirect: (shouldRedirectParams: ShouldRedirectParams) => boolean = ({ isAuth }) => {
  return !isAuth
}

const ecommerceRedirectTo: (redirectToParams: RedirectToParams) => {
  newPath: string
  queryParams?: object
} = ({ asPath }) => {
  return { newPath: LOGIN_PAGE_PATH, queryParams: { redirectPath: asPath, preventRedirect: true } }
}

export const ecommerceValidateRoute: Route = {
  isPublic: false,
  shouldRedirect: ecommerceShouldRedirect,
  redirectTo: ecommerceRedirectTo,
}
