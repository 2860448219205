import { KosmoGeocodeResult } from "@/types/here.types"
import {
  formatGoogleToKosmoGeocode,
  formatHereToKosmoDirections,
  googleGeocodeByAddress,
  hereGeocodeByAddress,
} from "@/utils/Geocoding/geocoding.utils"
import { useFlags } from "flagsmith/react"
import { CoordinatesLatLng } from "@/types/createSingleOrder.types"
import { userAtom } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"
import { orgOverrideConfig } from "./autocomplete/override_config/overrideConfig"

export interface GeocodingOpts {
  country?: string
  latLng?: CoordinatesLatLng
  postalCode?: string
  forceGoogle?: boolean
}

interface IUseGeocoding {
  geocodeByAddress: (
    queryAddress: string,
    opts?: GeocodingOpts
  ) => Promise<KosmoGeocodeResult | undefined>
}

export const useGeocoding = (): IUseGeocoding => {
  const flags = useFlags([
    "use_here_geocoding",
    "use_google_geocoding_countries",
    "show_address_inaccurate",
  ])
  const { org } = useRecoilValue(userAtom)

  let platform: H.service.Platform
  if (typeof window !== "undefined" && window.H) {
    platform = new window.H.service.Platform({
      apikey: process.env.NEXT_PUBLIC_HERE_API_KEY || "",
    })
  }

  const geocodeByAddress = async (
    queryAddress: string,
    opts?: {
      country?: string
      latLng?: CoordinatesLatLng
      postalCode?: string
      forceGoogle?: boolean
    }
  ): Promise<KosmoGeocodeResult | undefined> => {
    try {
      const { country, latLng, postalCode, forceGoogle } = opts || {}
      const countriesFlagged = JSON.parse(
        flags.use_google_geocoding_countries.value?.toString() || "{}"
      )?.countries
      const isCountryGoogleFlagged =
        countriesFlagged?.length === 0 || countriesFlagged?.includes(org?.country)

      let kosmoGeocodedAddress
      if (!forceGoogle && flags.use_here_geocoding.enabled && !isCountryGoogleFlagged) {
        const orgConfig = orgOverrideConfig[org?.id]
        const countryConstraint =
          orgConfig && orgConfig?.length > 1 ? undefined : country ?? org?.country

        kosmoGeocodedAddress = await hereGeocodeByAddress(
          platform,
          queryAddress,
          countryConstraint,
          latLng,
          postalCode
        )
        if (kosmoGeocodedAddress) {
          const hereGeocode = formatHereToKosmoDirections(kosmoGeocodedAddress, queryAddress)
          if (!flags.show_address_inaccurate.enabled && hereGeocode) {
            hereGeocode.isWarning = false
          }
          return hereGeocode
        }
      }
      // Use google as a fallback if no results with Here
      kosmoGeocodedAddress = await googleGeocodeByAddress(queryAddress, country, latLng)
      const googleResult = formatGoogleToKosmoGeocode(
        kosmoGeocodedAddress,
        queryAddress,
        latLng,
        postalCode
      )
      if (!flags.show_address_inaccurate.enabled && googleResult) {
        googleResult.isWarning = false
      }
      return googleResult
    } catch (error) {
      throw error
    }
  }

  return { geocodeByAddress }
}
