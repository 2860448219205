export enum VehicleType {
  // WALKER = "WALKER",
  BIKE = "BIKE",
  // MOTORBIKE = "MOTORBIKE",
  MOTORCYCLE = "MOTORCYCLE",
  // LALAGO = "LALAGO",
  // LALAPRO = "LALAPRO",
  // MPV = "MPV",
  CAR = "CAR",
  VAN = "VAN",
  // MV = "mV",
  // MC = "mC",
  // MR = "mR",
  LORRY = "LORRY",
}
