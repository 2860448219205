import { Button } from "antd"
import React from "react"

interface KosmoButtonProps {
  onClick?: (e?: any) => void
  isDisabled?: boolean
  htmlType?: "button" | "submit" | "reset" | undefined
  type?: "primary" | "default" | "success" | "danger" | "link" | undefined
  size?: "large" | "middle" | "small" | undefined
  classname?: string
  isLoading?: boolean
  id?: string
  danger?: boolean
  children: React.ReactNode
}

const KosmoButton: React.FC<KosmoButtonProps> = ({
  id,
  isDisabled,
  isLoading,
  onClick,
  type,
  classname,
  htmlType,
  size,
  danger,
  children,
  ...props
}) => {
  return (
    <Button
      id={id || ""}
      disabled={isDisabled}
      loading={isLoading}
      onClick={onClick}
      // @ts-ignore
      type={type || "primary"}
      className={`!flex !items-center !justify-center kosmo-btn-base pointer-events-auto ${classname}`}
      htmlType={htmlType}
      size={size}
      danger={danger}
      {...props}
    >
      {children}
    </Button>
  )
}

export default KosmoButton
