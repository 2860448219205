export const parcelDimensionsTypes = [
  "13cm x 13cm x 13cm / < 2kg",
  "20cm x 20cm x 20cm / < 4kg",
  "33cm x 33cm x 33cm / < 8kg",
  "40cm x 40cm x 40cm / < 10kg",
  "53cm x 53cm x 53cm / < 20kg",
  // "65cm x 65cm x 65cm / < 30kg",
  // "100cm x 100cm x 100cm / < 30kg",
]

export const defaultDimensions = {
  weight: 0,
  height: 0,
  length: 0,
  width: 0,
}
