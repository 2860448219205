import React, { ReactElement, useState } from "react"
import { Button, Modal, Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import KosmoButton from "../Buttons/KosmoButton"

const { Title } = Typography

type IModalType = "info" | "danger" | "warning"

interface IConfirmModalContent {
  type: IModalType
  title: string
  description: string | React.ReactElement
  cancelText: string
  okText: string
}

interface IConfirmModalProps {
  id?: string
  button: ReactElement
  content: IConfirmModalContent
  onConfirm: (() => Promise<void>) | (() => void)
  disabled?: boolean
  confirmDisabled?: boolean
  children?: React.ReactNode
  isLoading?: boolean
  className?: string
}

export const modalTypes = {
  danger: {
    icon: faTriangleExclamation,
    color: "text-red-600",
    iconBg: "bg-red-100",
  },
  info: {
    icon: faInfoCircle,
    color: "text-blue-600",
    iconBg: "bg-blue-100",
  },
  warning: {
    icon: faTriangleExclamation,
    color: "text-orange-600",
    iconBg: "bg-yellow-100",
  },
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  button,
  content,
  isLoading,
  confirmDisabled,
  onConfirm,
  disabled,
  id,
  children,
  className,
}) => {
  const [open, setOpen] = useState(false)

  const showModal = (e: React.MouseEvent) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    if (!disabled) {
      setOpen(true)
    }
  }

  const hideModal = (e: React.MouseEvent) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    setOpen(false)
  }

  return (
    <>
      {React.cloneElement(button, { onClick: showModal })}
      <Modal open={open} closable={false} footer={false} wrapClassName={className}>
        <div id={id ? id : "confirm-modal"}>
          <div className="flex">
            <div className="mr-3">
              <span className={`inline-block p-2 ${modalTypes[content.type].iconBg} rounded-full`}>
                <FontAwesomeIcon
                  icon={modalTypes[content.type].icon as IconProp}
                  className={`mx-1 ${modalTypes[content.type].color}`}
                />
              </span>
            </div>
            <div>
              <Title className="break-all" level={4}>
                {content.title}
              </Title>
              <p className="text-gray-500">{content.description}</p>
            </div>
          </div>
          {children}
          <div className="flex flex-row mt-5">
            <Button
              id="confirm-modal-cancel"
              className="ml-auto mr-3"
              onClick={hideModal}
              disabled={isLoading}
              loading={isLoading}
            >
              {content.cancelText}
            </Button>
            <KosmoButton
              id="confirm-modal-ok"
              onClick={async (e) => {
                await onConfirm()
                hideModal(e)
              }}
              isDisabled={isLoading || confirmDisabled}
              isLoading={isLoading}
            >
              {content.okText}
            </KosmoButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmModal
