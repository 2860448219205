import React, { useEffect } from "react"

import { Form } from "antd"
import { useRecoilValue } from "recoil"
import { userAtom } from "@/atoms/userAtom"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import { postStore, formatStore } from "@/api/stores"

import {
  AliasFormItem,
  BuildingNumberFormItem,
  PhoneFormItem,
  DeliveryNoteFormItem,
  SenderNameFormItem,
  AddressAutocomplete,
} from "@/UI"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import { useDeliveryForm } from "@/hooks/useDeliveryForm"
import { useTranslation } from "react-i18next"
import { PickupDetailsType } from "@/types/createSingleOrder.types"
import { useStore } from "@/hooks/useStore"
import { openStoreAddedSuccess } from "@/UI/Notifications/NotificationsWrappers.component"
import { EmailFormItem } from "@/UI/Forms/FormItems/EmailFormItem"
import { CountryCode } from "libphonenumber-js"

// TODO: On next change, switch isModalVisible with callback
interface CreateLocationSettingsFormProps {
  callback?: () => void
  isModal?: boolean
}

export type Postcode = { [key: string]: string }

const CreateLocationSettingsForm: React.FC<CreateLocationSettingsFormProps> = ({ callback }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const user = useRecoilValue(userAtom)
  const { setCreatedStore } = useStore()

  const {
    geocodeAutocompletePrediction,
    onLocationSearchChange,
    handlePhoneSelect,
    searchValue,
    postCode,
    countryCode,
    isAddressValid,
    formPickupCoordinates,
  } = useDeliveryForm(form)

  useEffect(() => {
    // Prefill form with user phone
    if (user && user.phone) {
      form.setFieldsValue({
        phone: formatPhoneNumber(user.phone, countryCode as CountryCode),
      })
    }
  }, [])

  const handleSelectPrediction = async (prediction: any) => {
    try {
      const geocodeResult = await geocodeAutocompletePrediction(prediction)
      form.setFieldValue("address", geocodeResult?.address.label)
    } catch (error) {
      console.log(error, "predictions - dropoffDetails")
    }
  }

  const submitStoreForm = async (storeFormValues: PickupDetailsType) => {
    try {
      const formattedStore = formatStore(formPickupCoordinates, {
        ...storeFormValues,
        postalCode: postCode || "",
        phone: (storeFormValues?.phone || "").replace(/\+/g, "").replace(/\s/g, ""),
      })

      const { data } = await postStore(formattedStore)
      setCreatedStore(data)
      if (callback) {
        callback()
      }
      openStoreAddedSuccess(t)
    } catch (error) {
      console.log("onboarding error", error)
    }
  }

  return (
    <Form
      form={form}
      name={"settings-store-form"}
      onFinish={submitStoreForm}
      style={{ textAlign: "left" }}
      layout="vertical"
      validateTrigger="onSubmit"
    >
      <AliasFormItem
        name={"alias"}
        placeholder={t("create-order.forms.locationName")}
        validationErrorText={t("create-order.forms.enterAlias")}
        withWarning
      />
      <SenderNameFormItem
        name={"name"}
        placeholder={t("create-order.forms.senderFullName")}
        label={t("create-order.forms.senderFullName")}
        validationErrorText={t("common.forms.enterName")}
      />
      <AddressAutocomplete
        name="address"
        label={t("create-order.forms.addressIncludingStreetNumber")}
        placeholder={t("create-order.forms.addressIncludingStreetNumber")}
        searchValue={searchValue}
        onSelected={handleSelectPrediction}
        onChange={(e: any) => {
          if (onLocationSearchChange) {
            onLocationSearchChange(e)
          }
        }}
        isInvalid={!isAddressValid && !!searchValue.length}
      />
      <BuildingNumberFormItem
        name={"buildingNumber"}
        label={t("create-order.forms.buildingNumberPlaceholder")}
        placeholder={t("create-order.forms.buildingNumberPlaceholder")}
      />
      <PhoneFormItem
        handlePhoneSelect={handlePhoneSelect}
        countryCode={countryCode || "SG"}
        skipValidation
      />
      <EmailFormItem name="email" placeholder={t("common.forms.emailAddressOptional")} />
      <DeliveryNoteFormItem
        name="notes"
        label={t("create-order.forms.pickupInstructions")}
        placeholder={t("create-order.forms.pickupInstructionsPlaceholder")}
        size="middle"
      />
      <div className="flex w-full justify-end mt-6 space-x-4">
        <KosmoButton
          type="default"
          onClick={() => {
            if (callback) {
              callback()
            }
          }}
          classname="font-semibold"
        >
          {t("common.cancel")}
        </KosmoButton>
        <div className="ml-2">
          <KosmoButton type="primary" classname="font-semibold" htmlType="submit">
            {t("profile.AddStoreModal.addNewLocation")}
          </KosmoButton>
        </div>
      </div>
    </Form>
  )
}

export default CreateLocationSettingsForm
