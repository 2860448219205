import { useState } from "react"
import { useRecoilState } from "recoil"
import { driversSettingsAtom } from "@/atoms/driverSettingsAtom"
import { AppConfiguration, DriversSettings, PriceConfiguration } from "@/types/drivesAppSettings"
import {
  deletePriceConfiguration,
  getAppConfiguration,
  getPriceConfiguration,
  putAppConfiguration,
  putPriceConfiguration,
} from "@/api/price-configuration"

interface IUseDriverSettings {
  driverSettings: DriversSettings
  isLoading: boolean
  fetchDriverSettings: () => Promise<void>
  updateDriverPaySettings: (driverPay: PriceConfiguration) => Promise<void>
  deleteDriverPaySetting: (configId: string) => Promise<void>
  updateDriverAppSettings: (driverApp: AppConfiguration) => Promise<void>
}

export const useDriverSettings = (): IUseDriverSettings => {
  const [driverSettings, setDriverSettings] = useRecoilState(driversSettingsAtom)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchDriverSettings = async () => {
    setIsLoading(true)
    try {
      const { data: priceData } = await getPriceConfiguration()
      const { data: appData } = await getAppConfiguration()
      setDriverSettings({
        ...driverSettings,
        driverPay: priceData.configs,
        driverApp: appData,
      })
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const updateDriverPaySettings = async (driverPay: PriceConfiguration) => {
    setIsLoading(true)
    try {
      const { data } = await putPriceConfiguration(driverPay)
      setDriverSettings({
        ...driverSettings,
        driverPay: data.configs,
      })
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const updateDriverAppSettings = async (driverApp: AppConfiguration) => {
    setIsLoading(true)
    try {
      const { data } = await putAppConfiguration(driverApp)
      setDriverSettings({
        ...driverSettings,
        driverApp: data,
      })
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDriverPaySetting = async (configId: string) => {
    setIsLoading(true)
    try {
      await deletePriceConfiguration(configId)
      setDriverSettings({
        ...driverSettings,
        driverPay: driverSettings.driverPay?.filter((config) => config.id !== configId),
      })
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return {
    driverSettings,
    isLoading,
    fetchDriverSettings,
    updateDriverPaySettings,
    deleteDriverPaySetting,
    updateDriverAppSettings,
  }
}
