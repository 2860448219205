import { useState } from "react"

import { deleteDispatchRule, getDispatchRules, putDispatchRule } from "@/api/dispatchRules"
import { dispatchRulesAtom } from "@/atoms/dispatchRulesAtom"
import { IDispatchRule, PutDispatchRulePayload } from "@/types/dispatchRules.types"

import { useRecoilState } from "recoil"

interface IUseDispatchRule {
  createDispatchRule: (
    rule: PutDispatchRulePayload,
    ruleId?: string
    // type: DispatchRuleType
  ) => Promise<void>
  removeDispatchRule: (id: string) => Promise<void>
  fetchDispatchRules: () => Promise<void>
  dispatchRules: IDispatchRule[]
  isLoading: boolean
}

export const useDispatchRule = (): IUseDispatchRule => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dispatchRules, setDispatchRules] = useRecoilState(dispatchRulesAtom)

  const createDispatchRule = async (rule: PutDispatchRulePayload, ruleId?: string) => {
    try {
      setIsLoading(true)
      const { data } = await putDispatchRule(rule, ruleId)
      setDispatchRules((state) => {
        const newState = (state || []).filter((rule) => rule.id !== ruleId)
        return [...newState, data]
      })
    } catch (error) {
      console.log(error, "error creating rule")
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDispatchRules = async () => {
    try {
      setIsLoading(true)
      const { data } = await getDispatchRules()

      setDispatchRules(data || [])
    } catch (error) {
      console.log(error, "error fetching rules")
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const removeDispatchRule = async (id: string) => {
    try {
      setIsLoading(true)
      await deleteDispatchRule(id)
      // TODO: Once backend returns deleted rule, filter on returned object
      setDispatchRules((state) => {
        const newDispatchList = state.filter((rule) => rule.id !== id)
        return [...newDispatchList]
      })
    } catch (error) {
      console.log(error, "error deleting rule")
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return {
    createDispatchRule,
    removeDispatchRule,
    fetchDispatchRules,
    dispatchRules,
    isLoading,
  }
}
