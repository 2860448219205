import React, { useRef } from "react"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form } from "antd"
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js"
import { useTranslation } from "react-i18next"
import { FloatInputPhone } from "../../Inputs/FloatInputPhone"
import { PhonecodeDropdown } from "../../PhonecodeDropdown"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import { brandingColorsSelector } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"

interface IPhoneFormItemProps {
  countryCode: string
  onChange?: () => void
  handlePhoneSelect: (newCountryCode: string) => void
  size?: SizeType
  validateTrigger?: string | string[]
  disabled?: boolean
  skipValidation?: boolean
}

export const PhoneFormItem: React.FC<IPhoneFormItemProps> = ({
  countryCode,
  onChange,
  handlePhoneSelect,
  size = "middle",
  validateTrigger = "onChange",
  disabled = false,
  skipValidation = false,
}) => {
  const { t } = useTranslation()
  const { primaryColor } = useRecoilValue(brandingColorsSelector)
  const inputRef = useRef<any>(null)

  return (
    <Form.Item
      style={{ marginBottom: "1.2em" }}
      name={"phone"}
      id="phone"
      validateTrigger={validateTrigger}
      rules={[
        {
          required: !skipValidation,
          message: t("common.PhoneFormItem.enterPhoneWarning"),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            const phone = getFieldValue("phone")
            // TODO: TEMPORARY PATCH FOR OLACLICK PILOT
            const isBrazilAndMissingArea =
              countryCode === "BR" && value.replace(/ /g, "").length < 13 ? true : false

            if (
              !isBrazilAndMissingArea &&
              (!value || isValidPhoneNumber(phone, countryCode as CountryCode))
            ) {
              return Promise.resolve()
            } else {
              return Promise.reject(t("common.notification.invalidPhoneNumber"))
            }
          },
          warningOnly: skipValidation,
        }),
      ]}
    >
      <FloatInputPhone
        name="phone"
        label={t("common.phoneNumber")}
        placeholder={t("common.phoneNumber")}
        size={size}
        inputRef={inputRef}
        onChange={onChange}
        prefix={
          <>
            <FontAwesomeIcon
              className="flex-shrink-0 h-5 mr-2"
              icon={faPhone}
              style={{ color: primaryColor ?? "#4100FF" }}
            />
            <PhonecodeDropdown
              handlePhoneSelect={(e) => {
                handlePhoneSelect(e)
                inputRef?.current?.focus()
              }}
              countryCode={countryCode || "SG"}
              disabled={disabled}
            />
          </>
        }
        disabled={disabled}
      />
    </Form.Item>
  )
}
