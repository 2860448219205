export const v16Properties = [
  "orderNumber",

  // "productType",
  "deliveryType",
  "vehicleType",

  "date",
  "scheduledTime",
  "dropoffByTime",

  "senderName",
  "senderAddress",
  "senderBuildingNumber",
  "senderPhone",
  "senderEmail",
  "senderNote",

  "recipientName",
  "recipientAddress",
  "buildingNumber",
  "recipientPhone",
  "recipientEmail",
  "dropOffNote",

  "parcelValue",
  "parcelDimensions",

  "orderReferenceId",
]
