const googleGeocodeWithBounds = (address: string, region?: string, bounds?: google.maps.LatLngBounds): Promise<{ results: google.maps.GeocoderResult[] }> => {
  const geocoder = new window.google.maps.Geocoder()
  const { OK } = window.google.maps.GeocoderStatus
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      { address, region, bounds },
      (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
        if (status !== OK) {
          return reject(status)
        }
        return resolve({ results: results || [] })
      }
    )
  })
}

export default googleGeocodeWithBounds
