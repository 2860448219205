import { DatePicker } from "antd"
import { DateTime, Settings, type DateTime as DateTimeType } from "luxon"
import luxonGenerateConfig from "rc-picker/lib/generate/luxon"

const LuxonDatePicker = DatePicker.generatePicker<DateTimeType>({
  ...luxonGenerateConfig,
  getNow: () => DateTime.now().setZone(Settings.defaultZone),
})

export default LuxonDatePicker
