import React from "react"
import { Form } from "antd"
import { FloatTextArea } from "../../Inputs/FloatTextArea"
import { SizeType } from "antd/lib/config-provider/SizeContext"

interface IDeliveryNoteFormItemProps {
  name: string
  label: string
  placeholder: string
  size?: SizeType
}

export const DeliveryNoteFormItem: React.FC<IDeliveryNoteFormItemProps> = ({
  name,
  label,
  placeholder,
  size = "large",
}) => {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: false,
        },
      ]}
    >
      <FloatTextArea label={label} placeholder={placeholder} size={size} />
    </Form.Item>
  )
}
