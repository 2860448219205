import axios from "@/modules/shared/AxiosInterceptor"
import { OptimizationConfig } from "@/types/optimization"

export async function getOptimizationConfig() {
  return axios
    .get<OptimizationConfig>("/optimization/config")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putOptimizationConfig(config: OptimizationConfig) {
  return axios
    .put<OptimizationConfig>("/optimization/config", { ...config })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
