import { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { AutocompletionRequest, IPrediction, LatLng } from "./GooglePlacesAutocomplete.types"
import autocompletionRequestBuilder from "./helpers/autocompletionRequestBuilder"
import { localizationAtom } from "@/atoms/localizationAtom"
import { useRecoilValue } from "recoil"
import { userAtom } from "@/atoms/userAtom"
import { logEvent } from "firebase/analytics"
import { analytics } from "@/modules/shared/firebase"
import { orgOverrideConfig } from "./override_config/overrideConfig"

export type FetchPredictionsParams = {
  location?: LatLng
  defaultCountry?: string
  radius?: number
  types?: string[]
  sessionId?: string
  seconds_since_last_request?: number
}

interface IUseGmap {
  autoCompleteHandler: (value: string, opts?: FetchPredictionsParams) => Promise<IPrediction[]>
  fetchPredictions: (value: string, opts?: FetchPredictionsParams) => void
  predictions: IPrediction[]
  setPredictions: (predictions: IPrediction[]) => void
  calculateExtraDebounce: (nbChars: number) => number
  debounceTime: number
  isReady: boolean
}

const usePlacesAutocomplete = (_?: { language?: string; region?: string }): IUseGmap => {
  const debounceTime = 1000
  const [placesService, setPlacesService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined)

  // const orgCountry = useRecoilValue(orgCountryAtom)

  // const countryConfig = isEmpty(autocompleteCountryConfig[orgCountry])
  //   ? autocompleteCountryConfig["default"]
  //   : autocompleteCountryConfig[orgCountry]

  const config = {
    debounce: debounceTime,
    minLengthAutocomplete: 3,
    types: [],
    // process.env.NEXT_PUBLIC_GOOGLE_MAPS_AUTOCOMPLETE_CONSTRAINT?.split(",") ||
    radius: 15000,
  }

  const [predictions, setPredictions] = useState<IPrediction[]>([])
  const { locale } = useRecoilValue(localizationAtom)
  const { org } = useRecoilValue(userAtom)
  const isPredictionRelevant = (_: string[]) => {
    // const isBlacklisted = countryConfig?.blacklist.some((item) => types.includes(item))
    // const isWhitelisted = countryConfig?.whitelist.some((item) => types.includes(item))
    return true // isWhitelisted || !isBlacklisted
  }

  const autoCompleteHandler = async (
    value: string,
    opts?: FetchPredictionsParams
  ): Promise<IPrediction[]> => {
    const { defaultCountry, location, radius, types, sessionId, seconds_since_last_request } =
      opts || {}
    if (!placesService) {
      setPredictions([])
      return []
    }
    if (!value || value?.length < config.minLengthAutocomplete) {
      setPredictions([])
      return []
    }
    const orgConfig = orgOverrideConfig[org?.id]
    const countryRestriction = defaultCountry ? defaultCountry : !!orgConfig ? orgConfig : locale

    const autocompletionReq: AutocompletionRequest = {
      componentRestrictions: {
        country: countryRestriction,
      },
      types: types || config.types,
    }

    if (location?.lat && location.lng) {
      autocompletionReq.location = location
      autocompletionReq.radius = radius || config.radius
    }
    let predictions: IPrediction[] = []

    await placesService.getPlacePredictions(
      autocompletionRequestBuilder(autocompletionReq, value),
      (suggestions) => {
        if (analytics) {
          logEvent(analytics, "auto_complete_search", {
            org_id: org?.id,
            search_length: value?.length,
            results_count: suggestions?.length || 0,
            address: value,
            session_id: sessionId,
            seconds_since_last_request: seconds_since_last_request,
          })
        }
        const formattedPredictions = (suggestions || [])
          // Whitelists and blacklists
          .filter((rawSuggestion) => {
            return isPredictionRelevant(rawSuggestion?.types)
          })
          .map((suggestion) => ({
            description: suggestion.description,
            place_id: suggestion.place_id,
            value: suggestion,
            type: "address",
          }))

        setPredictions(formattedPredictions)
        predictions = formattedPredictions
        return formattedPredictions
      }
    )
    return predictions
  }

  const [fetchPredictions] = useDebouncedCallback(autoCompleteHandler, config.debounce)

  const calculateExtraDebounce = (nbChars: number) => {
    if (nbChars < 30) {
      return 0
    }

    let logMin = Math.log(100)
    let logMax = Math.log(500)
    let logValue = logMin + nbChars * (logMax - logMin)

    return Math.floor(Math.exp(logValue))
  }

  const initializeService = () => {
    if (!window.google)
      throw new Error("[react-google-places-autocomplete]: Google script not loaded")
    if (!window.google.maps)
      throw new Error("[react-google-places-autocomplete]: Google maps script not loaded")
    if (!window.google.maps.places)
      throw new Error("[react-google-places-autocomplete]: Google maps places script not loaded")

    setPlacesService(new window.google.maps.places.AutocompleteService())
  }

  const init = async () => {
    try {
      initializeService()
    } catch (_) {
      // console.error(error)
    }
  }
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY) {
      init()
    }
  }, [window?.google])

  return {
    autoCompleteHandler,
    fetchPredictions,
    predictions,
    setPredictions,
    calculateExtraDebounce,
    debounceTime,
    isReady: !!placesService,
  }
}

export default usePlacesAutocomplete
