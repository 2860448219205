export const v28Properties = [
  "recipientName",
  "recipientAddress",
  "recipientPostalCode",
  "recipientCountry",
  "buildingNumber",
  "recipientLatitude",
  "recipientLongitude",
  "recipientPhone",
  "dropOffNote",
  "recipientEmail",

  "senderName",
  "senderAddress",
  "senderPostalCode",
  "senderBuildingNumber",
  "senderLatitude",
  "senderLongitude",
  "senderPhone",
  "pickupNotes",
  "senderEmail",

  "date",
  "scheduledTime",
  "arrivalTimeFrom",
  "arrivalTimeTo",
  "timeAtStop",

  "teamName",
  "driverName",

  "parcelValue",
  "parcelWeight",
  "parcelLength",
  "parcelHeight",
  "parcelWidth",
  "parcelQuantity",
  "parcelDescription",

  "orderReferenceId",
  "barcode",

  "priority",

  "stopType",

  "clientId"
]
