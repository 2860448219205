import { useContext } from "react"
import { SandboxContext } from "@/layout/Nav/SandboxSwitcher"
import { NextRouter, useRouter } from "next/router"

interface IUseSandboxRouter {
  sandboxRouter: SandboxRouter & SandboxRouter
}

interface SandboxRouter extends NextRouter {
  pushWithContext: (
    { targetHref, query }: { targetHref: string; query?: any },
    shallow?: boolean
  ) => void
  getContextHref: (targetHref: string) => string
  isSandboxPath: () => boolean
}

export const useSandboxRouter = (): IUseSandboxRouter => {
  const router = useRouter()
  const sandbox = useContext(SandboxContext)

  const getContextHref = (targetHref: string) => {
    const href = sandbox.enabled ? `/test${targetHref?.replace("/test", "")}` : targetHref
    return href
  }

  const pushWithContext = (
    { targetHref, query }: { targetHref: string; query?: any },
    shallow?: boolean
  ) => {
    // Sandbox prefix could be set as constant
    const href = getContextHref(targetHref)
    router.push({ pathname: href, query: query }, undefined, { shallow: shallow })
  }

  const isSandboxPath = () => {
    return router.asPath.startsWith("/test")
  }

  const sandboxRouter = {
    ...router,
    pushWithContext,
    getContextHref,
    isSandboxPath,
  }

  return { sandboxRouter }
}
