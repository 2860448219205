import React, { useState } from "react"
import { Input } from "antd"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isEmpty } from "lodash"
import { brandingColorsSelector } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"

interface IFloatInputProps {
  label?: string
  value?: string | number
  placeholder?: string
  type?: string
  prefixIcon?: IconDefinition
  onChange?: (e?: any) => void
  required?: boolean
  size?: SizeType
  name?: string
  helpText?: string
  defaultValue?: string | number
  classname?: string
  disabled?: boolean
}

//Custom Input on pickup/dropoff form
export const FloatInput: React.FC<IFloatInputProps> = (props) => {
  const { primaryColor } = useRecoilValue(brandingColorsSelector)

  const [focus, setFocus] = useState(false)
  let { label, value, placeholder, type, required, size, disabled } = props

  if (!placeholder) placeholder = label
  if (disabled === undefined) disabled = false

  const isOccupied = focus || !isEmpty(value) || (typeof value === "number" && value >= 0)

  const labelClass = isOccupied ? `${size} label as-label` : `${size} label as-placeholder`

  const requiredMark = required ? <span className="text-danger text-red-600">*</span> : null

  return (
    <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <Input
        id={props.name}
        name={props.name}
        {...(!!props.prefixIcon
          ? {
              prefix: (
                <>
                  <FontAwesomeIcon
                    className={`flex-shrink-0 h-4 mr-2`}
                    style={{
                      color: isOccupied ? primaryColor ?? "#4100FF" : "text-gray-400",
                    }}
                    icon={props.prefixIcon}
                  />
                </>
              ),
            }
          : {})}
        onChange={props.onChange}
        type={type}
        value={props.value}
        size={size}
        defaultValue={props.defaultValue}
        autoComplete="new-password"
        className={`float-input ${props.classname}`}
        style={{
          zIndex: "1",
        }}
        placeholder={focus ? "" : placeholder}
        disabled={disabled}
      />
      <label
        style={{ zIndex: "1", lineHeight: size === "middle" ? 1.3 : "" }}
        className={`${labelClass}
         whitespace-nowrap text-ellipsis overflow-hidden`}
      >
        {isOccupied ? label : ""} {requiredMark}
      </label>
    </div>
  )
}
