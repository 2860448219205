export enum DeliveryType {
  INSTANT = "Instant",
  SCHEDULE = "Schedule",
  FOURHOURS = "FourHours",
}

export enum ServerDeliveryType {
  EMPTY = "",
  INSTANT = "instant",
  FOURHOURS = "4_hours",
  NEXT_DAY = "next_day",
  NEXT_THREE_DAYS = "next_3_days",
}

export enum DeliveryTypeLabel {
  INSTANT = "Express",
  SCHEDULED = "Scheduled",
  FOURHOURS = "4 Hours",
  SAME_DAY = "Same Day",
  NEXT_DAY = "1 day",
  NEXT_THREE_DAYS = "3 days",
  EMPTY = "Empty",
}

export enum NetworkDeliveryTypeLabel {
  INSTANT = "Instant",
  FOURHOURS = "4 Hours",
  NEXT_DAY = "Tomorrow",
  NEXT_THREE_DAYS = "3 Days",
}

export interface IDeliveryTypePayload {
  type: ServerDeliveryType | undefined
  time: string
  schedule?: number
}
