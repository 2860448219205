import React, { ReactElement } from "react"

import { ConfigProvider } from "antd"
import { brandingColorsSelector } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"

interface ThemeProviderProps {
  children: ReactElement
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { primaryColor, secondaryColor, textColor } = useRecoilValue(brandingColorsSelector)

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          colorPrimary: primaryColor,
          borderRadius: 6,
          controlHeight: 28,
          fontSize: 13,
          fontSizeSM: 11,
          ...(textColor ? { colorText: textColor } : {}),
        },
        components: {
          Table: {
            selectionColumnWidth: 45,
          },
          Tabs: {
            inkBarColor: `${primaryColor} !important`,
          },
          Input: {
            activeBorderColor: `${primaryColor} !important`,
            hoverBorderColor: `${primaryColor} !important`,
          },
          Menu: {
            controlHeight: 32,
            ...(secondaryColor
              ? {
                  itemSelectedBg: secondaryColor,
                }
              : {}),
          },
          Form: {
            itemMarginBottom: 16,
            labelHeight: 34,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

export default ThemeProvider
