import { VehicleType } from "@/types/vehicle.types"
import { vehicleImage } from "@/utils/providers"
import Image from "next/image"
import React from "react"

interface VehicleTypeSelectorProps {
  vehicles: VehicleType[]
  selectedVehicleType?: VehicleType | null
  onClick: (vehicleType: VehicleType) => void
  displayType?: "compact" | "full"
}
const sortingArray = [VehicleType.MOTORCYCLE, VehicleType.CAR, VehicleType.VAN, VehicleType.LORRY]
const VehicleTypeSelector: React.FC<VehicleTypeSelectorProps> = ({
  vehicles,
  selectedVehicleType,
  onClick,
  displayType = "full",
}) => {
  const styles = {
    compact: {
      container: "p-1 h-10 w-10",
      size: 24,
    },
    full: {
      container: "p-2 h-14 w-14",
      size: 40,
    },
  }

  return (
    <>
      <div className={`flex ${displayType === "full" ? "gap-x-5" : "gap-x-2"}`}>
        {vehicles
          .sort((a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b))
          .map((vehicle: VehicleType, index) => (
            <div
              key={index}
              className={`flex items-center justify-center cursor-pointer rounded-lg border ${
                styles[displayType].container
              } ${selectedVehicleType === vehicle ? "bg-gray-200" : ""}`}
              onClick={() => onClick(vehicle)}
            >
              <Image
                src={vehicleImage(vehicle)}
                priority={true}
                width={styles[displayType].size}
                height={styles[displayType].size}
              />
            </div>
          ))}
      </div>
    </>
  )
}

export default VehicleTypeSelector
