import React, { useState } from "react"
import { Modal, Button } from "antd"
import { ReactComponentElement, ReactNode } from "react"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SemiBoldLabelText } from "./Texts"

interface IPreventWithModalProps {
  enable: boolean
  label: ReactNode | string
  cta: {
    label: string
    onClick: () => void
  }
  children: ReactComponentElement<any>
}

const PreventWithModal: React.FC<IPreventWithModalProps> = ({
  enable,
  label,
  cta,
  children,
  ...props
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setIsModalVisible(true)
  }

  const handleCTA = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setIsModalVisible(false)
    cta.onClick()
  }

  const handleCancel = (e: any) => {
    e.stopPropagation()
    setIsModalVisible(false)
  }

  return enable ? (
    <div>
      {React.cloneElement(children, { ...props, onClick: showModal, onChange: undefined })}
      <Modal
        open={isModalVisible}
        footer={
          <div className="flex w-full justify-end">
            <Button type="primary" onClick={handleCTA}>
              {cta.label}
            </Button>
          </div>
        }
        closable
        onCancel={handleCancel}
        destroyOnClose
      >
        <div className="flex flex-col gap-y-6 items-center justify-center">
          <FontAwesomeIcon icon={faCircleInfo} fontSize={48} color={"#4100FF"} />
          <SemiBoldLabelText>{label}</SemiBoldLabelText>
        </div>
      </Modal>
    </div>
  ) : (
    React.cloneElement(children, { ...props })
  )
}

export default PreventWithModal
