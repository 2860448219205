import { Route } from "@/types/routes"

import { loginRoute } from "./login"
import { profileRoute } from "./profile"
import { newOrdersRoute } from "./new-orders"
import { developersRoute } from "./developers"
import { ordersRoute } from "./orders"
import { analyticsRoute } from "./analytics"
import { scanRoute } from "./scan"

import {
  DEVELOPERS_PAGE_PATH,
  NEW_ORDERS_PAGE_PATH,
  ECOMMERCE_VALIDATE_PATH,
  ANALYTICS_PAGE_PATH,
  INVITATION_PAGE_PATH,
  LOGIN_PAGE_PATH,
  ONBOARDING_PAGE_PATH,
  ACTIVE_ORDERS_PAGE_PATH,
  PROFILE_PAGE_PATH,
  SIGNUP_PAGE_PATH,
  MAP_PAGE_PATH,
  AFFILIATE_PAGE_PATH,
  CUSTOMER_PORTAL_PAGE_PATH,
  SCAN_PAGE_PATH,
} from "@/constants/routes"
import { invitationRoute } from "./invitation"
import { ecommerceValidateRoute } from "./ecommerce"
import { onboardingRoute } from "./onboarding"
import { signupRoute } from "./signup"
import { mapRoute } from "./map"
import { affiliateRoute } from "./affiliate"
import { customerPortalRoute } from "./customer-portal"

export const routes = {
  [LOGIN_PAGE_PATH]: loginRoute,
  [PROFILE_PAGE_PATH]: profileRoute,
  [NEW_ORDERS_PAGE_PATH]: newOrdersRoute,
  [DEVELOPERS_PAGE_PATH]: developersRoute,
  [ACTIVE_ORDERS_PAGE_PATH]: ordersRoute,
  [ANALYTICS_PAGE_PATH]: analyticsRoute,
  [MAP_PAGE_PATH]: mapRoute,
  [INVITATION_PAGE_PATH]: invitationRoute,
  [ECOMMERCE_VALIDATE_PATH]: ecommerceValidateRoute,
  [ONBOARDING_PAGE_PATH]: onboardingRoute,
  [SIGNUP_PAGE_PATH]: signupRoute,
  [CUSTOMER_PORTAL_PAGE_PATH]: customerPortalRoute,
  [AFFILIATE_PAGE_PATH]: affiliateRoute,
  [SCAN_PAGE_PATH]: scanRoute,
} as Record<string, Route>
