import React from "react"
import { Driver } from "@/types/drivers.types"
import { Tooltip } from "antd"
import { capitalize } from "lodash"

interface OwnDriverNameAndStatusProps {
  driver: Driver
  driverNameClassName?: string
}

export const DriverOnlineDot: React.FC<{}> = ({}) => {
  return <span className="w-[6px] h-[6px] inline-block bg-green-500 rounded-md" />
}

export const DriverIdleDot: React.FC<{}> = ({}) => {
  return <span className="w-[6px] h-[6px] inline-block bg-yellow-500 rounded-md" />
}

export const DriverLateDot: React.FC<{}> = ({}) => {
  return <span className="w-[6px] h-[6px] inline-block bg-red-500 rounded-md" />
}

const OwnDriverNameAndStatus: React.FC<OwnDriverNameAndStatusProps> = ({
  driver,
  driverNameClassName = "",
}) => {
  return (
    <div title={capitalize(driver?.status)} className="flex items-center gap-x-1">
      <p className={`capitalize ${driverNameClassName}`}>{driver?.name} </p>
      {driver?.status === "online" && (
        <Tooltip title={capitalize(driver?.status)}>
          <DriverOnlineDot />
        </Tooltip>
      )}
      {driver?.status === "idle" && (
        <Tooltip title={capitalize(driver?.status)}>
          <DriverIdleDot />
        </Tooltip>
      )}
      {driver?.status === "late" && (
        <Tooltip title={capitalize(driver?.status)}>
          <DriverLateDot />
        </Tooltip>
      )}
    </div>
  )
}

export default OwnDriverNameAndStatus
