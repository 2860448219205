const supportedLngs = ['en', 'ms', 'pt', 'es', 'id', 'zh-CN', 'zh-TW', 'zh']
export const ni18nConfig = {
  supportedLngs,
  fallbackLng: 'en',
  ns: ['common'],
  react: {
    useSuspense: false,
  },
  interpolation: { escapeValue: false }
}
