import { ServerDeliveryType } from '@/types/deliveryTypes.types';
import { VehicleType } from '@/types/vehicle.types';
import { ProvidersType } from "./providers.types"
import { AssignmentTypes } from './draftOrder.types';

export type RuleFormObject = {
  name: string
  disabled: boolean
  priority: DispatchRuleType
  assignmentType: AssignmentTypes
  couriers: (string | ProvidersType)[]
  maxPrice: number
  deliveryType?: ServerDeliveryType
  vehicleType?: VehicleType
  reassignTime: number
}

export interface IDispatchRule {
  id: string
  disabled: boolean
  name: string
  type: DispatchRuleType
  couriers?: DispatchRuleCouriers[]
  providers: ProvidersType[]
  priority: DispatchRuleType
  createdAt: number
  maxPrice: number
  ruleId: string
  deliveryType?: ServerDeliveryType
  vehicleType?: VehicleType
  reassignTime: number
}
export type PutDispatchRulePayload = Omit<IDispatchRule, "id" | "createdAt" | "type" | "ruleId">

export enum DispatchRuleType {
  PRICE = "PRICE",
  ETA = "ETA",
  RANK = "RANK",
  ZONE = "ZONE",
}

export enum DispatchCourierType {
  OWN_FLEET = "OWN_FLEET",
  THIRD_PARTY = "3pl",
}

export interface DispatchRuleCouriers {
  assignmentType: AssignmentTypes,
  courierId?: number,
  provider: string,
  type: DispatchCourierType
}

// Couriers * []struct {
//   AssignmentType * AssignmentType`json:"assignmentType,omitempty"`
//   CourierId * string`json:"courierId,omitempty"`
//   Provider * string`json:"provider,omitempty"`
//   Type * CourierType`json:"type,omitempty"`
// } `json:"couriers,omitempty"`
