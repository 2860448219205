import Geocode from "react-geocode"
import { Parcel, ParcelDimensions } from "@/types/orders.types"
import { parseParcelDimensions, parseParcelDimensionsV2 } from "@/utils/parcel"
import { CountryCode } from "libphonenumber-js"
import { cloneDeep, isEmpty } from "lodash"
import { EU_DATE_FORMAT_UPLOAD, EU_DATE_TIME_FORMAT_UPLOAD, parseDraftUploadDate } from "@/utils/datetime"
import { getCountry } from "countries-and-timezones"
import { DateTime } from "luxon"
import { splitFullName } from "@/utils/formatFullName"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import { AddressDetails, MAX_UPLOAD_DRAFT_LIMIT } from "@/hooks/useUploadTemplate"
import { KosmoGeocodeResult } from "@/types/here.types"
import { GeocodingOpts } from "@/hooks/useGeocoding"
import { ClientWithLocations } from "@/types/clients.types"
import { writeFileXLSX, utils, WorkBook, read } from "xlsx"
import { ISheetRow } from "./upload.types"
import { DRAFT_TEMPLATE_VERSIONS } from "../DropoffForm/shared/draftTemplateVersions"
import { getColumnDescription } from "@/utils/excel"
import { UploadFile } from "antd"
import { TFunction } from "i18next"

export const deliveryExcelTypes = {
  UNDEFINED: undefined,
  EMPTY: "",
  ASAP: "ASAP",
  SCHEDULE: "Schedule",
  FOUR_HOURS: "4 Hours",
  SAME_DAY: "Same Day",
  NEXT_DAY: "Next Day",
  NEXT_THREE_DAYS: "1 - 3 Days",
}

export const hasParcelInfo = (row: ISheetRow) => {
  return (
    row?.parcelValue ||
    row?.parcelWeight ||
    row?.parcelLength ||
    row?.parcelHeight ||
    row?.parcelWidth ||
    row?.parcelQuantity ||
    row?.parcelDescription ||
    row?.barcode
  )
}

export const formatUploadedRecipientsFromTemplate = (
  addressDetails: AddressDetails,
  recipientAddress: string,
  recipientName?: string,
  recipientEmail?: string,
  buildingNumber?: string,
  recipientPhone?: string,
  dropOffNote?: string,
  orderReferenceId?: string,
  recipientCountry?: CountryCode,
  isWarning?: boolean
) => {
  const { firstName, lastName } = splitFullName(recipientName)

  const { postalCode, lat, lng } = addressDetails
  return {
    firstName: !!firstName ? `${firstName}` : "",
    lastName: !!lastName ? `${lastName}` : "",
    postalCode: `${postalCode}`,
    phone: recipientPhone ? formatPhoneNumber(recipientPhone, recipientCountry) : "",
    email: recipientEmail ? `${recipientEmail}` : "",
    location: {
      address: `${recipientAddress}`,
      postalCode: `${postalCode}`,
      latitude: Number(lat),
      longitude: Number(lng),
      buildingNumber: buildingNumber ? `${buildingNumber}` : "",
      country: recipientCountry ? `${recipientCountry}` : "",
      isWarning: isWarning,
    },
    orderReferenceId: orderReferenceId ? `${orderReferenceId}` : "",
    notes: dropOffNote ? `${dropOffNote}` : "",
  }
}
export const isEmptyCell = (cell: string | undefined) => {
  return cell === "" || cell === undefined || cell === null || cell === "NA"
}

export const getAddressDetail = async (
  geocodeByAddress: (
    queryAddress: string,
    opts: GeocodingOpts
  ) => Promise<KosmoGeocodeResult | undefined>,
  address: string,
  referenceCountry: CountryCode,
  referenceLatLng?: google.maps.LatLng,
  referencePostalCode?: string
) => {
  let postalCode, lat, lng, isWarning = false
  try {
    if (isEmptyCell(address)) {
      throw new Error("Invalid Address")
    }

    let result

    const longCountryName = getCountry(referenceCountry) ? getCountry(referenceCountry)!.name : undefined
    const targetAddress = `${address} ${longCountryName ?? ""}`
    result = await geocodeByAddress(targetAddress, {
      country: referenceCountry,
      ...(referenceLatLng ? { latLng: { lat: referenceLatLng?.lat(), lng: referenceLatLng?.lng() } } : undefined),
      postalCode: referencePostalCode,
      forceGoogle: !referenceLatLng?.lat() && !referenceLatLng?.lng()
    })
    if (!result) {
      postalCode = ""
      lat = 0
      lng = 0
      throw new Error("Invalid Address")
    }

    postalCode = result?.address?.postalCode
    lat = result?.location.lat
    lng = result?.location.lng
    isWarning = result?.isWarning
  } catch {
    postalCode = ""
    lat = 0
    lng = 0
  }
  return { postalCode, lat, lng, isWarning }
}

export const getReverseAddress = async (lat: number, lng: number) => {
  const response = await Geocode.fromLatLng(`${lat}`, `${lng}`)
  if (response?.results.length === 0) {
    return ""
  }
  const address = response.results[0].formatted_address
  return address
}


export const formatUploadedParcelDetails = (
  parcelValue?: string,
  parcelDimensions?: string,
  parcelDimensionsV2?: string,
  parcelWeight?: string,
  parcelLength?: string,
  parcelHeight?: string,
  parcelWidth?: string,
  parcelQuantity?: string,
  parcelDescription?: string,
  barcode?: string
): Parcel | undefined => {
  if (
    isEmptyCell(parcelValue) &&
    isEmptyCell(parcelDimensions) &&
    isEmptyCell(parcelDimensionsV2) &&
    isEmptyCell(parcelWeight) &&
    isEmptyCell(parcelLength) &&
    isEmptyCell(parcelHeight) &&
    isEmptyCell(parcelWidth) &&
    isEmptyCell(parcelQuantity) &&
    isEmptyCell(parcelDescription) &&
    isEmptyCell(barcode)
  ) {
    return undefined
  }

  const defaultDimensions = {
    ...(!isEmptyCell(parcelWeight) ? { weight: Math.ceil(Number(parcelWeight)) } : { weight: 0 }),
    ...(!isEmptyCell(parcelHeight) ? { height: Math.ceil(Number(parcelHeight)) } : { height: 0 }),
    ...(!isEmptyCell(parcelLength) ? { length: Math.ceil(Number(parcelLength)) } : { length: 0 }),
    ...(!isEmptyCell(parcelWidth) ? { width: Math.ceil(Number(parcelWidth)) } : { width: 0 }),
  }

  const dimensions: ParcelDimensions = {
    ...(!isEmptyCell(parcelDimensions) && parcelDimensions
      ? { ...parseParcelDimensions(parcelDimensions), ...defaultDimensions }
      : defaultDimensions),
    ...(!isEmptyCell(parcelDimensionsV2) && parcelDimensionsV2
      ? { ...parseParcelDimensionsV2(parcelDimensionsV2), ...defaultDimensions }
      : defaultDimensions),
  }

  return {
    ...(!isEmpty(dimensions) ? { dimensions: dimensions } : {}),
    ...(!isEmptyCell(parcelValue) ? { value: Number(parcelValue) } : {}),
    ...(!isEmptyCell(parcelQuantity) ? { quantity: Number(parcelQuantity) } : {}),
    ...(!isEmptyCell(parcelDescription) ? { description: parcelDescription } : {}),
    ...(!isEmptyCell(barcode) && !isEmpty(barcode) ? { barcode: { id: barcode!, createdAt: DateTime.now().toMillis() } } : {}),
  }
}

export const getSchedule = (date: string, scheduledTime: string) => {
  const parsedDate = parseDraftUploadDate(date, scheduledTime)
  let schedule

  if (date?.includes("ASAP") && scheduledTime?.includes("ASAP")) {
    return { schedule }
  }
  // ASAP + Scheduled time
  if (date?.includes("ASAP") && !scheduledTime?.includes("ASAP")) {
    const today = DateTime.now().startOf("day").toFormat(EU_DATE_FORMAT_UPLOAD)
    const dateAndTime = DateTime.fromFormat(`${today} ${scheduledTime}`, EU_DATE_TIME_FORMAT_UPLOAD)
    if (dateAndTime.isValid) {
      schedule = {
        pickup_at: dateAndTime.toMillis(),
        pickupAt: dateAndTime.toMillis(),
      }
    }
  }
  if (parsedDate && parsedDate.isValid) {
    schedule = {
      pickup_at: parsedDate.toMillis(),
      pickupAt: parsedDate.toMillis(),
    }
  }
  return { schedule }
}

type UploadedRecipient = {
  name?: string
  address?: string
  postalCode?: string,
  phone?: string
  buildingNumber?: string,
  dropOffNote?: string
  latitude?: number,
  longitude?: number
  country?: string
  email?: string
}

export const getCleanUploadedRecipient = ({ name, address, postalCode, country, phone, buildingNumber, dropOffNote, latitude, longitude, email }: UploadedRecipient, client?: ClientWithLocations) => {
  const clientHasLocation = !!client && !!client?.locations?.[0]

  const hasRecipientData =
    (client && clientHasLocation) ||
    name ||
    address ||
    buildingNumber ||
    phone ||
    dropOffNote
  if (!hasRecipientData) {
    return undefined
  }

  const parsedPostalCode =
    clientHasLocation && client?.locations?.[0]?.postalCode
      ? client?.locations?.[0]?.postalCode
      : address?.match(/\b\d{5}\b/)
  // TODO: Dirty but this concatenates address components if filled to increase geocode accuracy
  let finalAddress =
    clientHasLocation && client?.locations?.[0]?.address
      ? client?.locations?.[0]?.address
      : `${address}${postalCode ? " " + postalCode : ""}`

  if (!postalCode && parsedPostalCode?.[0]) {
    finalAddress = `${parsedPostalCode?.[0]} ${finalAddress}`
  }

  const finalLatitude =
    clientHasLocation && client?.locations?.[0]?.latitude
      ? client?.locations?.[0]?.latitude
      : latitude

  const finalLongitude =
    clientHasLocation && client?.locations?.[0]?.longitude
      ? client?.locations?.[0]?.longitude
      : longitude

  const finalCountry =
    clientHasLocation && client?.locations?.[0]?.country
      ? client?.locations?.[0]?.country
      : country

  const finalName = client ? client?.name : name

  return {
    name: finalName,
    address: finalAddress,
    country: finalCountry as CountryCode,
    buildingNumber: String(buildingNumber),
    phone: String(phone),
    latitude: finalLatitude || 0,
    longitude: finalLongitude || 0,
    postalCode: parsedPostalCode,
    dropOffNote: String(dropOffNote),
    email: String(email),
  }
}

const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/

export function checkLatLonValid(lat: number, lon: number) {
  let validLat = regexLat.test(lat?.toString() || "")
  let validLon = regexLon.test(lon?.toString() || "")
  return validLat && validLon
}

export const exportErrorsToExcel = (parsedRows: { mainRow: ISheetRow, parcels: ISheetRow[] }[], version: number, workbook: WorkBook) => {
  const currentVersionTemplate = DRAFT_TEMPLATE_VERSIONS[version] ?? DRAFT_TEMPLATE_VERSIONS[0]!
  const versionProperties = currentVersionTemplate.properties
  const previousMainSheet = workbook.Sheets[workbook.SheetNames[0] || "Kosmo Template"]

  // Convert the sheet into a JSON format 
  const sheetJson = utils.sheet_to_json(previousMainSheet!, { header: 1 });
  // Extract the first row (header) from the sheet
  const firstRow = sheetJson[0] as string[]; // This will be an array of values in the first row

  const preParsedRows = parsedRows?.map((row) => {
    const obj: Record<string, string | number> = {}
    for (const key of versionProperties) {
      obj[key] = row.mainRow[key as keyof ISheetRow] || ""
    }

    const parcels = row.parcels.map((parcel) => {
      const parcelObj: Record<string, string | number> = {}
      for (const key of versionProperties) {
        parcelObj[key] = parcel[key as keyof ISheetRow] || ""
      }
      return parcelObj
    })
    return { mainRow: { ...obj }, parcels: parcels }
  })

  const updatedRows = preParsedRows.map((row) => {
    return [Object.values(row.mainRow), ...row.parcels.map((parcel) => Object.values(parcel))]
  });

  const updatedData = [firstRow, ...updatedRows?.flat()]
  const updatedSheet = utils.aoa_to_sheet(updatedData)
  const doNotTouchSheet = utils.aoa_to_sheet([["version", version]])
  const errorWorkbook = utils.book_new()

  utils.book_append_sheet(errorWorkbook, updatedSheet, "errors")
  if (doNotTouchSheet) {
    utils.book_append_sheet(errorWorkbook, doNotTouchSheet, "DoNotTouch")
  }

  writeFileXLSX(errorWorkbook, "upload_errors.xlsx")
}

export const convertFiletoJson: ({ file, t }: { file: UploadFile<any>[], t: TFunction }) => Promise<{
  parsedRows: ISheetRow[] | undefined
  version: number
  workbook: WorkBook
}> = async ({ file, t }) => {
  try {
    if (!file.length || !file?.[0]) {
      throw new Error("No file selected")
    }

    const data = await file[0].originFileObj?.arrayBuffer()

    const workbook = read(data, {
      cellStyles: true,
    })

    // Deep copy the workbook to avoid mutating the original workbook
    const toCopyWorkbook = cloneDeep(workbook)
    // Take sheet 0 or sheet with name "Kosmo Template"
    const sheet = workbook.Sheets[workbook.SheetNames[0] || "Kosmo Template"]

    if (!sheet) {
      throw new Error(t("errors.draftUpload.noTemplateFound"))
    }
    //format sheet to json
    var range = utils.decode_range(sheet!["!ref"]!)

    range.s.r = 1 // <-- zero-indexed, so setting to 2 will skip row 0,1

    sheet!["!ref"] = utils.encode_range(range)

    // Get the version sheet
    // If the version sheet is not found, we will use the second sheet
    const sheetName = workbook.Sheets["DoNotTouch"] ? "DoNotTouch" : workbook.SheetNames?.[1]
    const versionSheet =
      sheetName && workbook.Sheets[sheetName] && !isEmpty(workbook.Sheets[sheetName])
        ? workbook.Sheets[sheetName]
        : undefined
    let version = 0

    if (versionSheet && !isEmpty(versionSheet) && versionSheet?.["B1"]?.v) {
      version = versionSheet?.["B1"]?.v
    } else {
      const versionsList = Object.keys(DRAFT_TEMPLATE_VERSIONS)
      version = Number(versionsList[versionsList?.length - 1]) ?? 0
    }

    const currentVersionTemplate = DRAFT_TEMPLATE_VERSIONS[version] ?? DRAFT_TEMPLATE_VERSIONS[0]!
    const versionProperties = currentVersionTemplate.properties

    // count number of columns with data
    // we need to check A2, B2, C2 etc. because the first row is the header
    // iterate by letters A, B, C, D etc.
    let columnCount = 0
    for (let i = 0; i < versionProperties.length; i++) {
      const letter = getColumnDescription(i)
      const cell = sheet[letter + currentVersionTemplate.headerRowIndex]
      if (cell && cell.v) {
        columnCount++
      } else {
        break
      }
    }

    if (columnCount !== versionProperties.length) {
      // To translate
      throw new Error(
        currentVersionTemplate === DRAFT_TEMPLATE_VERSIONS[0]
          ? t("errors.draftUpload.noVersionFoundInTemplate")
          : t("errors.draftUpload.templateErrorVersion", {
            version: version,
            required: versionProperties.length,
            found: columnCount,
          })
      )
    }
    utils.sheet_add_aoa(sheet!, [versionProperties], { origin: currentVersionTemplate.origin })

    const table: any[] = utils
      .sheet_to_json(sheet!, { raw: true })
      .filter(
        (item: any) =>
          item.clientId ||
          item.recipientName ||
          item.recipientAddress ||
          item.deliveryType ||
          item.date ||
          item.scheduledTime ||
          hasParcelInfo(item)
      )

    if (table.length > MAX_UPLOAD_DRAFT_LIMIT) {
      throw new Error(
        t("errors.draftUpload.limitReached", { draftLimit: MAX_UPLOAD_DRAFT_LIMIT })
      )
    }

    return { parsedRows: table, version, workbook: toCopyWorkbook }
  } catch (err: any) {
    throw err
  }
}
