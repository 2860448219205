import { getTimezone } from "@/hooks/useTimezone"
import { currencyAtom } from "@/atoms/currencyAtom"
import { localizationAtom } from "@/atoms/localizationAtom"
import { LOCALES } from "@/i18n/locales"
import { CountryKeys } from "@/types/localization.types"
import { getCountry } from "countries-and-timezones"
import { getAllInfoByISO } from "iso-country-currency"
import { Settings } from "luxon"
import moment from "moment-timezone"
import { useSetRecoilState } from "recoil"

interface IUseLocalization {
  initTimezoneAndCountry: (orgCountry?: string, auto?: boolean) => void
}

export const useLocalization = (): IUseLocalization => {
  const setLocalization = useSetRecoilState(localizationAtom)
  const setCurrency = useSetRecoilState(currencyAtom)
  const initTimezoneAndCountry = (orgCountry?: string, auto?: boolean) => {
    const country = getCountry(orgCountry || "SG")
    const countryKey = (country?.id?.toUpperCase() || "SG") as CountryKeys
    const locale = LOCALES[countryKey]

    setLocalization({
      locale: countryKey,
      defaultCoordinates: locale?.defaultCoordinates,
    })
    let currencyData
    try {
      currencyData = getAllInfoByISO(countryKey)
    } catch (e) {
      console.error(e)
      currencyData = getAllInfoByISO("US")
    }
    if (countryKey === CountryKeys.VE) {
      currencyData.currency = "USD"
      currencyData.symbol = "$"
    }
    setCurrency({
      code: currencyData.currency,
      symbol: currencyData.symbol,
    })

    const tzSetting = localStorage.getItem("userTimezone")
    let tz = getTimezone(countryKey)
    if (tzSetting === "auto" || auto) {
      tz = moment.tz.guess()
    } else if (tzSetting === "default") {
      tz = getTimezone(countryKey)
    } else if (tzSetting) {
      tz = tzSetting
    }

    Settings.defaultZone = tz
    moment.tz.setDefault(tz)
  }

  return {
    initTimezoneAndCountry,
  }
}
