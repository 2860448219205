import { ACTIVE_ORDERS_PAGE_PATH, LOGIN_PAGE_PATH, NEW_ORDERS_PAGE_PATH, ONBOARDING_PAGE_PATH } from "@/constants/routes"
import { RedirectToParams, ShouldRedirectParams } from "@/types/routes"
import { ParsedUrlQuery } from "querystring"

export const defaultShouldRedirect: (shouldRedirectParams: ShouldRedirectParams) => boolean = ({
  isAuth,
  hasOrg,
}) => !isAuth || !hasOrg

export const defaultRedirectTo: (redirectToParams: RedirectToParams) => {
  newPath: string
  queryParams?: ParsedUrlQuery
} = ({ hasOrg, isAuth, query, isSupportRole }) => {
  // TODO: Change this into guards
  if (isAuth) {
    if (!hasOrg) {
      return { newPath: ONBOARDING_PAGE_PATH, queryParams: query }
    } else if (isSupportRole) {
      return { newPath: ACTIVE_ORDERS_PAGE_PATH, queryParams: query }
    } else {
      return { newPath: NEW_ORDERS_PAGE_PATH, queryParams: query }
    }
  } else {
    return { newPath: LOGIN_PAGE_PATH, queryParams: query }
  }
}
