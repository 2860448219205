import { DestinationPriority } from "@/types/orders.types"

export const DeliveryExcelTypes = {
  UNDEFINED: undefined,
  EMPTY: "",
  ASAP: "ASAP",
  SCHEDULE: "Schedule",
  FOUR_HOURS: "4 Hours",
  SAME_DAY: "Same Day",
  NEXT_DAY: "Next Day",
  NEXT_THREE_DAYS: "1 - 3 Days",
}

export interface ISheetRow {
  index?: number
  orderNumber: string

  // productType: string
  deliveryType: string
  vehicleType: string

  date: string
  scheduledTime: string
  arrivalTimeFrom: string
  arrivalTimeTo: string

  senderName: string
  senderAddress: string
  senderBuildingNumber: string
  senderPostalCode: string | number
  senderPhone: string
  senderEmail?: string
  senderNote: string
  senderLatitude: string
  senderLongitude: string

  recipientName: string
  recipientAddress: string
  buildingNumber: string
  recipientPhone: string
  recipientEmail: string
  recipientPostalCode: string | number
  recipientCountry: string
  recipientBuildingNumber: string
  recipientLatitude: string
  recipientLongitude: string

  cashOnDeliveryAmount: string
  dropOffNote: string
  timeAtStop: string

  teamName: string
  driverName: string

  parcelValue: string
  parcelWeight: string
  parcelLength: string
  parcelHeight: string
  parcelWidth: string
  parcelQuantity: string
  parcelDescription: string
  parcelDimensions: string
  parcelDimensionsV2: string

  orderReferenceId: string
  barcode: string

  priority: string

  stopType: string

  clientId: string

  endLocation: string
  endLocationTime: string

  __rowNum__: number
}

export const priorityKeys: Record<string, DestinationPriority> = {
  "first": DestinationPriority.FIRST,
  "last": DestinationPriority.LAST,
  "automatic": DestinationPriority.DEFAULT,
  "automatica": DestinationPriority.DEFAULT,
  "primera": DestinationPriority.FIRST,
  "ultima": DestinationPriority.LAST,
}
