import { atom } from "recoil"

type AbortRoutes = "getDraftOrders"

export type AbortControllerType = Record<AbortRoutes, AbortController | null>

export const abortControllerAtom = atom({
  key: "abortController",
  default: {
    getDraftOrders: null,
  } as AbortControllerType,
})
