export type StorageTokens = {
  accessToken: string
}

export const getActiveTokens = (): StorageTokens => {
  const tokens = JSON.parse(localStorage?.getItem("tokens") || "{}")

  return {
    accessToken: tokens?.accessToken || "",
  }
}

export const setTokens = (tokens: StorageTokens) => {
  localStorage?.setItem("tokens", JSON.stringify(tokens))
}

export const logoutJwtUser = () => {
  localStorage?.removeItem("tokens")
  // deleteActiveOrg()
}

export const setActiveOrg = (orgId: string) => {
  if (!orgId) {
    return
  }
  localStorage?.setItem("activeOrg", orgId)
}

export const deleteActiveOrg = () => {
  localStorage?.removeItem("activeOrg")
}

export const getActiveOrg = () => {
  const orgId = localStorage?.getItem("activeOrg") || ""
  if (orgId === "undefined") {
    deleteActiveOrg()
    return ""
  }
  return orgId
}
