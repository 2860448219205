export const objectsEqual = (o1: any, o2: any): boolean => {

  if (o1 === null && o2 === null) return true
  if (o1 === null || o2 === null) return false
  if (typeof o1 === "object" && typeof o2 === "object" && Object.keys(o1).length === 0 && Object.keys(o2).length === 0) return true

  return typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2
}

export const arraysEqual = (a1: any[], a2: any[]) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))

export const matchField = (field?: string, value?: string) =>
  field && value && field?.toLowerCase().includes(value?.toLowerCase())