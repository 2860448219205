import { Driver } from "@/types/drivers.types"

export function sortDrivers(drivers: Driver[]): Driver[] {
  return drivers.sort((a, b) => {
    // Sort by status first
    if (a.status === "online" && b.status !== "online") {
      return -1
    }
    if (b.status === "online" && a.status !== "online") {
      return 1
    }

    if (a.status === "idle" && b.status !== "idle") {
      return -1
    }
    if (b.status === "idle" && a.status !== "idle") {
      return 1
    }

    // If status is the same, sort by name
    return a.name.localeCompare(b.name)
  })
}