export const LOGIN_PAGE_PATH = "/login"
export const SIGNUP_PAGE_PATH = "/signup"
export const CUSTOMER_PORTAL_PAGE_PATH = "/customer-portal/[hash]"
export const PROFILE_PAGE_PATH = "/profile"
export const INVITATION_PAGE_PATH = "/invitation"
export const NEW_ORDERS_PAGE_PATH = "/new-orders"
export const ANALYTICS_PAGE_PATH = "/analytics"
export const ACTIVE_ORDERS_PAGE_PATH = "/active-orders"
export const MAP_PAGE_PATH = "/map"
export const ONBOARDING_PAGE_PATH = "/onboarding"
export const ROOT_PAGE_PATH = "/"
export const DEVELOPERS_PAGE_PATH = "/developers"
export const ECOMMERCE_VALIDATE_PATH = "/ecommerce/validate"
export const AFFILIATE_PAGE_PATH = "/affiliate"
export const SCAN_PAGE_PATH = "/scan"

export const DEVELOPERS_DOC_URL = "https://kosmo.readme.io/reference/getting-started-1"
export const KOSMO_STATUS_URL = "https://status.kosmo.delivery"

export const TAB_EXTERNAL_LINKS = [DEVELOPERS_DOC_URL, KOSMO_STATUS_URL]

export const KOSMO_SHOPIFY_APP_LINK = "https://apps.shopify.com/kosmo-delivery"
