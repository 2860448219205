// Enable provider
export enum ProvidersType {
  GRAB = "GRAB",
  PANDAGO = "PANDAGO",
  LALAMOVE = "LALAMOVE",
  ZEEK = "ZEEK",
  PICKUPP = "PICKUPP",
  QXPRESS = "QXPRESS",
  IFOOD = "IFOOD",
  TELEPORT = "TELEPORT",
  GOGOX = "GOGOX",
  BORZO = "BORZO",
  NINJAVAN = "NINJAVAN",
  JNT = "JNT",
  RAPPI = "RAPPI",
  MILKRUN = "MILKRUN",
  KOSMO = "KOSMO",
  UBER = "UBER",
  TADA = "TADA",
  OWN_FLEET = "OWN_FLEET",
  KOSMO_NETWORK = "KOSMO_NETWORK",
}

// Enable provider
export const getProviderName = (provider: ProvidersType) => {
  switch (provider) {
    case ProvidersType.GRAB:
      return "Grab"
    case ProvidersType.PANDAGO:
      return "pandago"
    case ProvidersType.LALAMOVE:
      return "Lalamove"
    case ProvidersType.ZEEK:
      return "Zeek"
    case ProvidersType.PICKUPP:
      return "Pickupp"
    case ProvidersType.QXPRESS:
      return "Qxpress"
    case ProvidersType.UBER:
      return "Uber"
    case ProvidersType.IFOOD:
      return "iFood"
    case ProvidersType.TELEPORT:
      return "Teleport"
    case ProvidersType.GOGOX:
      return "GOGOX"
    case ProvidersType.BORZO:
      return "Borzo"
    case ProvidersType.NINJAVAN:
      return "Ninja Van"
    case ProvidersType.JNT:
      return "J&T"
    case ProvidersType.RAPPI:
      return "Rappi"
    case ProvidersType.MILKRUN:
      return "Milkrun"
    case ProvidersType.TADA:
      return "Tada"
    case ProvidersType.KOSMO:
      return "Kosmo Test"
    case ProvidersType.OWN_FLEET:
      return "My Fleet"
    case ProvidersType.KOSMO_NETWORK:
      return "Kosmo Network"
    default:
      return ""
  }
}
