import axios from "@/modules/shared/AxiosInterceptor"
import { Zone } from "@/types/zones.types"
import { RcFile } from "antd/lib/upload"
import { AxiosResponse } from "axios"

export async function getZones() {
  return axios
    .get<{ zones: Zone[] }>("/zones")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function importZones(file: RcFile): Promise<AxiosResponse<{ zones: Zone[] }, any>> {
  const formData = new FormData()
  const fileName = file.name.endsWith(".kml") ? "file.kml" : `file.kmz`
  formData.append(fileName, file)
  return axios
    .post<{ zones: Zone[] }>("/zones/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function deleteZone(id: string) {
  return axios
    .delete(`/zones/${id}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
