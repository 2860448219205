import React, { useRef } from "react"
import { ANTD_PICKER_DATE_FORMAT, DATE_FILTER_BY, LUXON_PICKER_DATE_FORMAT } from "@/utils/datetime"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import LuxonDatePicker from "@/modules/shared/LuxonDatePicker"
import { Button, Dropdown, MenuProps } from "antd"

type DateFilterProps = {
  date?: string // formatted as LUXON_PICKER_DATE_FORMAT
  setDate: (formattedDate: string) => void
  setDateFilterBy?: (dateFilterBy: DATE_FILTER_BY) => void
  dateFilterBy?: DATE_FILTER_BY
}

export default function DateFilter({
  date,
  setDate,
  setDateFilterBy,
  dateFilterBy,
}: DateFilterProps) {
  const datePickerRef = useRef<any>(null)
  const { t } = useTranslation()
  const selectedDate = DateTime.fromFormat(date || "", LUXON_PICKER_DATE_FORMAT)

  const onSelectDate = (_: DateTime | null, dateString: string | string[]) => {
    if (!date) return

    if (datePickerRef.current) {
      datePickerRef.current.blur()
    }

    setDate(typeof dateString === "string" ? dateString : dateString?.[0]!)
  }

  const dateFilterByLabels = {
    [DATE_FILTER_BY.CREATION]: "common.creation",
    [DATE_FILTER_BY.PICKUP_TIME]: "common.pickupTime",
    [DATE_FILTER_BY.COMPLETION]: "common.completion",
  }

  const dateRangeDropdownItems: MenuProps["items"] = [
    {
      key: DATE_FILTER_BY.CREATION as string,
      label: t("common.creation"),
      onClick: () => {
        setDateFilterBy?.(DATE_FILTER_BY.CREATION)
      },
    },

    {
      key: DATE_FILTER_BY.PICKUP_TIME as string,
      label: t("common.pickupTime"),
      onClick: () => {
        setDateFilterBy?.(DATE_FILTER_BY.PICKUP_TIME)
      },
    },
  ]

  return (
    <Button.Group>
      <LuxonDatePicker
        ref={datePickerRef}
        placeholder={t("common.date")}
        allowClear={false}
        format={ANTD_PICKER_DATE_FORMAT}
        value={selectedDate}
        onChange={onSelectDate}
        style={dateFilterBy ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
      />
      {dateFilterBy && setDateFilterBy && (
        <Dropdown
          menu={{
            items: dateRangeDropdownItems,
            selectable: true,
            selectedKeys: [dateFilterBy ?? (DATE_FILTER_BY.CREATION as string)],
          }}
        >
          <Button>{t(dateFilterByLabels[dateFilterBy])}</Button>
        </Dropdown>
      )}
    </Button.Group>
  )
}
