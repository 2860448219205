import { useState } from "react"
import { isEmpty } from "lodash"
import { useRecoilState, useRecoilValue } from "recoil"

import { deleteClient, getClientPay, getClients, postClient, putClient, putClientPay, deleteClientPayApi } from "@/api/clients"

import { clientPayAtom, clientsAtom, enrichedClientsSelector } from "@/atoms/clientsAtom"
import { Client, ClientWithLocations, CreateClientPayload } from "@/types/clients.types"
import { PriceConfiguration } from "@/types/drivesAppSettings"


interface IUseClients {
  clients: Client[]
  enrichedClients: ClientWithLocations[]
  clientsIds: string[]
  clientPay: PriceConfiguration[]
  isLoading: boolean
  fetchClients: (forceFetch?: boolean) => Promise<void>
  fetchClientPay: () => Promise<void>
  updateClientPay: (updatedConfig: PriceConfiguration) => Promise<void>
  deleteClientPay: (configId: string) => Promise<void>
  updateClient: (client: Client) => Promise<void>
  createClient: (client: CreateClientPayload) => Promise<void>
  removeClient: (id: string) => Promise<void>
}

export const useClients = (): IUseClients => {
  const [clients, setClients] = useRecoilState(clientsAtom)
  const enrichedClients = useRecoilValue(enrichedClientsSelector)
  const [clientPay, setClientPay] = useRecoilState(clientPayAtom)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchClients = async (forceFetch?: boolean) => {
    setIsLoading(true)
    try {
      if (isEmpty(clients) || forceFetch) {
        const { data } = await getClients()
        setClients(data)
      }
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const fetchClientPay = async () => {
    setIsLoading(true)
    try {
      const { data } = await getClientPay()
      setClientPay(data.configs)
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const updateClientPay = async (updatedConfig: PriceConfiguration) => {
    setIsLoading(true)
    try {
      const { data } = await putClientPay(updatedConfig)
      setClientPay(data.configs)
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const deleteClientPay = async (configId: string) => {
    const config = clientPay.find((config) => config.id === configId)
    if (!config) return
    setIsLoading(true)
    try {
      setClientPay((clientPay) => clientPay.filter((config) => config.id !== configId))
      await deleteClientPayApi(configId)
    } catch (error) {
      setClientPay((clientPay) => [...clientPay, config])
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const updateClient = async (client: Client) => {
    setIsLoading(true)
    try {
      const { data } = await putClient(client)
      setClients((clients) => clients.map((t) => (t.id === data.id ? data : t)))
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const createClient = async (client: CreateClientPayload) => {
    setIsLoading(true)
    try {
      const { data } = await postClient(client)
      setClients((clients) => [...clients, data])
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const removeClient = async (id: string) => {
    setIsLoading(true)
    try {
      await deleteClient(id)
      setClients((clients) => clients.filter((client) => client.id !== id))
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const clientsIds = clients?.map((client) => client?.id)

  return {
    clients,
    enrichedClients,
    clientPay,
    isLoading,
    fetchClients,
    fetchClientPay,
    updateClientPay,
    deleteClientPay,
    updateClient,
    createClient,
    removeClient,
    clientsIds,
  }
}
