import React from "react"
import { Input } from "antd"
import { SizeType } from "antd/lib/config-provider/SizeContext"

interface IFloatTextAreaProps {
  label: string
  value?: string
  placeholder: string
  required?: boolean
  size: SizeType
  name?: string
  handleChange?: (target: string, e: any) => void
  onChange?: (values: any) => void
}

//Custom TextArea on pickup/dropoff form
export const FloatTextArea: React.FC<IFloatTextAreaProps> = (props) => {
  let { label, placeholder, required, value, size, handleChange, onChange } = props

  if (!placeholder) placeholder = label

  const isOccupied = value !== undefined && value?.length !== 0

  const labelClass = isOccupied
    ? `${props.size} label as-label-textArea`
    : `${props.size} label as-placeholder`

  const requiredMark = required ? <span className="text-danger">*</span> : null

  return (
    <div className="float-label">
      <Input.TextArea
        value={!!value ? value : undefined}
        onChange={(e) => {
          if (handleChange) {
            handleChange("notes", e)
          }
          if (onChange) {
            onChange(e)
          }
        }}
        size={size}
        placeholder={placeholder}
        style={{
          resize: "none",
          height: size === "large" ? "80px" : "55px",
          fontSize: size === "large" ? "16px" : "14px",
        }}
      />
      <label style={{ zIndex: "1" }} className={labelClass}>
        {isOccupied ? label : ""} {requiredMark}
      </label>
    </div>
  )
}
