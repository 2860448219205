import { atom } from "recoil"
import { ISheetRow } from "@/modules/shared/UploadTemplate/upload.types";
import { WorkBook } from "xlsx";
import { UPLOAD_ERRORS } from "@/hooks/useUploadTemplate";

export type UploadError = {
  index: number; reason: UPLOAD_ERRORS | undefined; excelRow: ISheetRow, parcels: ISheetRow[]
}

export type UploadSummary = {
  totalSuccess: number
  errors: UploadError[]
  version: number
  workbook: WorkBook
}

export const uploadSummaryAtom = atom<UploadSummary | null>({
  key: "uploadSummaryAtom",
  default: null,
})
