import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
const firebaseConfig = {
  apiKey: "AIzaSyDybFlCCG_Gxv_meGkvd0X6jSUIUWR0tKc",
  projectId: "kosmo-delivery-prd",
  storageBucket: "kosmo-delivery-prd.appspot.com",
  messagingSenderId: "456340085735",
  appId: "1:456340085735:web:3bafb2580b199b260cfb42",
}

const app = initializeApp(firebaseConfig, "kosmo-frontend")

// try to add analytics
const analytics = app.name && typeof window !== "undefined" ? getAnalytics(app) : null

export { app, analytics }
