import React, { useState } from "react"
import { Select } from "antd"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"
import { useSetRecoilState } from "recoil"
import { userAtom } from "@/atoms/userAtom"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import {
  ASIA_DATE_FORMAT,
  DATE_FORMAT_REGIONS,
  EU_DATE_FORMAT,
  US_DATE_FORMAT,
} from "@/utils/datetime"

interface DateFormatSelectProps {
  withLabel?: boolean
  size?: SizeType
}

export const LOCAL_STORAGE_DATE_KEY = "userDateFormat"

const DateFormatSelect: React.FC<DateFormatSelectProps> = ({
  withLabel = false,
  size = undefined,
}) => {
  const { t } = useTranslation()
  const savedDateFormat = localStorage?.getItem(LOCAL_STORAGE_DATE_KEY) || DATE_FORMAT_REGIONS.EU
  const [selectedDateFormat, setSelectedDateFormat] = useState(savedDateFormat)
  const setUser = useSetRecoilState(userAtom)

  const handleDateFormatSelection = (value: string) => {
    localStorage.setItem(LOCAL_STORAGE_DATE_KEY, value)
    setSelectedDateFormat(value)
    setUser((user) => ({ ...user, preferredDateFormat: value as DATE_FORMAT_REGIONS }))
  }

  const options = [
    {
      label: `Europe (${DateTime.now().toFormat(EU_DATE_FORMAT)})`,
      value: DATE_FORMAT_REGIONS.EU,
    },
    {
      label: `US (${DateTime.now().toFormat(US_DATE_FORMAT)})`,
      value: DATE_FORMAT_REGIONS.US,
    },
    {
      label: `Asia (${DateTime.now().toFormat(ASIA_DATE_FORMAT)})`,
      value: DATE_FORMAT_REGIONS.ASIA,
    },
  ]

  return (
    <div className="flex flex-col gap-y-1">
      {withLabel && (
        <span>
          <label htmlFor="timezone-select">{t("profile.GeneralSettings.dateFormat")}</label>
        </span>
      )}
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder={t("profile.GeneralSettings.dateFormatPlaceholder")}
        optionFilterProp="children"
        value={selectedDateFormat}
        options={options}
        labelInValue
        size={size}
        // @ts-ignore
        onChange={({ value }) => {
          handleDateFormatSelection(value)
        }}
      />
    </div>
  )
}

export default DateFormatSelect
