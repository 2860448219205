export interface Role {
  title: string
  value: string
}

export enum IRoles {
  ADMIN = "admin",
  OWNER = "owner",
  MANAGER = "manager",
  SUPPORT = "support",
}

export const allRolesItemKey = {
  title: "common.roles.allRoles",
  value: "all",
}

export const inviteRolesKeys = [
  {
    title: "common.roles.admin",
    value: "admin",
  },
  {
    title: "common.roles.fleetManager",
    value: "manager",
  },
  {
    title: "common.roles.support",
    value: "support",
  }
]

export const rolesKeys = [
  {
    title: "common.roles.owner",
    value: IRoles.OWNER,
  },
  {
    title: "common.roles.admin",
    value: IRoles.ADMIN,
  },
  {
    title: "common.roles.fleetManager",
    value: IRoles.MANAGER,
  },
  {
    title: "common.roles.support",
    value: IRoles.SUPPORT,
  },
]
