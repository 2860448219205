import React, { useEffect, useRef, useState } from "react"
import { Select } from "antd"
import useMediaQuery from "@/utils/useMediaQuery"

interface IPhonecodeDropdownProps {
  countryCode: string
  handlePhoneSelect: (countryCode: string) => void
  disabled?: boolean
}
// TODO Fix types select
export const PhonecodeDropdown: React.FC<IPhonecodeDropdownProps> = (props) => {
  const [dropdownCountryCode, setDropdownCountryCode] = useState(props.countryCode)
  const { countries } = require("@/constants/countryCodes.json")
  const { Option, OptGroup } = Select
  let isDesktop = useMediaQuery("(min-width: 1024px)")

  const dropdownRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setDropdownCountryCode(props.countryCode)
  }, [props.countryCode])
  const timerRef = useRef<any>(null)

  return (
    <Select
      id="phonecode-dropdown"
      onChange={(e) => {
        props.handlePhoneSelect(e)
        dropdownRef?.current?.blur()
      }}
      variant="borderless"
      className="phone-select"
      defaultValue={props.countryCode}
      value={dropdownCountryCode}
      optionLabelProp="img"
      showSearch
      ref={dropdownRef}
      onClick={(e) => {
        e.stopPropagation()
        setIsOpen(!isOpen)
      }}
      onKeyDown={(e: any) => {
        if (e.keyCode === 27) {
          setIsOpen(false)
        }
      }}
      onMouseEnter={() => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }
      }}
      onMouseLeave={() => {
        timerRef.current = setTimeout(() => setIsOpen(false), 2000)
      }}
      disabled={props.disabled}
      open={isOpen}
      style={{
        ...(isDesktop
          ? {
              minWidth: "70px",
              maxWidth: "100px",
              height: "24px",
            }
          : {
              height: "24px",
            }),
      }}
      dropdownStyle={{
        ...(isDesktop
          ? {
              zIndex: 9999,
              minWidth: "20%",
              borderRadius: "8px",
            }
          : { zIndex: 9999, borderRadius: "8px", minWidth: "300px" }),
      }}
      filterOption={(input, option) => {
        // TODO REVIEW THIS: all this block is weird
        if (typeof option?.options != "undefined") {
          return false
        } else {
          if ((option as any).label.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
            return true
          } else {
            return false
          }
        }
      }}
    >
      <Option
        value="SG"
        label="Singapore"
        img={
          <img
            className="w-full h-3 p-0 m-0 mt-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/sg.svg"
          />
        }
      >
        <div className="flex flex-row my-auto ml-3">
          <img
            className="w-5 mr-1 mb-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/sg.svg"
          />
          Singapore
        </div>
      </Option>
      <Option
        value="MY"
        label="Malaysia"
        img={
          <img
            className="w-full h-3 p-0 m-0 mt-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/my.svg"
          />
        }
      >
        <div className="flex flex-row my-auto ml-3">
          <img
            className="w-5 mr-1 mb-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/my.svg"
          />
          Malaysia
        </div>
      </Option>
      <Option
        value="ID"
        label="Indonesia"
        img={
          <img
            className="w-full h-3 p-0 m-0 mt-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/id.svg"
          />
        }
      >
        <div className="flex flex-row my-auto ml-3">
          <img
            className="w-5 mr-1 mb-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/id.svg"
          />
          Indonesia
        </div>
      </Option>
      <Option
        value="BR"
        label="Brazil"
        img={
          <img
            className="w-full h-3 p-0 m-0 mt-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/br.svg"
          />
        }
      >
        <div className="flex flex-row my-auto ml-3">
          <img
            className="w-5 mr-1 mb-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/br.svg"
          />
          Brazil
        </div>
      </Option>
      <Option
        value="TH"
        label="Thailand"
        img={
          <img
            className="w-full h-3 p-0 m-0 mt-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/th.svg"
          />
        }
      >
        <div className="flex flex-row my-auto ml-3">
          <img
            className="w-5 mr-1 mb-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/th.svg"
          />
          Thailand
        </div>
      </Option>
      <Option
        value="PH"
        label="Philippines"
        img={
          <img
            className="w-full h-3 p-0 m-0 mt-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/ph.svg"
          />
        }
      >
        <div className="flex flex-row my-auto ml-3">
          <img
            className="w-5 mr-1 mb-1"
            src="https://cdn.kcak11.com/CountryFlags/countries/ph.svg"
          />
          Philippines
        </div>
      </Option>
      <OptGroup label="All Countries">
        {countries.map(
          (country: { countryCode: string; name: string; flag: string }, index: number) => (
            <Option
              key={index}
              value={country.countryCode}
              label={country.name}
              img={<img className="w-full h-3 p-0 m-0" src={country.flag} />}
            >
              <div className="flex flex-row my-auto">
                <img className="w-5 mr-1 mb-1" src={country.flag} />
                {country.name}
              </div>
            </Option>
          )
        )}
      </OptGroup>
    </Select>
  )
}
