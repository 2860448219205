import { userAtom } from "@/atoms/userAtom"
import { PickupDetailsType } from "@/types/createSingleOrder.types"
import { useRecoilState } from "recoil"

interface IUseStore {
  switchSelectedStore: (store?: PickupDetailsType | undefined) => void
  setCreatedStore: (store: PickupDetailsType) => void
}

const useStore = (): IUseStore => {
  const [_, setUser] = useRecoilState(userAtom)
  // TODO To remove all this
  const switchSelectedStore = (store?: PickupDetailsType | undefined) => {
    if (store?.id) {
      window.localStorage?.setItem("previousSelectedStoreId", store.id)
    }
  }

  const setCreatedStore = (store: PickupDetailsType) => {
    setUser((user) => ({
      ...user,
      stores: [...(user?.stores || []), store],
    }))
    switchSelectedStore(store)
  }

  return {
    setCreatedStore,
    switchSelectedStore,
  }
}

export { useStore }
