import { DateTime } from "luxon"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { Arrival, Location, StopType, defaultPickupForm } from "@/types/orders.types"
import { DropoffDetails, PickupDetailsType } from "@/types/createSingleOrder.types"
import { v4 as uuidv4 } from "uuid"
import { ShortenedArrival, ShortenedLocation, ShortenedSender } from "@/types/map"

export const formatMultipleDropoffs = (
  recipients: Location[],
  deliveryType?: ServerDeliveryType,
  omitId?: boolean
): DropoffDetails[] => {
  return recipients.map((dropOff) => formatSingleDropoff(dropOff, deliveryType, omitId))
}

export const formatSingleDropoff = (
  recipient: Location | undefined,
  deliveryType?: ServerDeliveryType,
  omitId?: boolean
): DropoffDetails => {
  const dropoff = {
    ...(omitId ? {} : { id: recipient?.id || uuidv4() }),
    address: recipient?.location?.address || "",
    buildingNumber: recipient?.location?.buildingNumber,
    name: recipient?.firstName + " " + recipient?.lastName,
    phone: recipient?.phone || "",
    email: recipient?.email || "",
    postalCode: recipient?.location?.postalCode || "",
    latitude: recipient?.location?.latitude || 0,
    longitude: recipient?.location?.longitude || 0,
    notes: recipient?.notes,
    orderReferenceId: recipient?.orderReferenceId,
    productType: recipient?.productType,
    parcels: recipient?.parcels,
    trackingUrl: recipient?.trackingUrl,
    deliveryType: deliveryType,
    cashOnDeliveryAmount: recipient?.cashOnDeliveryAmount,
    clientPricingDeliveryFee: recipient?.clientPricingDeliveryFee,
    clientPricingTips: recipient?.clientPricingTips,
    plannedArrivalAt: recipient?.dropoffEta ?? recipient?.plannedArrivalAt,
    finishedAt: recipient?.finishedAt,
    optimizationFailureReasons: recipient?.optimizationFailureReasons,
    expectedArrival: {
      from: recipient?.expectedArrival?.from
        ? DateTime.fromMillis(recipient?.expectedArrival.from)
        : undefined,
      to: recipient?.expectedArrival?.to
        ? DateTime.fromMillis(recipient?.expectedArrival.to)
        : undefined,
    },
    expectedTimeAtStop: recipient?.expectedTimeAtStop,
    metadata: recipient?.metadata,
    status: recipient?.status,
    type: recipient?.type || StopType.DROPOFF,
    priority: recipient?.priority,
    isWarning: recipient?.location?.isWarning,
    driverEdited: recipient?.location?.driverEdited,
  } as DropoffDetails

  return dropoff
}

export const formatToPickup = (
  pickup: Location | undefined,
  deliveryType?: ServerDeliveryType
): PickupDetailsType => {
  if (!pickup) {
    return defaultPickupForm
  }

  return {
    id: pickup.location?.id,
    address: pickup.location?.address || "",
    buildingNumber: pickup.location?.buildingNumber,
    name: pickup.firstName + " " + pickup.lastName,
    phone: pickup.phone || "",
    postalCode: pickup.location?.postalCode || "",
    latitude: pickup.location?.latitude || 0,
    longitude: pickup.location?.longitude || 0,
    notes: pickup.notes,
    orderReferenceId: pickup.orderReferenceId,
    productType: pickup.productType!,
    parcels: pickup.parcels,
    deliveryType: deliveryType,
    alias: pickup.location?.alias,
    type: pickup.type || StopType.PICKUP,
    priority: pickup.priority,
  }
}

export const isDropoffComplete = (dropoff: DropoffDetails): boolean => {
  if (dropoff.type === StopType.BREAK) {
    return !!dropoff.expectedTimeAtStop
  }
  return (
    !!dropoff && !!dropoff.address && !!dropoff.name && !!dropoff.latitude && !!dropoff.longitude
  )
}

export const formatShortLocationToLocation = (location: ShortenedLocation): Location => {
  return {
    id: location?.id,
    firstName: location?.fn,
    lastName: location?.ln,
    phone: location?.p,
    ...(location?.l
      ? {
          location: {
            latitude: location?.l?.lat || 0,
            longitude: location.l?.lon || 0,
            id: location?.l?.id || "",
            address: location?.l?.a || "",
            alias: location?.l?.aa || "",
            country: location?.l?.cn || "",
            city: location?.l?.ct,
            postalCode: location?.l?.pc,
            buildingNumber: location?.l?.bn,
          },
        }
      : {}),
    notes: location?.notes,
    orderReferenceId: location?.o,
    email: location?.e,
    status: location?.s,
    dropoffEta: location?.de,
    parcels: location?.pas,
    cashOnDeliveryAmount: location?.co,
    // clientPricingTotal: location?.cpt, // TODO: check if this is needed
    expectedArrival: location?.ea,
    expectedTimeAtStop: location?.et,
    plannedArrivalAt: location?.pla,
    // plannedDepartureAt: number
    // optimizationFailureReasons: string[]
    metadata: location?.m,
    trackingUrl: location?.tu,
    // zoneIds: string[]
    finishedAt: location?.fa,
    type: location?.t || StopType.DROPOFF,
    priority: location?.opt,
  }
}

export const formatShortenedSenderToLocation = (sender: ShortenedSender): Location => {
  return {
    email: sender?.e,
    firstName: sender?.fn,
    lastName: sender?.ln,
    id: sender?.id,
    type: StopType.PICKUP,
    phone: sender?.p,
    notes: sender?.n,
    ...(sender?.l
      ? {
          location: {
            latitude: sender?.l?.lat || 0,
            longitude: sender.l?.lon || 0,
            id: sender?.l?.id || "",
            address: sender?.l?.a || "",
            alias: sender?.l?.aa || "",
            country: sender?.l?.cn || "",
            city: sender?.l?.ct,
            postalCode: sender?.l?.pc,
            buildingNumber: sender?.l?.bn,
          },
        }
      : {}),
  }
}

export const formatShortenedArrivalToArrival = (arr?: ShortenedArrival): Arrival | undefined => {
  if (!arr) return undefined
  return {
    contact: {
      name: arr.c.n,
      phone: arr.c.p,
      email: arr.c.e,
      notes: arr.c.n,
    },
    location: {
      latitude: arr?.l?.lat || 0,
      longitude: arr.l?.lon || 0,
      id: arr?.l?.id || "",
      address: arr?.l?.a || "",
      alias: arr?.l?.aa || "",
      country: arr?.l?.cn || "",
      city: arr?.l?.ct,
      postalCode: arr?.l?.pc,
      buildingNumber: arr?.l?.bn,
    },
    plannedArrivalAt: arr.pla,
    scheduleAt: arr.sca,
    finishedAt: 0,
  }
}
