import { DateTime } from "luxon"

export enum DATE_FORMAT_REGIONS {
  US = "us",
  EU = "eu",
  ASIA = "asia"
}


export const US_DATE_FORMAT = "MM-dd-yyyy"
export const ASIA_DATE_FORMAT = "yyyy-MM-dd"
export const EU_DATE_FORMAT = "dd-MM-yyyy"
export const TIME_FORMAT = "HH:mm"

export const ASIA_DATE_FORMAT_UPLOAD = "yyyy/MM/dd"
export const ASIA_DATE_TIME_FORMAT_UPLOAD = `${ASIA_DATE_FORMAT_UPLOAD} ${TIME_FORMAT}`
export const ASIA_DATE_TIME_FORMAT = `${ASIA_DATE_FORMAT} ${TIME_FORMAT}`

export const EU_DATE_FORMAT_UPLOAD = "dd/MM/yy"
export const EU_DATE_TIME_FORMAT_UPLOAD = `${EU_DATE_FORMAT_UPLOAD} ${TIME_FORMAT}`
export const EU_DATE_TIME_FORMAT = `${EU_DATE_FORMAT} ${TIME_FORMAT}`

export const US_DATE_TIME_FORMAT = `${US_DATE_FORMAT} ${TIME_FORMAT}`

export const SHORT_DATE_FORMAT = "dd LLL"
export const SHORT_DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} ${TIME_FORMAT}`

export const defaultScheduleTime = "12:00"

export const FULL_DATE_FORMAT = "dd/MM/yyyy HH:mm"
export const MONTH_NAME_TIME_FORMAT = "dd MMM - HH:mm"
export const MONTH_NAME_FORMAT = "dd MMM"
export const LONG_DATE_ONLY_FORMAT = "dd/MM/yyyy"
export const DATE_DASH_SEPARATOR = "-"

export const RANGE_PICKER_DATE_FORMAT = "DD-MM-yyyy"
export const RANGE_PICKER_DAY_MONTH_FORMAT = "DD-MM"
export const RANGE_PICKER_DATETIME_FORMAT = "DD-MM-yyyy HH:mm"
export const RANGE_PICKER_DAY_MONTH_TIME_FORMAT = "DD-MM HH:mm"

export const LUXON_PICKER_DATE_FORMAT = "dd-MM-yyyy"
export const LUXON_PICKER_DATETIME_FORMAT = "dd-MM-yyyy HH:mm"
export const LUXON_DAY_MONTH_FORMAT = "dd/MM"

export const ANTD_PICKER_DATE_FORMAT = "DD-MM-YYYY"

export const REGION_LUXON_PICKER_DATE_FORMAT = {
  [DATE_FORMAT_REGIONS.EU]: EU_DATE_FORMAT,
  [DATE_FORMAT_REGIONS.US]: US_DATE_FORMAT as LuxonToMillisFormats,
  [DATE_FORMAT_REGIONS.ASIA]: ASIA_DATE_FORMAT as LuxonToMillisFormats,
}

export const REGION_LUXON_PICKER_DATETIME_FORMAT = {
  [DATE_FORMAT_REGIONS.EU]: EU_DATE_TIME_FORMAT,
  [DATE_FORMAT_REGIONS.US]: US_DATE_TIME_FORMAT as LuxonToMillisFormats,
  [DATE_FORMAT_REGIONS.ASIA]: ASIA_DATE_TIME_FORMAT as LuxonToMillisFormats,
}


export type LuxonToMillisFormats = "yyyy/-MM-dd" | "yyyy-MM-dd HH:mm" | "MM-dd-yyyy" | "MM/dd/yyyy HH:mm" | "dd-MM-yyyy" | "dd-MM-yyyy HH:mm"

interface ITimeSlot {
  startHour: number
  intervalString: string
}

export type DateFilterType = {
  range: [string, string] | [],
  format: LuxonToMillisFormats,
}

export enum DATE_FILTER_BY {
  CREATION = "created_at",
  PICKUP_TIME = "picked_up_at",
  COMPLETION = "completed_at"
}

export const getDateTimeMillis = (date: string, format: LuxonToMillisFormats, end?: boolean): number => {
  let dateTime = DateTime.fromFormat(date || "", format)
  if (!dateTime.isValid) {
    return DateTime.now().toMillis()
  }

  if (Object.values(REGION_LUXON_PICKER_DATETIME_FORMAT).includes(format)) {
    return dateTime.toUTC().toMillis()
  } else {
    return end ? dateTime.endOf("day").toMillis() : dateTime.startOf("day").toMillis()
  }
}

export const parseDraftUploadDate = (date: string | number, scheduledTime: string) => {
  if (!date) {
    return null
  }
  let parsedDate = date
  // Handling excel serial number format
  if (typeof date === "number") {
    parsedDate = DateTime.fromFormat("01/01/1900", "dd/M/yy").plus({ day: date - 2 }).toFormat(EU_DATE_FORMAT_UPLOAD)
  }

  const dateAndTime = `${parsedDate} ${scheduledTime || defaultScheduleTime}`
  if (DateTime.fromFormat(dateAndTime, EU_DATE_TIME_FORMAT_UPLOAD).isValid) {
    return DateTime.fromFormat(dateAndTime, EU_DATE_TIME_FORMAT_UPLOAD)
  }

  if (DateTime.fromFormat(dateAndTime, ASIA_DATE_TIME_FORMAT_UPLOAD).isValid) {
    return DateTime.fromFormat(dateAndTime, ASIA_DATE_TIME_FORMAT_UPLOAD)
  }

  return null
}

export const isCurrentDay = (timestamp: number) => {
  return DateTime.fromMillis(timestamp).hasSame(DateTime.now(), "day")
}

export const convertTimestamp = (timestamp: number) => {
  const myDateTime = DateTime.fromMillis(timestamp)
  const myDateTimeISO = myDateTime.toLocaleString({
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
  return myDateTimeISO
}

/**
 *
 *
 * @param {string} start - start time in format HH:mm
 * @param {string} end - end time in format HH:mm
 * @param {number} [interval=30] in minutes
 * @return {*} {ITimeSlot[]} - array of time slots
 */
export const getTimeSlots = (start: string, end: string, interval: number = 30): ITimeSlot[] => {
  let startTime = DateTime.fromFormat(start, "HH:mm")
  let endTime = DateTime.fromFormat(end, "HH:mm")

  if (endTime.toMillis() < startTime.toMillis()) {
    endTime = endTime.plus({ days: 1 })
  }

  const timeStops: ITimeSlot[] = []

  while (startTime.toMillis() <= endTime.toMillis()) {
    timeStops.push({
      startHour: startTime.hour,
      intervalString: `${startTime.toFormat("HH:mm")} - ${startTime
        .plus({ minutes: interval })
        .toFormat("HH:mm")}`,
    })
    startTime = startTime.plus({ minutes: interval })
  }
  return timeStops
}

export const disabledDate = (tz?: string, loose?: boolean) => (current: DateTime | null) => {
  if (!current) {
    return true
  }

  const now = DateTime.now().setZone(tz || "local")
  const currentTZ = current.setZone(tz || "local")
  const tooLate = current > now.plus({ days: 90 }).startOf("day")
  const tooEarly = !loose && current.startOf("day").toMillis() < now.startOf("day").toMillis()

  const isSameDay = currentTZ.hasSame(now, "day") && currentTZ.hasSame(now, "year") && currentTZ.hasSame(now, "month")
  if (isSameDay && current.hour <= 23) {
    return false
  }

  return tooEarly || tooLate
}

export const disabledTime = (tz: string) => (current: DateTime | null) => {
  return {
    disabledHours: () => {
      // create operational hours from 6 to 23
      const operationalHours = Array.from({ length: 24 }, (_, i) => i)
      // if current date is null, disable all hours
      if (!current) {
        return operationalHours
      }
      const now = DateTime.now().setZone(tz || "local")
      const currentTZ = current.setZone(tz || "local")
      const isSameDay = currentTZ.hasSame(now, "day") && currentTZ.hasSame(now, "year") && currentTZ.hasSame(now, "month")

      // if current date is today, disable all hours before current hour + 1
      if (isSameDay) {
        const disabledOperationHours = operationalHours.filter((hour) => hour < now.hour || (hour === now.hour && now.minute > 45))
        return disabledOperationHours
      }
      // if current date is before today, disable all hours
      if (current.startOf("day") < now.startOf("day")) {
        return operationalHours
      }

      // if current date is after today, everything is available
      return []
    },
    disabledMinutes: (_: number) => {
      const availableMinutes = [0, 15, 30, 45]

      // if current date is null, disable all hours
      if (!current) {
        return availableMinutes
      }
      const now = DateTime.now().setZone(tz || "local")
      const currentTZ = current.setZone(tz || "local")
      const isSameDay = currentTZ.hasSame(now, "day") &&
        currentTZ.hasSame(now, "month") &&
        currentTZ.hasSame(now, "year")

      // if it's current hour, disable all minutes before current minute + 1
      if (isSameDay && currentTZ?.hour === now.hour) {
        const disabledMinutes = availableMinutes.filter((minute) => minute < now.minute + 1)
        return disabledMinutes
      }

      return []
    },
    disabledSeconds: (_hour: number, _minute: number) => {
      return [0]
    }
  }
}

export const getHumanizedTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return `${hours ? `${hours}hr` : ""} ${minutes ? `${minutes}min` : "0min"}`
}
