import React, { useEffect, useState } from "react"
import { Form, Input } from "antd"
import { faLocationDot, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getDisplayAddress } from "@/utils/addresses"
import { LocationDetailsType } from "@/types/orders.types"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import usePlacesAutocomplete, {
  FetchPredictionsParams,
} from "@/hooks/autocomplete/usePlacesAutocomplete"
import { CountdownCircleTimer } from "react-countdown-circle-timer"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { DateTime } from "luxon"
import { brandingColorsSelector } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"
import { ICustomer } from "@/api/customers"
import { useFlags } from "flagsmith/react"

interface AddressAutocompleteProps {
  name: string
  label?: string
  placeholder?: string

  searchValue: string
  savedCustomers?: ICustomer[]
  onSelected: (prediction: any) => void
  onChange: (e?: any) => void

  isInvalid?: boolean
  minified?: boolean

  size?: SizeType
  relative?: boolean

  fetchPredictionsOptions?: FetchPredictionsParams
  hideTopLabel?: boolean
  isWarning?: boolean
}

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  size,
  isInvalid,
  minified,
  relative,
  fetchPredictionsOptions,
  onChange,
  onSelected,
  savedCustomers,
  placeholder,
  searchValue,
  name,
  label,
  hideTopLabel,
  isWarning,
}) => {
  const { t } = useTranslation()
  const flags = useFlags(["show_address_inaccurate"])
  const { primaryColor } = useRecoilValue(brandingColorsSelector)

  const [focus, setFocus] = useState(false)
  const [listOpen, setListOpen] = useState(false)
  const isOccupied = !hideTopLabel && (focus || searchValue?.length)
  const labelClass = isOccupied ? `${size} label as-label` : `${size} label as-placeholder`
  const [fieldsChanged, setFieldsChanged] = useState(false)

  const [firstRequestTime, setFirstRequestTime] = useState<DateTime>()
  const [sessionId, _] = useState(uuidv4())
  const [debounceTimer, setDebounceTimer] = useState(0)

  const { predictions, fetchPredictions, debounceTime } = usePlacesAutocomplete()

  const predictionsAndCustomers = [...(savedCustomers || []), ...predictions]

  useEffect(() => {
    if (!fieldsChanged) {
      return
    }

    if (searchValue?.length > 3 && fieldsChanged) {
      setDebounceTimer(debounceTime)
    }

    if (!firstRequestTime) {
      setFirstRequestTime(DateTime.now())
    }

    fetchPredictions(searchValue, {
      ...fetchPredictionsOptions,
      sessionId,
      seconds_since_last_request: firstRequestTime?.isValid
        ? DateTime.now().diff(firstRequestTime, "second").seconds
        : undefined,
    })
  }, [searchValue])

  const setFocusFalseDelay = () => {
    setTimeout(function () {
      setFocus(false)
    }, 400)
  }

  const formattedItem = (prediction: any) => {
    switch (prediction.type) {
      case "customer":
        return (
          <div className="flex flex-row flex-wrap shrink-0">
            <p className="ml-1 text-left font-bold mr-2">
              <FontAwesomeIcon
                icon={faUser}
                style={{
                  color: "#4100FF",
                  height: "1em",
                  marginRight: "5px",
                  marginTop: "5px",
                  float: "left",
                }}
              />
              {prediction?.firstName || ""}
            </p>

            <p className="ml-1 text-left text-ellipsis overflow-hidden">
              {getDisplayAddress({
                address: prediction?.address,
                buildingNumber: prediction?.buildingNumber,
              } as LocationDetailsType)}
            </p>
          </div>
        )
      case "address":
        return (
          <p className="ml-1 text-left">
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{
                color: "#4100FF",
                height: "1em",
                marginRight: "5px",
                marginTop: "5px",
                float: "left",
              }}
            />
            {prediction?.description || "Not found"}
          </p>
        )
      default:
        return null
    }
  }

  const shouldShowWarning = flags.show_address_inaccurate.enabled && isWarning && !fieldsChanged

  return (
    <div className="w-full">
      <Form.Item
        className={`w-full ${shouldShowWarning ? "mb-7" : ""}`}
        style={{ marginBottom: minified ? "0" : "" }}
        name={name}
        validateStatus={shouldShowWarning ? "warning" : ""}
        help={shouldShowWarning ? t("errors.draftUpload.addressMayBeInaccurate") : ""}
        rules={
          minified
            ? []
            : [
                () => ({
                  validator(_) {
                    if (isInvalid) {
                      return Promise.reject(new Error(t("common.enterAddress")))
                    }
                    return Promise.resolve()
                  },
                }),
              ]
        }
      >
        <div
          className={`h-full ${minified ? "w-full" : "float-label"}`}
          onBlur={() => setFocusFalseDelay()}
          onFocus={() => setFocus(true)}
        >
          {minified ? (
            <div className="flex w-full items-center gap-x-4">
              <Input
                name={name}
                value={searchValue}
                autoComplete="off"
                defaultValue={searchValue}
                onChange={(e) => {
                  setTimeout(() => setListOpen(true), 700)
                  if (!fieldsChanged) {
                    setFieldsChanged(true)
                  }
                  onChange(e)
                }}
                className={"border-none w-full px-0"}
                style={{
                  zIndex: "1",
                }}
                placeholder={placeholder}
              />
              {debounceTimer > 0 && (
                <CountdownCircleTimer
                  key={searchValue}
                  isPlaying
                  colors={"#a1a1aa"}
                  trailColor={"#FFFFFF"}
                  duration={(debounceTimer + 300) / 1000}
                  onComplete={() => setDebounceTimer(0)}
                  size={20}
                  strokeWidth={4}
                />
              )}
            </div>
          ) : (
            <>
              <Input
                name={name}
                value={searchValue}
                {...(debounceTimer > 0
                  ? {
                      suffix: (
                        <CountdownCircleTimer
                          key={searchValue}
                          isPlaying
                          colors={"#a1a1aa"}
                          trailColor={"#FFFFFF"}
                          duration={(debounceTimer + 300) / 1000}
                          onComplete={() => setDebounceTimer(0)}
                          size={20}
                          strokeWidth={4}
                        />
                      ),
                    }
                  : {})}
                prefix={
                  <>
                    <FontAwesomeIcon
                      className={`flex-shrink-0 h-4 mr-2`}
                      style={{ color: primaryColor ?? "#4100FF" }}
                      icon={faLocationDot}
                    />
                  </>
                }
                autoComplete="off"
                onChange={(e) => {
                  setTimeout(() => setListOpen(true), 700)
                  if (!fieldsChanged) {
                    setFieldsChanged(true)
                  }
                  onChange(e)
                }}
                placeholder={focus ? "" : placeholder}
                size={size}
                className={"float-input"}
                style={{
                  zIndex: "1",
                }}
              />
              <label className={labelClass}>{isOccupied ? label : ""}</label>
            </>
          )}
        </div>
      </Form.Item>
      {listOpen && predictionsAndCustomers?.length > 0 && searchValue?.length > 2 && (
        <ul
          id="search-form-item-dropdown"
          className={`${
            relative ? "relative" : "absolute"
          } z-50 bg-white border-2 overflow-y-auto px-3`}
          style={{
            maxHeight: "20rem",
          }}
        >
          {predictionsAndCustomers?.map((prediction: any, index: number) => (
            <li
              key={index}
              className="py-1 pb-2 cursor-pointer"
              onClick={(e) => {
                setFieldsChanged(false)
                if (onSelected) {
                  onSelected(prediction), e.preventDefault()
                  setListOpen(false)
                  setFocus(false)
                }
              }}
            >
              {formattedItem(prediction)}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
