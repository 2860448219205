import React from "react"

interface SquareIconContainerProps {
  icon: JSX.Element | React.ReactElement
  color: string
  className?: string
}

const SquareIconContainer: React.FC<SquareIconContainerProps> = ({
  icon,
  className = "",
  color = "bg-kosmoLightGray",
}) => {
  if (!icon) return <></>

  return <span className={`inline-flex justify-center w-5 h-5 rounded-md ${className} ${color}`}>{icon}</span>
}

export default SquareIconContainer
