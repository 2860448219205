
// function that takes value such as 08:00, then converts it to a DateTime object

import { DateTime } from "luxon";

// if now is before 08:00, it will return the same day at 08:00, otherwise it will return the next day at 08:00
export const getDateTimesFromDayRange = (arrivalTimes: { start: string; end: string }) => {
  const now = DateTime.now()
  const start = DateTime.fromFormat(arrivalTimes.start, "HH:mm")
  const end = DateTime.fromFormat(arrivalTimes.end, "HH:mm")
  if (now < start) {
    return {
      from: start.set({ day: now.day, month: now.month, year: now.year }),
      to: end.set({ day: now.day, month: now.month, year: now.year }),
    }
  }
  return {
    from: start.set({ day: now.day + 1, month: now.month, year: now.year }),
    to: end.set({ day: now.day + 1, month: now.month, year: now.year }),
  }
}
