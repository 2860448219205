import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBasketShopping,
  faBurger,
  faCapsules,
  faShop,
  faSquare,
  faTruck,
  faWineGlass,
} from "@fortawesome/free-solid-svg-icons"
import { IProductType } from "@/types/productTypes.types"

interface IProductTypeIconProps {
  name: IProductType
}

export const ProductTypeIcon = (props: IProductTypeIconProps): React.ReactElement => {
  const iconName = props.name || "Default"

  const productTypeIcons: { name: string; component: React.ReactElement }[] = [
    {
      name: IProductType.COURIER,
      component: (
        <FontAwesomeIcon
          className="flex flex-shrink-0 h-4 w-4 text-kosmoBlue my-auto mr-2"
          icon={faTruck}
        />
      ),
    },
    {
      name: IProductType.FOOD,
      component: (
        <FontAwesomeIcon
          className="flex flex-shrink-0 h-4 w-4 text-kosmoBlue my-auto mr-2"
          icon={faBurger}
        />
      ),
    },
    {
      name: IProductType.FLOWERS,
      component: (
        <img
          src="/assets/images/customIcons/flower-ic.svg"
          className="flex flex-shrink-0 h-5 w-5 text-kosmoBlue my-auto mr-1"
        />
      ),
    },
    {
      name: IProductType.PHARMACY,
      component: (
        <FontAwesomeIcon
          className="flex flex-shrink-0 h-5 w-5 text-kosmoBlue my-auto mr-2"
          icon={faCapsules}
        />
      ),
    },
    {
      name: IProductType.GROCERIES,
      component: (
        <FontAwesomeIcon
          className="flex flex-shrink-0 h-4 w-4 text-kosmoBlue my-auto mr-2"
          icon={faBasketShopping}
        />
      ),
    },

    {
      name: IProductType.FOOD_ALCHOHOL_DISTRIBUTOR,
      component: (
        <FontAwesomeIcon
          className="flex flex-shrink-0 h-4 w-4 text-kosmoBlue my-auto mr-2"
          icon={faWineGlass}
        />
      ),
    },
    {
      name: IProductType.MARKETPLACE,
      component: (
        <FontAwesomeIcon
          className="flex flex-shrink-0 h-4 w-4 text-kosmoBlue my-auto mr-2"
          icon={faShop}
        />
      ),
    },
    {
      name: IProductType.OTHER,
      component: (
        <img
          src="/assets/images/customIcons/other-ic.svg"
          className="flex flex-shrink-0 h-5 w-5 text-kosmoBlue my-auto mr-1"
        />
      ),
    },
    {
      name: "Default",
      component: (
        <FontAwesomeIcon
          className="flex flex-shrink-0 h-4 w-4 text-kosmoBlue my-auto mr-2"
          icon={faSquare}
        />
      ),
    },
  ]

  const productIcon =
    productTypeIcons.find((icon) => icon.name === iconName) ||
    productTypeIcons.find((icon) => icon.name === "Default")

  // TODO: this could be improved
  return productIcon?.component as React.ReactElement
}
