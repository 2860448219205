import { Route } from "@/types/routes"
import { LOGIN_PAGE_PATH, NEW_ORDERS_PAGE_PATH } from "@/constants/routes"

export const onboardingRoute: Route = {
  isPublic: false,
  shouldRedirect: ({ isAuth, hasOrg }) => !isAuth || hasOrg,
  redirectTo: ({ query, isAuth, hasOrg }) => {
    if (isAuth && hasOrg) {
      return { newPath: NEW_ORDERS_PAGE_PATH, queryParams: query }
    } else {
      return { newPath: LOGIN_PAGE_PATH, queryParams: query }
    }
  },
}
