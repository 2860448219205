import React from "react"
import { Form } from "antd"
import { FloatInput } from "../../Inputs/FloatInput"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { SizeType } from "antd/lib/config-provider/SizeContext"

interface ISenderNameFormItemProps {
  validationErrorText: string
  label: string
  placeholder: string
  name: string
  size?: SizeType
  disabled?: boolean
}

export const SenderNameFormItem: React.FC<ISenderNameFormItemProps> = ({
  validationErrorText,
  label,
  placeholder,
  name,
  size,
  disabled = false,
}) => {
  return (
    <Form.Item
      name={name}
      validateTrigger="onSubmit"
      rules={[
        {
          required: true,
          message: validationErrorText,
        },
      ]}
    >
      <FloatInput
        name={name}
        prefixIcon={faUser}
        label={label}
        placeholder={placeholder}
        {...(size ? { size: size } : {})}
        disabled={disabled}
      />
    </Form.Item>
  )
}
