import { useState } from "react"
import { useRecoilState } from "recoil"

import { Zone } from "@/types/zones.types"
import { deleteZone, getZones, importZones } from "@/api/zones"
import { RcFile } from "antd/lib/upload"
import { zonesAtom } from "@/atoms/zonesAtom"

interface IUseZones {
  zones: Zone[]
  isLoading: boolean
  fetchZones: () => Promise<void>
  importZones: (file: RcFile) => Promise<Zone[]>
  removeZone: (id: string) => Promise<void>
}

export const useZones = (): IUseZones => {
  const [zones, setZones] = useRecoilState(zonesAtom)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchZones = async () => {
    setIsLoading(true)
    try {
      const res = await getZones()
      if (res?.data?.zones) {
        setZones(res.data.zones || [])
      }
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const _importZones = async (file: RcFile): Promise<Zone[]> => {
    try {
      const res = await importZones(file)
      if (res?.data?.zones) {
        setZones(res.data.zones || [])
      }
      return res?.data?.zones || []
    } catch (error) {
      throw error
    }
  }

  const removeZone = async (id: string) => {
    setIsLoading(true)
    try {
      await deleteZone(id)
      setZones(zones?.filter((zone) => zone.id !== id))
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return {
    zones: zones || [],
    fetchZones: fetchZones,
    importZones: _importZones,
    removeZone: removeZone,
    isLoading: isLoading,
  }
}
