import { defaultFilters, IFiltersState } from "@/hooks/useOrdersFilters"
import { DraftOrder, DraftOrdersGlobalMetadata, } from "@/types/draftOrder.types"
import { atom } from "recoil"
import { defaultGlobalMetadata } from "./draftOrdersAtom"

export type CreateRoutesState = { selectedRows: DraftOrder[], filters: IFiltersState, metadata: DraftOrdersGlobalMetadata, isOpen: boolean, skipUpload?: boolean, optimizationCallback: null | (() => Promise<void>), isLoadingOptimize: boolean, optimizationCompleted: boolean }

export const createRoutesAtom = atom<CreateRoutesState>({
  key: "createRoutesAtom",
  default: { selectedRows: [], filters: defaultFilters, metadata: defaultGlobalMetadata, isOpen: false, optimizationCallback: null, isLoadingOptimize: false, optimizationCompleted: false },
})
