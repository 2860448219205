import React from "react"
import { useUploadTemplate } from "@/hooks/useUploadTemplate"
import { DraftOrder } from "@/types/draftOrder.types"
import { IQuotePayload } from "@/types/quotes.types"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import DragAndUploadFileModule from "@/UI/Inputs/DragAndUploadFileModule"
import { message, Button, Progress } from "antd"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { uploadSummaryAtom } from "@/atoms/uploadSummaryAtom"

interface UploadTemplateProps {
  withPickup?: boolean
  handleUploadCallback: (uploadedOrders: DraftOrder[] | IQuotePayload[]) => Promise<any>
  onCancel: () => void
  isPublic?: boolean
}

const UploadTemplate: React.FC<UploadTemplateProps> = ({
  withPickup,
  handleUploadCallback,
  onCancel,
  isPublic,
}) => {
  const { t } = useTranslation()
  const uploadSummary = useRecoilValue(uploadSummaryAtom)
  const {
    processTemplateFile,
    isLoading,
    isDisabled,
    file,
    progressPercent,
    setFile,
    setSelectedPickup,
    selectedPickupId,
  } = useUploadTemplate(!!isPublic)

  const handleNext = async () => {
    try {
      const quotes = await processTemplateFile(handleUploadCallback)
      if (quotes && quotes.length > 0 && uploadSummary?.errors?.length === 0) {
        message.success(t("new-orders.DraftOrderUpload.uploadSuccessMessage"))
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <div className="flex flex-col w-full space-y-4">
      <div className={`items-center`}>
        <DragAndUploadFileModule
          file={file}
          setFile={(file) => {
            setFile(file)
          }}
          isDisabled={isLoading}
          selectedPickupId={selectedPickupId}
          {...(withPickup ? { setSelectedPickup: setSelectedPickup } : {})}
          hint={t("new-orders.FileUpload.uploadMaxSize")}
        />
      </div>
      <div className="flex flex-col items-end">
        <div className="flex justify-end">
          <Button
            className=""
            onClick={() => {
              setFile([])
              onCancel()
            }}
          >
            {t("common.cancel")}
          </Button>
          <KosmoButton
            id="draft-order-upload-next-step"
            classname="ml-2"
            isDisabled={isDisabled || !file?.length}
            isLoading={isLoading}
            onClick={handleNext}
            type="primary"
          >
            {t("new-orders.DraftOrderUpload.uploadAndPreview")}
          </KosmoButton>
        </div>
        {isLoading && (
          <div className="flex w-full mt-2">
            <Progress percent={progressPercent} strokeColor={"#4100FF"} />
          </div>
        )}
      </div>
    </div>
  )
}

export default UploadTemplate
