import { useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"

import { draftOrdersAtom } from "@/atoms/draftOrdersAtom"
import { IQuotePayload } from "@/types/quotes.types"
import {
  extractDropoffFromDraftOrder,
  extractDropoffsFromDraftOrder,
  getDraftOrders,
  postDraftOrders,
  putDraftBarcodes,
} from "@/api/draftorder"
import { message } from "antd"
import { useTranslation } from "react-i18next"
import axios, { AxiosResponse } from "axios"
import { DraftOrder } from "@/types/draftOrder.types"
import { userAtom } from "@/atoms/userAtom"
import { Location } from "@/types/orders.types"
import { v4 as uuidv4 } from "uuid"
import { isEmpty, isEqual, omit } from "lodash"
import { ICreateSingleOrder } from "@/types/createSingleOrder.types"
import { formatSenderRecipientPayload } from "@/api/quotes"
import { IFiltersState } from "./useOrdersFilters"
import { VehicleType } from "@/types/vehicle.types"

interface IUseDraftOrders {
  isCreatingDraftOrder: boolean
  isFetchingDraftOrders: boolean
  convertOrderPayloadToDraft: (orderPayload: ICreateSingleOrder) => IQuotePayload
  extractDropOff: (
    orderId: string,
    index: number,
    address: string
  ) => Promise<DraftOrder | undefined>
  extractDropoffs: (orderId: string) => Promise<void>
  fetchDraftOrders: (
    isPolling: boolean,
    filters: IFiltersState,
    abortController?: AbortController
  ) => Promise<void>
  createDraftOrders: (
    draftOrdersPayload: IQuotePayload[],
    duplicated?: boolean
  ) => Promise<AxiosResponse<DraftOrder[]>>
  createEmptyDraftOrder: () => Promise<void>
  createDraftOrdersFromOrder: (
    orders: {
      recipients: Location[]
      sender: Location
      vehicleType?: VehicleType
      schedule?: { pickup_at: number }
    }[],
    duplicateMode?: string
  ) => Promise<void>
  generateDraftBarcodes: (orderIds: string[], isSingleStopPrint?: boolean) => Promise<DraftOrder[]>
}

export const useDraftOrders = (): IUseDraftOrders => {
  const { t } = useTranslation()
  const [draftOrders, setDraftOrders] = useRecoilState(draftOrdersAtom)
  const [isCreatingDraftOrder, setIsCreatingDraftOrder] = useState<boolean>(false)
  const [isFetchingDraftOrders, setIsFetchingDraftOrders] = useState<boolean>(false)
  const { preference } = useRecoilValue(userAtom)

  const convertOrderPayloadToDraft = (orderPayload: ICreateSingleOrder): IQuotePayload => {
    const draftPayload: IQuotePayload = {
      sender: formatSenderRecipientPayload(orderPayload.pickupDetails),
      recipients: orderPayload.dropOffDetails.map((dropoff) =>
        formatSenderRecipientPayload(dropoff)
      ),
      deliveryType: orderPayload.deliveryType?.type,
      ...(orderPayload?.deliveryType?.schedule
        ? {
            schedule: {
              pickupAt: orderPayload?.deliveryType.schedule,
              pickup_at: orderPayload?.deliveryType?.schedule,
            },
          }
        : {}),
      autoReassign: !!preference.platformReassign,
    }
    return draftPayload
  }

  const fetchDraftOrders = async (
    isPolling: boolean = false,
    filters: IFiltersState,
    abortController?: AbortController
  ) => {
    if (!isPolling) {
      setIsFetchingDraftOrders(true)
    }
    try {
      const { data } = await getDraftOrders(filters, abortController?.signal)
      if (!isEqual(data, draftOrders?.orders) || !isEqual(draftOrders?.metadata, data?.metadata)) {
        setDraftOrders((d) => ({
          ...d,
          orders: data?.draftOrders,
          metadata: data?.metadata,
        }))
      }
      if (!isPolling) {
        setIsFetchingDraftOrders(false)
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsFetchingDraftOrders(false)
      }
    } finally {
      setIsFetchingDraftOrders(false)
    }
  }

  const createEmptyDraftOrder = async () => {
    try {
      const payload: IQuotePayload = {}

      if (preference?.platformReassign) {
        payload.autoReassign = true
      }

      await createDraftOrders([payload])
    } catch (error) {
      message.error(t("errors.common"))
    }
  }

  const createDraftOrdersFromOrder = async (
    orders: {
      recipients: Location[]
      sender: Location
      vehicleType?: VehicleType
      schedule?: { pickup_at: number }
    }[],
    duplicateMode?: string
  ) => {
    setIsCreatingDraftOrder(true)
    try {
      const payload = orders.map((order) => {
        const includeScheduledPickupAt =
          order?.schedule?.pickup_at && order?.schedule?.pickup_at >= Date.now()
        return {
          sender: {
            ...order.sender,
            id: !isEmpty(order?.sender?.id) ? order?.sender?.id : undefined,
            location: {
              ...order.sender.location,
              id: !isEmpty(order.sender.location?.id) ? order.sender.location?.id : undefined,
            },
          },
          recipients: order?.recipients?.map((recipient) => {
            // Reset ETA related fields when duplicating to enforce their re-calculation
            return omit(
              {
                ...recipient,
                id: uuidv4(),
                dropoffEta: undefined,
                expectedArrival: undefined,
                plannedArrivalAt: undefined,
                plannedDepartureAt: undefined,
              },
              "location.id"
            )
          }),
          vehicleType: order?.vehicleType,
          ...(includeScheduledPickupAt
            ? { schedule: { pickupAt: order?.schedule?.pickup_at } }
            : {}),
        }
      })

      // @ts-ignore
      await createDraftOrders(payload, true, duplicateMode)
    } catch (error) {
      throw error
    } finally {
      setIsCreatingDraftOrder(false)
    }
  }

  const createDraftOrders = async (
    draftOrdersPayload: IQuotePayload[],
    duplicated?: boolean,
    duplicateMode?: string
  ) => {
    try {
      setIsCreatingDraftOrder(true)
      const res = await postDraftOrders(draftOrdersPayload, duplicated, duplicateMode)
      const { data } = res
      const orderItems = data.map((item, i) => ({
        ...item,
        index: i,
      }))
      setDraftOrders((d) => ({
        ...d,
        orders: [...orderItems, ...d.orders],
      }))

      return res
    } catch (error) {
      throw error
    } finally {
      setIsCreatingDraftOrder(false)
    }
  }

  const extractDropOff = async (
    orderId: string,
    index: number,
    address: string
  ): Promise<DraftOrder | undefined> => {
    try {
      const { data } = await extractDropoffFromDraftOrder(orderId, index, address)
      if (data) {
        setDraftOrders((state) => {
          return {
            ...state,
            orders: data,
          }
        })
      }

      return data?.find((order) => order?.id === orderId)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const extractDropoffs = async (orderId: string): Promise<void> => {
    try {
      await extractDropoffsFromDraftOrder(orderId)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const generateDraftBarcodes = async (
    orderIds: string[],
    isSingleStopPrint?: boolean
  ): Promise<DraftOrder[]> => {
    try {
      const { data } = await putDraftBarcodes(orderIds)
      if (!isSingleStopPrint) {
        setDraftOrders((state) => {
          const newDraftOrders = state.orders.map((order) => {
            const updatedDraftOrder = data.find((o) => o.id === order.id)
            return updatedDraftOrder ?? order
          })
          return { ...state, orders: newDraftOrders }
        })
      }
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  return {
    extractDropOff,
    extractDropoffs,
    fetchDraftOrders,
    createDraftOrders,
    isCreatingDraftOrder,
    isFetchingDraftOrders,
    createEmptyDraftOrder,
    createDraftOrdersFromOrder,
    convertOrderPayloadToDraft,
    generateDraftBarcodes,
  }
}
