import React from "react"
import { Form } from "antd"
import { faTag, faWarning } from "@fortawesome/free-solid-svg-icons"
import { FloatInput } from "@/UI/Inputs/FloatInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

interface IAliasFormItem {
  validationErrorText: string
  placeholder: string
  name: string
  withWarning?: boolean
}

export const AliasFormItem: React.FC<IAliasFormItem> = ({
  validationErrorText,
  name,
  placeholder,
  withWarning = false,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col mb-2">
      <Form.Item
        className="mb-0"
        name={name}
        validateTrigger="onSubmit"
        rules={[
          {
            required: true,
            message: validationErrorText,
          },
        ]}
      >
        <FloatInput name={name} placeholder={placeholder} label={placeholder} prefixIcon={faTag} />
      </Form.Item>
      {withWarning && (
        <div className="flex items-start gap-x-1 mt-2 ml-2 mb-2">
          <FontAwesomeIcon className="text-red-400 mt-0.5" icon={faWarning} fontSize={"small"} />
          <p className="italic text-sm text-gray-500">
            {t("common.AliasFormItem.warningUseForCommunications")}
          </p>
        </div>
      )}
    </div>
  )
}
