import axios from "@/modules/shared/AxiosInterceptor"
import { CreateTeamPayload, Team } from "@/types/teams.types"

export async function getTeams() {
  return axios
    .get<{ teams: Team[] }>("/teams")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putTeam(team: Team) {
  return axios
    .put<Team>(`/teams/${team.id}`, team)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function deleteTeam(id: number) {
  return axios
    .delete(`/teams/${id}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function postTeam(team: CreateTeamPayload) {
  return axios
    .post<Team>(`/teams`, team)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
