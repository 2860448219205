import axios from "@/modules/shared/AxiosInterceptor"
import {
  Client,
  CreateClientPayload,
  MerchantOrderTracking,
  PublicCustomerPortal,
} from "@/types/clients.types"
import { PriceConfiguration, PriceConfigurations } from "@/types/drivesAppSettings"
import { OrderType } from "@/types/orders.types"
import { IQuotePayload } from "@/types/quotes.types"
import { AxiosResponse } from "axios"

export async function postClient(client: CreateClientPayload): Promise<AxiosResponse<Client>> {
  return axios
    .post("/customer-portals", client)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putClient(client: Client): Promise<AxiosResponse<Client>> {
  return axios
    .put(`/customer-portals/${client.id}`, client)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getClients(): Promise<AxiosResponse<Client[]>> {
  return axios
    .get("/customer-portals")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function deleteClient(id: string): Promise<AxiosResponse<{ id: string }>> {
  return axios
    .delete(`/customer-portals/${id}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getPublicCustomerPortal(
  hash: string
): Promise<AxiosResponse<PublicCustomerPortal>> {
  return axios
    .get(`/customer-portals/public/${hash}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function postPublicDraftOrders(hash: string, orders: IQuotePayload[]) {
  return axios
    .post(`/customer-portals/public/${hash}/draft-orders`, {
      orders,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putPortalBarcodes(
  hash: string,
  orderIds: string[],
  stopTrackingHashes: string[],
  type: OrderType
): Promise<AxiosResponse<MerchantOrderTracking[]>> {
  return axios
    .put(`/customer-portals/public/${hash}/barcodes`, {
      ids: orderIds,
      stopHashes: stopTrackingHashes,
      type,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getPublicOrders(
  hash: string
): Promise<AxiosResponse<MerchantOrderTracking[]>> {
  return axios
    .get(`/customer-portals/public/${hash}/orders`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getClientPay(): Promise<AxiosResponse<PriceConfigurations>> {
  return axios
    .get("/customer-portals/pay")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putClientPay(config: PriceConfiguration) {
  return axios
    .put<PriceConfigurations>("/customer-portals/pay", { ...config })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function deleteClientPayApi(configId: string) {
  return axios
    .delete(`/customer-portals/pay/${configId}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
