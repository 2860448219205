import React from "react"
import { faHashtag } from "@fortawesome/free-solid-svg-icons"
import { Form } from "antd"
import { FloatInput } from "../../Inputs/FloatInput"
import { SizeType } from "antd/lib/config-provider/SizeContext"

interface IBuildingNameFormItem {
  label: string
  placeholder: string
  name: string
  size?: SizeType
}

export const BuildingNumberFormItem: React.FC<IBuildingNameFormItem> = ({
  label,
  placeholder,
  name,
  size = "middle",
}) => {
  return (
    <Form.Item name={name} validateTrigger="onSubmit">
      <FloatInput
        name={name}
        prefixIcon={faHashtag}
        label={label}
        placeholder={placeholder}
        helpText="14-04"
        size={size}
      />
    </Form.Item>
  )
}
