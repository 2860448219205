import React from "react"
import { Form } from "antd"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FloatInput } from "@/UI/Inputs/FloatInput"
import { useTranslation } from "react-i18next"

interface EmailFormItemProps {
  placeholder: string
  name: string
  disabled?: boolean
}

export const EmailFormItem: React.FC<EmailFormItemProps> = ({ name, placeholder, disabled }) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      validateTrigger="onSubmit"
      rules={[
        {
          type: "email",
          message: t("common.form.providedEmailIsNotValid"),
        },
      ]}
    >
      <FloatInput
        name={name}
        placeholder={placeholder}
        label={placeholder}
        prefixIcon={faEnvelope}
        disabled={disabled}
      />
    </Form.Item>
  )
}
