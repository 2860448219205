import { defaultError, errorsMap } from "@/api/constants/errors"
import { ErrorKeys, IDisplayableError } from "@/types/errors.types"

export function getKosmoError(errorKey: ErrorKeys): IDisplayableError {
  const kosmoErr = errorsMap[errorKey]
  if (!kosmoErr) {
    return defaultError
  }

  return kosmoErr
}
