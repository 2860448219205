import { Tag } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

interface MenuTabTagProps {
  type: "new" | "beta"
}

const MenuTabTag: React.FC<MenuTabTagProps> = ({ type }) => {
  const { t } = useTranslation()

  return (
    <Tag className="rounded-full ml-2" color="#DBEAFE">
      <p
        className="text-black text-sm capitalize"
        style={{
          lineHeight: "1.5rem",
        }}
      >
        {type === "new" ? t("common.new") : t("common.beta")}
      </p>
    </Tag>
  )
}

export default MenuTabTag
