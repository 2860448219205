import { changeDriver, deleteDriver, getDrivers, postDriver, putDriver } from "@/api/drivers"
import { driversAtom, driversSelector } from "@/atoms/driversAtom"
import { userAtom } from "@/atoms/userAtom"
import { Driver, EnrichedDriver } from "@/types/drivers.types"
import { useMemo, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { teamsAtom } from "@/atoms/teamsAtom"
import { ordersAtom } from "@/atoms/ordersAtom"
import { AssignmentTypes } from "@/types/draftOrder.types"

interface IUseDrivers {
  drivers: Driver[]
  enrichedDrivers: EnrichedDriver[]
  isLoading: boolean
  createDriver: (driver: Driver) => Promise<void>
  fetchDrivers: (forceFetch?: boolean, targetOrgId?: string) => Promise<void>
  removeDriver: (id: string) => Promise<void>
  reassignDriver: (orderId: string, driverId: string) => Promise<void>
  updateDriver: (driver: Driver) => Promise<void>
}

const cache = new Map<string, Driver[]>()

export const useDrivers = (): IUseDrivers => {
  const setDrivers = useSetRecoilState(driversAtom)
  const [{ metadata }, setOrders] = useRecoilState(ordersAtom)

  const { org } = useRecoilValue(userAtom)
  const setTeams = useSetRecoilState(teamsAtom)
  const drivers = useRecoilValue(driversSelector)
  const enrichedDrivers = useMemo(() => {
    return drivers?.map((driver) => {
      const ongoingDeliveries = metadata?.ongoingDeliveriesPerDriver?.[driver.id] || 0
      return {
        ...driver,
        ongoingDeliveries,
      }
    })
  }, [drivers, metadata?.ongoingDeliveriesPerDriver])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchDrivers = async (forceFetch?: boolean, targetOrgId?: string) => {
    setIsLoading(true)
    try {
      const driversOrgId = targetOrgId || org?.id
      if (cache.has(driversOrgId) && !forceFetch) {
        const cacheDrivers = cache.get(driversOrgId)
        if (cacheDrivers) {
          setDrivers(cacheDrivers)
        }
        return
      }
      const { data } = await getDrivers()
      cache.set(driversOrgId, data?.drivers)
      setDrivers(data?.drivers)
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const createDriver = async (driver: Driver) => {
    setIsLoading(true)
    try {
      const { data } = await postDriver(driver)
      setDrivers((state) => {
        const newDrivers = [...state?.filter((driver) => driver.id !== data.id), data]
        cache.set(org?.id, newDrivers)
        return newDrivers
      })

      if (driver?.teamIDs?.length) {
        driver?.teamIDs?.forEach((teamID) => {
          setTeams((state) => {
            const newTeams = state.map((team) => {
              if (team.id === teamID) {
                return {
                  ...team,
                  drivers: [...team?.drivers, data?.id],
                }
              }
              return team
            })
            return newTeams
          })
        })
      }
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const removeDriver = async (id: string) => {
    setIsLoading(true)
    try {
      await deleteDriver(id)
      const newDrivers = [...drivers]?.filter((driver) => driver.id !== id)
      setDrivers(newDrivers)
      cache.set(org?.id, newDrivers)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const reassignDriver = async (orderId: string, driverId: string) => {
    try {
      setIsLoading(true)
      await changeDriver(orderId, driverId)
      const newDriver = drivers?.find((driver) => driver.id === driverId)
      setOrders((state) => {
        const newOrders = state.orders.map((order) => {
          if (order.id === orderId) {
            order = {
              ...order,
              driver: newDriver,
              assignmentType: AssignmentTypes.EMPTY,
            }
          }
          return order
        })
        return { ...state, orders: newOrders }
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const updateDriver = async (driver: Driver) => {
    setIsLoading(true)
    try {
      const { data } = await putDriver(driver)
      setDrivers((state) => {
        const existingDriverIndex = state.findIndex((driver) => driver.id === data.id)
        if (existingDriverIndex === -1) {
          return state
        }

        // ENG NASH DEMO
        const updatedDrivers = [...state]
        updatedDrivers[existingDriverIndex] = {
          ...data,
          attachments: updatedDrivers[existingDriverIndex]?.attachments,
        }
        // END ENG NASH DEMO
        cache.set(org?.id, updatedDrivers)
        return updatedDrivers
      })

      if (driver?.teamIDs?.length) {
        driver?.teamIDs?.forEach((teamID) => {
          setTeams((state) => {
            const newTeams = state.map((team) => {
              if (team.id === teamID) {
                return {
                  ...team,
                  drivers: [...team?.drivers, data?.id],
                }
              }
              return team
            })
            return newTeams
          })
        })
      }
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return {
    drivers,
    enrichedDrivers,
    isLoading,
    createDriver,
    fetchDrivers,
    removeDriver,
    reassignDriver,
    updateDriver,
  }
}
