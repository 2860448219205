import axios from "@/modules/shared/AxiosInterceptor"
import { CoordinatesLatLng, PickupDetailsType } from "@/types/createSingleOrder.types"
import { StopType } from "@/types/orders.types"

import { AxiosResponse } from "axios"

export function getStores(): Promise<AxiosResponse<{ stores: PickupDetailsType[] }>> {
  return axios.get<{ stores: PickupDetailsType[] }>("/orgs/stores")
}

// TODO: Check this type and/or create a store payload
export function postStore(store: PickupDetailsType): Promise<AxiosResponse<PickupDetailsType>> {
  return axios
    .post<PickupDetailsType>("/orgs/stores", {
      ...store,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function formatStore(location: CoordinatesLatLng, storeFormValues: PickupDetailsType) {
  const phone = storeFormValues.phone || ""

  return {
    address: storeFormValues.address,
    buildingNumber: storeFormValues.buildingNumber,
    latitude: location.lat,
    longitude: location.lng,
    postalCode: storeFormValues.postalCode,
    name: storeFormValues.name,
    email: storeFormValues?.email,
    alias: storeFormValues.alias,
    notes: storeFormValues.notes,
    phone: phone.replace(/\+/g, "").replace(/\s/g, ""),
    type: StopType.PICKUP,
  }
}
// TODO: Not 100% sure about this one
export function editStores(
  storePayload: PickupDetailsType
): Promise<AxiosResponse<PickupDetailsType>> {
  return axios.put<PickupDetailsType>("/orgs/stores", storePayload)
}
