import Geocode from "react-geocode"
import { HereGeocodeItem, KosmoGeocodeResult } from "@/types/here.types";
import { AddressDetails } from "@/types/quotes.types"
import { intersection, orderBy } from "lodash";
import { CoordinatesLatLng } from "@/types/createSingleOrder.types";
import googleGeocodeWithBounds from "@/utils/Geocoding/googleGeocodeWithBounds"
import { countries } from "country-data";
import { GoogleGeocodeResponse, PartialGoogleGeocodeResult } from "@/hooks/autocomplete/GooglePlacesAutocomplete.types";

export const hereGeocodeByAddress = async (herePlatform: H.service.Platform, queryAddress: string, country?: string, latLng?: CoordinatesLatLng, postalCode?: string): Promise<HereGeocodeItem | undefined> => {
  const geocodingService = herePlatform.getSearchService()

  let referenceCountry
  if (country) {
    referenceCountry = countries?.[country]
  }

  // Reference country is a hard search parameters. For multiple countries org, Google Geocoding should be used
  let topResult;
  await geocodingService.geocode(
    {
      q: queryAddress,
      ...(postalCode ? { qq: `postalCode=${postalCode}` } : {}),
      show: ["countryInfo"],
      ...(referenceCountry ? { in: `countryCode:${referenceCountry?.alpha3}` } : {}),
      ...(latLng && latLng.lat !== 0 && latLng.lng !== 0 ? { at: `${latLng?.lat},${latLng?.lng}` } : {})
    },
    (result) => {
      const items: HereGeocodeItem[] = (result as any).items
      topResult = orderBy(items, ['scoring.queryScore'], ['desc'])?.[0]
    },
    (error) => {
      console.log(error)
      return undefined
    }
  )
  return topResult
}

export const googleGeocodeByAddress = async (queryAddress: string, country?: string, latLng?: CoordinatesLatLng): Promise<any> => {
  try {
    if (country || latLng) {
      if (country) {
        Geocode.setRegion(country)
      }
      if (latLng) {
        const bounds = new google.maps.LatLngBounds()
        bounds?.extend(latLng)
        const results = await googleGeocodeWithBounds(queryAddress, country, latLng ? bounds : undefined)
        return results
      }
    }

    const geocodeResult = await Geocode.fromAddress(queryAddress)
    return geocodeResult
  } catch (error) {
    throw error
  }
}

// View here supported types for "property": https://developers.google.com/maps/documentation/places/web-service/supported_types
export const getInfoFromDirectionService = (response?: any, property?: string, isShort?: boolean) => {
  if (!response || !property) {
    return null
  }
  for (let i = 0; i < response.results[0].address_components.length; i++) {
    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
      if (response.results[0].address_components[i].types[j] === property) {
        return isShort ? response.results[0].address_components[i].short_name : response.results[0].address_components[i].long_name
      }
    }
  }
  return null
}

export const buildAddressFromGeocode = (result?: any): AddressDetails => {
  if (!result) {
    return {
      postalCode: "",
      city: "",
      country: ""
    }
  }

  const postalCode = result.address_components.find((component: any) => component.types?.includes('postal_code'))?.long_name
  const city = result.address_components.find((component: any) => component.types?.includes('locality'))?.long_name
  const country = result.address_components.find((component: any) => component.types?.includes('country'))?.short_name
  return { postalCode, city, country }
}

const isHereWarning = (hereResult: HereGeocodeItem): boolean => {
  const warningTypes = ["street", "administrativeArea", "locality"]
  const isQueryScoreLow = hereResult?.scoring?.queryScore < 0.6
  return isQueryScoreLow || warningTypes?.includes(hereResult?.resultType)
}

const isGoogleWarning = (googleResult: PartialGoogleGeocodeResult, _?: string, resultsBounds?: google.maps.LatLngBounds): boolean => {
  const warningTypes = ["political", "locality", "route", "sublocality", "country", "neighborhood", "sublocality_level_1", "intersection"]
  // const isApproximate = ["RANGE_INTERPOLATED", 'APPROXIMATE'].includes(googleResult?.geometry?.location_type)
  // const googlePostalCode = googleResult?.address_components?.find((component) => component?.types?.includes("postal_code"))?.long_name
  // const isPostalCodeDifferent = !!postalCode && !!googlePostalCode && postalCode !== googlePostalCode

  const isComponentsCountLow = googleResult?.address_components?.length < 4
  let isBoundsWarning = false
  if (resultsBounds) {
    const start = resultsBounds?.getNorthEast()
    const center = resultsBounds?.getCenter()
    isBoundsWarning = google.maps.geometry.spherical.computeDistanceBetween(center, start) > 2000;
  }
  return isBoundsWarning || isComponentsCountLow || intersection(googleResult?.types, warningTypes)?.length === googleResult?.types?.length //|| isPostalCodeDifferent || isApproximate
}

const getGoogleResultBounds = (bounds: any) => {
  if (bounds?.northeast) {
    const newBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(bounds.southwest),
      new google.maps.LatLng(bounds.northeast)
    )
    return newBounds
  } else if (bounds?.getCenter) {
    return new google.maps.LatLngBounds(bounds)
  } else {
    return undefined
  }
}

export const formatHereToKosmoDirections = (
  result: HereGeocodeItem,
  rawAddress?: string
): KosmoGeocodeResult | undefined => {
  if (!result) {
    return undefined
  }
  return {
    address: { ...result?.address, country: result?.countryInfo?.alpha2 },
    location: result?.position,
    rawAddress: rawAddress || result?.address?.label,
    isWarning: isHereWarning(result)
  }
}

// Directly with the array
export const formatGoogleToKosmoGeocode = (
  geocodeResult: GoogleGeocodeResponse,
  rawAddress?: string,
  latLng?: CoordinatesLatLng,
  postalCode?: string
): KosmoGeocodeResult | undefined => {
  if (geocodeResult?.results?.length > 0 && latLng) {
    geocodeResult?.results?.sort((a, b) => {
      const aLat =
        typeof a?.geometry?.location?.lat === "function"
          ? a?.geometry?.location?.lat()
          : a?.geometry?.location?.lat
      const aLng =
        typeof a?.geometry?.location?.lng === "function"
          ? a?.geometry?.location?.lng()
          : a?.geometry?.location?.lng

      const bLat =
        typeof b?.geometry?.location?.lat === "function"
          ? b?.geometry?.location?.lat()
          : b?.geometry?.location?.lat
      const bLng =
        typeof b?.geometry?.location?.lng === "function"
          ? b?.geometry?.location?.lng()
          : b?.geometry?.location?.lng
      // If we have a starting location, we sort based on the absolute difference of the coordinates (rudimentary)
      return Math.abs(aLat - latLng?.lat + aLng - latLng?.lng) >
        Math.abs(bLat - latLng?.lat + bLng - latLng?.lng)
        ? 1
        : -1
    })
  }
  const topResult = geocodeResult.results?.[0]
  if (!topResult) {
    return undefined
  }

  const allResultsBounds = new google.maps.LatLngBounds()
  geocodeResult.results?.forEach((result) => {
    const resultBound = getGoogleResultBounds(result?.geometry?.bounds || result?.geometry?.viewport)
    if (resultBound) {
      allResultsBounds?.union(resultBound)
    }
  })

  return {
    address: {
      label: topResult?.formatted_address,
      city:
        getInfoFromDirectionService(geocodeResult, "administrative_area_level_2") ||
        getInfoFromDirectionService(geocodeResult, "administrative_area_level_1") ||
        getInfoFromDirectionService(geocodeResult, "political") ||
        "",
      postalCode: getInfoFromDirectionService(geocodeResult, "postal_code") || "",
      country: getInfoFromDirectionService(geocodeResult, "country", true) || "",
      district: "",
      state: "",
      street: "",
      stateCode: "",
    },
    location: {
      lat:
        typeof topResult?.geometry?.location?.lat === "function"
          ? topResult?.geometry?.location?.lat()
          : topResult?.geometry?.location?.lat,
      lng:
        typeof topResult?.geometry?.location?.lng === "function"
          ? topResult?.geometry?.location?.lng()
          : topResult?.geometry?.location?.lng,
    },
    rawAddress: rawAddress || topResult?.formatted_address,
    isWarning: isGoogleWarning(topResult, postalCode, allResultsBounds)
  }
}
