import axios from "@/modules/shared/AxiosInterceptor"
import { IInviteUser, IPendingInvitee, ITeamMember, IUpdateUser, IUser } from "@/types/user.types"
import { AxiosResponse } from "axios"

export function getUserProfile(): Promise<AxiosResponse<IUser>> {
  return axios
    .get<IUser>("/users/profile")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function getAllOrgUsers(): Promise<
  AxiosResponse<{
    users: ITeamMember[]
    pendingInvitations: IPendingInvitee[]
  }>
> {
  return axios
    .get<{ users: ITeamMember[]; pendingInvitations: IPendingInvitee[] }>("/users/orgs/all")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function patchReferralCode(referralCode: string) {
  return axios
    .patch("/users/referral", { referralCode })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function patchUser(user: IUpdateUser): Promise<AxiosResponse<{ status: number }>> {
  return axios
    .patch<{ status: number }>("/users/profile", {
      ...user,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function deleteUser(id: string): Promise<AxiosResponse<{ status: number }>> {
  return axios
    .delete<{ status: number }>("/users/orgs", {
      data: { userId: id },
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function inviteUser(info: IInviteUser): Promise<AxiosResponse<{ status: number }>> {
  return axios
    .post<{ status: number }>("/users/invite", {
      ...info,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function inviteUserByEmail(info: IInviteUser): Promise<AxiosResponse<{ status: number }>> {
  return axios
    .post<{ status: number }>("/users/invite", {
      ...info,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function acceptEmailInvitation(
  invitationId: string
): Promise<AxiosResponse<{ status: number }>> {
  return axios
    .post<{ status: number }>(`/users/invite/${invitationId}/accept`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function cancelInvite(invitationId: string): Promise<AxiosResponse<{}>> {
  return axios
    .delete<{}>(`/users/invite/${invitationId}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
