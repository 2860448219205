import { CountryCode, parsePhoneNumber } from "libphonenumber-js"

export const formatPhoneNumber = (phoneNumber: string, countryCode?: CountryCode): string => {
  try {
    if (phoneNumber) {
      let formattedPhoneNumber = parsePhoneNumber(phoneNumber, countryCode)
      if (formattedPhoneNumber.isValid()) {
        return formattedPhoneNumber.formatInternational()
      }
      // Fallback removing the country and auto-parsing relying on country code indicator
      formattedPhoneNumber = parsePhoneNumber(
        phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`
      )
      return formattedPhoneNumber.isValid()
        ? formattedPhoneNumber.formatInternational()
        : phoneNumber.length > 4 && phoneNumber.length < 16
        ? phoneNumber
        : ""
    } else return ""
  } catch (error) {
    return phoneNumber
  }
}
