import { brandingColorsSelector } from "@/atoms/userAtom"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useRecoilValue } from "recoil"

interface BrandedQuestionMarkProps {
  isBranded?: boolean
  isColored?: boolean
  size?: "small" | "medium"
  className?: string
}

const BrandedQuestionMark: React.FC<BrandedQuestionMarkProps> = ({
  isBranded = true,
  size = "medium",
  className,
  isColored = true,
}) => {
  const { primaryColor } = useRecoilValue(brandingColorsSelector)
  const defaultColor = isColored ? "#4100FF" : "black"

  return (
    <FontAwesomeIcon
      className={`cursor-pointer ${size === "small" ? "text-sm" : ""} ${className}`}
      icon={faQuestionCircle}
      style={{ color: isBranded && primaryColor ? primaryColor : defaultColor }}
    />
  )
}

export default BrandedQuestionMark
