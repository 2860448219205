import React from "react"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import { modalTypes } from "@/UI/Modals/ConfirmModal"
import { driversAtom } from "@/atoms/driversAtom"
import { usePrevious } from "@/hooks/usePrevious"
import { DraftOrder, DraftOrderOptimizationResult } from "@/types/draftOrder.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, message, Modal, Typography } from "antd"
import { isEqual, uniqBy } from "lodash"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { NEW_ORDERS_PAGE_PATH } from "@/constants/routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import { getOptimizationConfig } from "@/api/optimization"
import { OptimizationConfig, OptimizationMethod } from "@/types/optimization"
import LoadingModal from "@/UI/Modals/LoadingModal"
import { handleOptimizeDraftOrders } from "@/modules/new-orders/helpers"
import OptimizationSummaryModal from "./components/OptimizationSummaryModal/OptimizationSummaryModal"
import OptimizationForm from "./components/OptimizationSummaryModal/components/OptimizationForm"
import UploadTemplate from "@/modules/shared/UploadTemplate/UploadTemplate.container"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import UploadTemplateActionsButton from "@/modules/shared/UploadTemplate/components/UploadTemplateActionsButton"
import { createRoutesAtom } from "@/atoms/createRoutesAtom"
import { IQuotePayload } from "@/types/quotes.types"

const { Title } = Typography

interface RouteOptimizationModalProps {}

const RouteOptimizationModal: React.FC<RouteOptimizationModalProps> = ({}) => {
  const { t } = useTranslation()
  const [createRoutes, setCreateRoutes] = useRecoilState(createRoutesAtom)
  const resetCreateRoutes = useResetRecoilState(createRoutesAtom)
  const { sandboxRouter } = useSandboxRouter()

  const selectedStores = uniqBy(
    createRoutes?.selectedRows?.map((row) => row.sender),
    (sender) => sender?.location?.id
  )

  const previousSelectedStores = usePrevious(selectedStores)
  const drivers = useRecoilValue(driversAtom)
  const [optimizationConfig, setOptimizationConfig] = useState<OptimizationConfig | null>(null)
  const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>(undefined)
  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([])
  const [optimizationSummary, setOptimizationSummary] =
    useState<DraftOrderOptimizationResult | null>(null)

  const isUploadMode = !createRoutes?.skipUpload || createRoutes?.metadata?.total === 0

  const setIsLoadingOptimize = (isLoadingOptimize: boolean) => {
    setCreateRoutes((createRoutesState) => ({ ...createRoutesState, isLoadingOptimize }))
  }

  const setOpen = (isOpen: boolean) => {
    if (!isOpen) {
      resetCreateRoutes()
    } else {
      setCreateRoutes((createRoutesState) => ({ ...createRoutesState, isOpen }))
    }
  }

  const hideModal = (e?: React.MouseEvent) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }

    setCreateRoutes((createRoutesState) => ({
      ...createRoutesState,
      isOpen: false,
      optimizationCallback: null,
      skipUpload: undefined,
    }))
  }

  const handleOptimize = async (selectedDrivers: string[], startLocationId?: string) => {
    try {
      setIsLoadingOptimize(true)
      setCreateRoutes((createRoutesState) => ({ ...createRoutesState, isOpen: false }))
      const affectedRows = createRoutes?.selectedRows.map((row) => row.id)
      const data = await handleOptimizeDraftOrders(
        {
          draftOrdersIds: affectedRows,
          params: {
            ...createRoutes?.filters,
            all: affectedRows?.length === 0 ? true : createRoutes?.filters?.all,
          },
        },
        selectedDrivers,
        startLocationId
      )
      setOptimizationSummary(data)
      if (createRoutes?.optimizationCallback) {
        createRoutes?.optimizationCallback()
      }
      setCreateRoutes((createRoutesState) => ({
        ...createRoutesState,
        optimizationCompleted: true,
      }))
    } catch (error: unknown) {
      message.error(t("new-orders.DraftOrderTable.optimizeOrdersFailedDesc"))
      throw error
    } finally {
      hideModal()
      setIsLoadingOptimize(false)
    }
  }

  const onConfirm = async () => {
    try {
      await handleOptimize(selectedDrivers, selectedStoreId)
      setSelectedDrivers([])
      setSelectedStoreId(undefined)
      setOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchOptimizationConfig = async () => {
    try {
      const { data: optimizationConfig } = await getOptimizationConfig()
      setOptimizationConfig(optimizationConfig)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (createRoutes?.isOpen) {
      fetchOptimizationConfig()
    }
  }, [createRoutes?.isOpen])

  useEffect(() => {
    // without usePrevious hook, this will be triggered every time the component re-renders
    if (
      selectedDrivers?.length > 0 &&
      previousSelectedStores &&
      !isEqual(previousSelectedStores, selectedStores)
    ) {
      setSelectedDrivers([])
    }
  }, [selectedStores])

  // if no selected drivers but route optimization method is balanced, disable the button
  const isValid =
    (selectedDrivers.length > 0 &&
      optimizationConfig?.optimizationMethod === OptimizationMethod.BALANCED) ||
    optimizationConfig?.optimizationMethod === OptimizationMethod.DISTANCE

  const isAllSelected = !!createRoutes?.filters?.all || createRoutes?.selectedRows?.length === 0

  const stopsToOptimize = isAllSelected
    ? 0
    : createRoutes?.selectedRows?.reduce(
        (prevValue, currentValue) => prevValue + currentValue?.recipients?.length,
        0
      )

  return (
    <>
      <LoadingModal
        title={t("new-orders.BatchSelectors.optimizing") + "..."}
        subtitle={t("new-orders.BatchSelectors.optimizingDesc")}
        open={createRoutes?.isLoadingOptimize}
      />
      <OptimizationSummaryModal
        setOptimizationSummary={setOptimizationSummary}
        optimizationSummary={optimizationSummary?.optimizationSummary}
        drivers={drivers}
        draftOrders={optimizationSummary?.draftOrders || []}
      />
      <Modal
        open={createRoutes?.isOpen}
        onCancel={hideModal}
        destroyOnClose
        footer={null}
        title={
          isUploadMode ? (
            <div className="flex justify-between w-[95%]">
              {t("common.importFile")}
              <UploadTemplateActionsButton />
            </div>
          ) : undefined
        }
      >
        {isUploadMode ? (
          <UploadTemplate
            handleUploadCallback={async (uploadedOrders: DraftOrder[] | IQuotePayload[]) => {
              const uploadedOrdersIds =
                uploadedOrders?.length <= 30
                  ? uploadedOrders?.map((order) => ("id" in order ? order?.id : ""))
                  : []

              sandboxRouter.push(
                {
                  pathname: NEW_ORDERS_PAGE_PATH,
                  query: {
                    ...(uploadedOrders?.length > 30 ? {} : { ids: uploadedOrdersIds?.join("_") }),
                    ...(uploadedOrders?.length > 30 ? { filter: "all" } : {}),
                  },
                },
                undefined,
                sandboxRouter?.pathname?.includes(NEW_ORDERS_PAGE_PATH)
                  ? { shallow: true }
                  : undefined
              )

              hideModal()
            }}
            withPickup={true}
            onCancel={() => setOpen(false)}
          />
        ) : (
          <div id={"confirm-modal"}>
            <div className="flex">
              <div className="mr-3">
                <span className={`inline-block p-2 ${modalTypes?.info.iconBg} rounded-full`}>
                  <FontAwesomeIcon
                    icon={modalTypes?.info.icon as IconProp}
                    className={`mx-1 ${modalTypes?.info.color}`}
                  />
                </span>
              </div>
              <div>
                <Title className="break-all" level={4}>
                  {t("new-orders.RouteOptimizationModal.optimize")}
                </Title>
                <p className="text-gray-500">
                  {t("new-orders.DraftOrderTable.optimizeOrdersConfirmation")}
                </p>
              </div>
            </div>
            <OptimizationForm
              drivers={drivers}
              selectedStoreId={selectedStoreId}
              setSelectedStoreId={setSelectedStoreId}
              setSelectedDrivers={setSelectedDrivers}
              selectedDrivers={selectedDrivers}
              optimizationConfig={optimizationConfig}
              isAllSelected={isAllSelected}
              orderCount={isAllSelected ? createRoutes?.metadata?.total : stopsToOptimize}
            />
            <div className="flex flex-row mt-5">
              <Button
                id="confirm-modal-cancel"
                className="ml-auto mr-3"
                onClick={hideModal}
                disabled={createRoutes?.isLoadingOptimize}
                loading={createRoutes?.isLoadingOptimize}
              >
                {t("common.cancel")}
              </Button>
              <KosmoButton
                id="confirm-modal-ok"
                onClick={async (e) => {
                  await onConfirm()
                  hideModal(e)
                }}
                isDisabled={createRoutes?.isLoadingOptimize || !isValid}
                isLoading={createRoutes?.isLoadingOptimize}
              >
                {t("new-orders.RouteOptimizationModal.optimize")}
              </KosmoButton>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default RouteOptimizationModal
