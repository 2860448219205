export enum IProductType {
  COURIER = "Courier",
  FOOD = "Food Delivery",
  FLOWERS = "Flower Delivery",
  PHARMACY = "Pharmacy",
  GROCERIES = "Grocery",
  FOOD_ALCHOHOL_DISTRIBUTOR = "Food / Alcohol Distributor",
  MARKETPLACE = "Marketplace",
  OTHER = "Other",
}

export const ProductTypes = {
  COURIER: IProductType.COURIER,
  FOOD: IProductType.FOOD,
  FLOWERS: IProductType.FLOWERS,
  PHARMACY: IProductType.PHARMACY,
  GROCERIES: IProductType.GROCERIES,
  FOOD_ALCHOHOL_DISTRIBUTOR: IProductType.FOOD_ALCHOHOL_DISTRIBUTOR,
  MARKETPLACE: IProductType.MARKETPLACE,
  OTHER: IProductType.OTHER,
}
