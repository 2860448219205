import { DistanceUnit } from "@/types/localization.types";

export const humanizeDistance = (distanceInMeters: number = 0, desiredUnit: DistanceUnit, strictUnit = false) => {
  if (desiredUnit === DistanceUnit.MI) {
    return `${(convertKMToMiles(distanceInMeters / 1000)).toFixed(2)} mi`
  }

  if (distanceInMeters < 1000 && !strictUnit) {
    return `${distanceInMeters} m`
  }
  return `${Number.isInteger(distanceInMeters / 1000) ? distanceInMeters / 1000 : (distanceInMeters / 1000).toFixed(2)} km`
}

export const convertKMToMiles = (km: number) => {
  return km * 0.621371;
}

export const getManhattanDistanceInMetersBetweenTheCoords = (
  lat1: number, lng1: number, lat2: number, lng2: number): number => {
  // Convert latitude and longitude from degrees to radians
  const lat1Rad = lat1 * (Math.PI / 180);
  const lng1Rad = lng1 * (Math.PI / 180);
  const lat2Rad = lat2 * (Math.PI / 180);
  const lng2Rad = lng2 * (Math.PI / 180);

  // Calculate difference in coordinates
  const dLat = lat2Rad - lat1Rad;
  const dLng = lng2Rad - lng1Rad;

  // Manhattan distance in radians
  const manhattanDistanceRad = Math.abs(dLat) + Math.abs(dLng);

  // Assuming earthRadius is defined somewhere
  const earthRadius = 6371000; // Earth's radius in meters

  // Convert radians to meters
  const manhattanDistanceMeters = manhattanDistanceRad * earthRadius;

  return Math.round(manhattanDistanceMeters);
}