export interface IPaymentMethod {
  id: string
  stripeID: string
  default: boolean
  brand: PaymentBrandsType
  expMonth: number
  expYear: number
  last4: number
  created: number
  funding: PaymentFundingsType
}

export interface IPaymentsState {
  paymentMethods: [] | IPaymentMethod[]
  paymentsFetched: boolean
}

export enum PaymentBrandsType {
  VISA = "visa",
  MASTERCARD = "mastercard",
  DEFAULT = "DEFAULT",
}

export enum PaymentFundingsType {
  DEBIT = "debit",
  CREDIT = "credit",
  PREPAID = "prepaid",
  UNKNOWN = "debit",
}

export enum DefaultPaymentMethodType {
  CARD = "card",
  BANK_TRANSFER = "bank_transfer",
  UNSPECIFIED = "",
}
