import { useState } from "react"
import { isEmpty } from "lodash"
import { useRecoilState, useRecoilValue } from "recoil"

import { deleteTeam, getTeams, postTeam, putTeam } from "@/api/teams"

import { enrichedTeamsSelector, teamsAtom } from "@/atoms/teamsAtom"

import { CreateTeamPayload, EnrichedTeam, Team } from "@/types/teams.types"
import { driversSelector } from "@/atoms/driversAtom"
import { useOrgUsers } from "./useOrgUsers"
import { Driver } from "@/types/drivers.types"

interface IUseTeams {
  teams: Team[]
  teamsIds: number[]
  enrichedTeams: EnrichedTeam[]
  isLoading: boolean
  fetchTeams: (forceFetch?: boolean) => Promise<void>
  createTeam: (team: CreateTeamPayload) => Promise<void>
  updateTeam: (team: Team) => Promise<void>
  removeTeam: (id: number) => Promise<void>
  findTeamByName: (teamName: string) => Team | undefined
  findDriverByName: (driverName: string) => Driver | undefined
  findDriverById: (driverId: string) => Driver | undefined
}

export const useTeams = (): IUseTeams => {
  const [teams, setTeams] = useRecoilState(teamsAtom)
  const availableDrivers = useRecoilValue(driversSelector)
  const enrichedTeams = useRecoilValue(enrichedTeamsSelector)

  const { isLoading: isLoadingManagers } = useOrgUsers()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchTeams = async (forceFetch?: boolean) => {
    setIsLoading(true)
    try {
      if (isEmpty(teams) || forceFetch) {
        const { data } = await getTeams()
        setTeams(data?.teams)
      }
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const updateTeam = async (team: Team) => {
    setIsLoading(true)
    try {
      const { data } = await putTeam(team)
      setTeams((teams) => teams.map((t) => (t.id === data.id ? data : t)))
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const createTeam = async (team: CreateTeamPayload) => {
    setIsLoading(true)
    try {
      const { data } = await postTeam(team)
      setTeams((teams) => [...teams, data])
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const removeTeam = async (id: number) => {
    setIsLoading(true)
    try {
      await deleteTeam(id)
      setTeams((teams) => teams.filter((team) => team.id !== id))
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const findTeamByName = (teamName?: string) => {
    if (!teamName) {
      return undefined
    }

    return teams?.find(
      (team) => team.name?.trim().toLowerCase() === teamName?.trim()?.toLowerCase()
    )
  }

  const findDriverByName = (driverName?: string) => {
    if (!driverName) {
      return undefined
    }

    return availableDrivers?.find(
      (driver) => driver.name?.trim().toLowerCase() === driverName?.trim()?.toLowerCase()
    )
  }

  const findDriverById = (driverId?: string) => {
    if (!driverId) {
      return undefined
    }

    return availableDrivers?.find((driver) => driver.id === driverId)
  }

  const teamsIds = teams?.map((team) => team?.id)

  return {
    teams,
    isLoading: isLoading || isLoadingManagers,
    fetchTeams,
    updateTeam,
    createTeam,
    removeTeam,
    enrichedTeams,
    teamsIds,
    findTeamByName,
    findDriverByName,
    findDriverById,
  }
}
