import { atom, selector } from 'recoil';
import { Client, ClientWithLocations } from '@/types/clients.types';
import { locationsSelector } from './userAtom';
import { PickupDetailsType } from '@/types/createSingleOrder.types';
import { PriceConfiguration } from '@/types/drivesAppSettings';

export const clientsAtom = atom({
  key: "clientsAtom",
  default: [] as Client[],
})


export const enrichedClientsSelector = selector({
  key: "enrichedClientsSelector",
  get: ({ get }) => {
    const clients = get(clientsAtom)
    const locations = get(locationsSelector)

    const enrichedClients: ClientWithLocations[] = clients?.map((client) => {
      const clientLocations: PickupDetailsType[] = client.locationIds
        ?.map((locationId) => locations.find((location) => location.id === locationId))
        ?.filter((location): location is PickupDetailsType => location !== undefined);
      return {
        ...client,
        locations: clientLocations,
      };
    });

    return enrichedClients
  }
})

export const clientPayAtom = atom({
  key: "clientPayAtom",
  default: [] as PriceConfiguration[],
})