import React from "react"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { brandingColorsSelector } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"

interface ILoaderProps {
  fontSize: string | number
  color?: string
  margin?: string
  size?: "small" | "default" | undefined
  style?: React.CSSProperties
  centered?: boolean
}

export const Loader: React.FC<ILoaderProps> = (props) => {
  const { primaryColor } = useRecoilValue(brandingColorsSelector)

  const Loadericon = (
    <LoadingOutlined
      className={props?.centered ? "absolute left-2/4 top-2/4" : ""}
      style={{
        fontSize: props.fontSize,
        color: props.color || primaryColor,
        margin: props.margin,
        ...props.style,
      }}
      spin
    />
  )
  return (
    <Spin className="flex items-center justify-center" indicator={Loadericon} size={props.size} />
  )
}
