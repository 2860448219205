import React from "react"

import { Dropdown, MenuProps } from "antd"

import { DownOutlined, FileExcelOutlined } from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileArrowDown, faFileImport } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import MenuTabTag from "@/modules/profile/shared/MenuTabTag.component"
import { useFlags } from "flagsmith/react"
import PreventWithModal from "@/UI/PreventWithModal"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import { useRecoilValue } from "recoil"
import { isPayingUserSelector } from "@/atoms/userAtom"
import { PROFILE_PAGE_PATH } from "@/constants/routes"
import {
  DRAFT_GOOGLE_SHEETS_TEMPLATE_LINK_ES,
  DRAFT_GOOGLE_SHEETS_TEMPLATE_LINK_EN,
  DRAFT_XLSX_TEMPLATE_LINK_ES,
  DRAFT_XLSX_TEMPLATE_LINK_EN,
} from "../constants"

interface UploadTemplateActionsButtonProps {
  setImportModal?: (importModal: boolean) => void
  overrideXlsxLink?: string
  overrideGoogleLink?: string
  huge?: boolean
  isPaywallEnabled?: boolean
}

const UploadTemplateActionsButton: React.FC<UploadTemplateActionsButtonProps> = ({
  overrideGoogleLink,
  overrideXlsxLink,
  setImportModal,
  isPaywallEnabled,
}) => {
  const flags = useFlags(["new_template_version_tag"])
  const { i18n } = useTranslation()
  const { language: currentLanguageI18n } = i18n
  const { t } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()
  const isPayingUser = useRecoilValue(isPayingUserSelector)

  const isWithImport = !!setImportModal
  const googleLink =
    overrideGoogleLink ||
    (currentLanguageI18n === "es"
      ? DRAFT_GOOGLE_SHEETS_TEMPLATE_LINK_ES
      : DRAFT_GOOGLE_SHEETS_TEMPLATE_LINK_EN)

  const xlsxLink =
    overrideXlsxLink ||
    (currentLanguageI18n === "es" ? DRAFT_XLSX_TEMPLATE_LINK_ES : DRAFT_XLSX_TEMPLATE_LINK_EN)

  const items: MenuProps["items"] = [
    ...(isWithImport
      ? [
          {
            label: (
              <PreventWithModal
                enable={!!isPaywallEnabled && !isPayingUser}
                label={t("onboarding.freePlanBannerDescription")}
                cta={{
                  label: t("profile.planCard.startNow"),
                  onClick: () => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`),
                }}
              >
                <p>{t("common.importFile")}</p>
              </PreventWithModal>
            ),
            key: "2",
            icon: <FontAwesomeIcon className="flex flex-shrink-0 h-3" icon={faFileImport} />,
            onClick: () => {
              if (!!isPaywallEnabled && !isPayingUser) return
              setImportModal(true)
            },
          },
        ]
      : []),
    {
      label: (
        <div className="flex gap-x-2 items-center">
          <p>{t("new-orders.xlsxTemplate")}</p>
          {flags.new_template_version_tag.enabled && <MenuTabTag type="new" />}
        </div>
      ),
      key: "3",
      icon: <FontAwesomeIcon className="flex flex-shrink-0 h-3" icon={faFileArrowDown} />,
      onClick: () => {
        window.open(xlsxLink)
      },
    },
    {
      label: (
        <div className="flex gap-x-2 items-center">
          <p>{t("new-orders.DraftOrderUpload.googleSheetsTemplate")}</p>
          {flags.new_template_version_tag.enabled && <MenuTabTag type="new" />}
        </div>
      ),
      key: "4",
      icon: <FontAwesomeIcon className="flex flex-shrink-0 h-3" icon={faFileArrowDown} />,
      onClick: () => {
        window.open(googleLink)
      },
    },
  ]

  const menuProps = {
    items,
  }

  return (
    <Dropdown
      menu={menuProps}
      placement="bottomRight"
      className={`kosmo-btn-base`}
      trigger={["click"]}
    >
      <KosmoButton id="import-dropdown" type={isWithImport ? "primary" : "default"}>
        <div className="flex items-center gap-x-2">
          <FileExcelOutlined className="text-sm leading-[0]" />
          {isWithImport ? t("common.importOrders") : t("common.templates")}
          <DownOutlined className="text-[10px] leading-[0]" />
        </div>
      </KosmoButton>
    </Dropdown>
  )
}

export default UploadTemplateActionsButton
