import { DestinationStatuses } from "@/types/orders.types"
import { OrderStatusesTypes } from "@/types/statuses.types"

export const isOrderStatusFinal = (status: OrderStatusesTypes) => {
  return [
    OrderStatusesTypes.DELIVERED,
    OrderStatusesTypes.CANCELLED,
    OrderStatusesTypes.RETURNED,
  ].includes(status)
}

export const isOrderStatusEditable = (status?: OrderStatusesTypes) => {
  if (!status) {
    return false
  }
  return ![
    OrderStatusesTypes.CANCELLED,
    OrderStatusesTypes.DELIVERED,
    OrderStatusesTypes.RETURNED,
  ].includes(status)
}

export const isStopStatusFinal = (status?: DestinationStatuses) => {
  if (!status) {
    return false
  }

  return [DestinationStatuses.CANCELED, DestinationStatuses.RETURNED, DestinationStatuses.DELIVERED].includes(status)
}

export const getStopStatusTextKey = (status: DestinationStatuses): string => {
  switch (status) {
    case DestinationStatuses.CANCELED:
      return "common.order-status.cancelled"
    case DestinationStatuses.DELIVERED:
      return "common.order-status.delivered"
    case DestinationStatuses.RETURNING:
      return "common.order-status.returning"
    case DestinationStatuses.RETURNED:
      return "common.order-status.returned"
    case DestinationStatuses.TO_DROPOFF:
      return "common.order-status.stopToDropoff"
    case DestinationStatuses.EMPTY:
    default:
      return "common.order-status.submitted"
  }
}

export const getOrderStatusTextKey = (status: OrderStatusesTypes) => {
  switch (status) {
    case OrderStatusesTypes.CANCELLED:
      return "common.order-status.cancelled"
    case OrderStatusesTypes.DELIVERED:
      return "common.order-status.delivered"
    case OrderStatusesTypes.TO_PICKUP:
      return "common.order-status.toPickup"
    case OrderStatusesTypes.PICKED_UP:
      return "common.order-status.picked_up"
    case OrderStatusesTypes.SUBMITTED:
      return "common.order-status.submitted"
    case OrderStatusesTypes.CREATED:
      return "common.order-status.created"
    case OrderStatusesTypes.RETURNING:
      return "common.order-status.returning"
    case OrderStatusesTypes.RETURNED:
      return "common.order-status.returned"
    case OrderStatusesTypes.AT_HUB:
      return "common.order-status.atHub"
    case OrderStatusesTypes.TO_DROPOFF:
      return "common.order-status.to_dropoff.status"
    case OrderStatusesTypes.IN_PROGRESS:
      return "common.order-status.in_progress"
    case OrderStatusesTypes.DELAYED:
      return "common.order-status.delayed"
    case OrderStatusesTypes.FAILED:
      return "common.order-status.failed"
    case OrderStatusesTypes.DRIVER_REJECTED_EVENT:
      return "common.driverRejects"
    case OrderStatusesTypes.EMPTY:
      return "common.order-status.created"
  }
}
