import { atom } from "recoil"
import { Currency } from "@/types/currencies.types"

export const currencyAtom = atom({
  key: "currency",
  default: {
    code: "SGD",
    symbol: "$S",
  } as Currency,
})
