import { Auth0Lock } from "auth0-lock"
import { isEmpty } from "lodash"
import { BASKIT_BRANDING } from "./ThemeProvider/InjectBranding"

const themeOptions: Auth0LockThemeOptions = {
  logo: "https://app.kosmo.delivery/assets/images/logo.svg",
  primaryColor: "black",
  authButtons: {},
}

export interface AuthorizedLoginQueryParams {
  query: {
    redirectPath?: string
    referralCode?: string
  }
}

export interface LockParams {
  language?: "es" | "en"
  state?: { query: object }
  redirect?: boolean
  initialScreen?: "login" | "signUp" | "forgotPassword"
  prefill?: {
    email: string
  }
  allowSignup?: boolean
  allowLogin?: boolean
  customSubdomain?: "baskit" | undefined
}

// Can be the source of SSR errors due to dompurify dependency
const getLock = (params?: LockParams) => {
  const allowSignup = params?.allowSignup
  const allowLogin = params?.allowLogin

  const options: Auth0LockConstructorOptions = {
    prefill: params?.prefill,
    auth: {
      audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      redirectUrl:
        params?.customSubdomain === "baskit"
          ? "https://baskit.kosmo.delivery/callback"
          : `${process.env.NEXT_PUBLIC_APP_HOST}/callback`,
      redirect: params?.redirect === false ? false : true,
      responseType: "token",
      params: {
        scope: "openid profile email offline_access",
        state: !isEmpty(params?.state) ? JSON.stringify(params?.state) : "",
      },
    },
    rememberLastLogin: false,
    language: params?.language,
    languageDictionary: {
      title: params?.customSubdomain === "baskit" ? "" : "Kosmo",
      signUpTitle: params?.customSubdomain === "baskit" ? "" : "Kosmo",
      signUpTerms:
        params?.language === "es"
          ? "Al registrarse, acepta nuestros <a href='https://www.kosmo.delivery/terms-conditions' target='_blank'>Términos y Condiciones</a> y <a href='https://www.kosmo.delivery/privacy-policy' target='_blank'>Política de Privacidad</a>."
          : "By signing up, you agree to our <a href='https://www.kosmo.delivery/terms-conditions' target='_blank'>Terms and Conditions</a> and <a href='https://www.kosmo.delivery/privacy-policy' target='_blank'>Privacy Policy</a>.",
    },
    closable: false,
    container: "auth-container",
    initialScreen: params?.initialScreen,
    allowSignUp: allowSignup,
    allowLogin: allowLogin,
  }

  const authLock = new Auth0Lock(
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || "",
    process.env.NEXT_PUBLIC_AUTH0_DOMAIN || "",
    {
      ...options,
      theme: {
        ...themeOptions,
        logo:
          params?.customSubdomain === "baskit" ? BASKIT_BRANDING.companyLogoUrl : themeOptions.logo,
      },
    }
  )

  return authLock
}

export { getLock }
