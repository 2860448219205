import React, { ReactElement, useState } from "react"
import { Modal } from "antd"

import { useTranslation } from "react-i18next"
import { Driver } from "@/types/drivers.types"
import DriverForm from "./DriverForm"
import { isPayingUserSelector } from "@/atoms/userAtom"
import { PROFILE_PAGE_PATH } from "@/constants/routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import PreventWithModal from "@/UI/PreventWithModal"
import { useRecoilValue } from "recoil"

interface DriverModalProps {
  button: ReactElement
  defaultTeamId?: number
  driver?: Driver
}

const DriverModal: React.FC<DriverModalProps> = ({ button, defaultTeamId, driver }) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { sandboxRouter } = useSandboxRouter()
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const showModal = () => {
    setIsModalVisible(true)
  }

  if (!isPayingUser) {
    return (
      <PreventWithModal
        enable={!isPayingUser}
        label={t("onboarding.freePlanBannerDescription")}
        cta={{
          label: t("profile.planCard.startNow"),
          onClick: () => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`),
        }}
      >
        {button}
      </PreventWithModal>
    )
  }

  return (
    <>
      {React.cloneElement(button, { onClick: showModal })}
      <Modal
        open={isModalVisible}
        footer={null}
        closable={true}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        zIndex={3000}
        styles={{ body: { paddingTop: "0px" } }}
        title={driver ? t("profile.DriversSettings.editDriver") : t("common.addDriver")}
      >
        <DriverForm
          cancelCallback={() => setIsModalVisible(false)}
          driver={driver}
          defaultTeamId={defaultTeamId}
        />
      </Modal>
    </>
  )
}

export default DriverModal
