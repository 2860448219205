import { CountryKeys, Locale } from "@/types/localization.types"

export const LOCALES: Record<CountryKeys, Locale> = {
  [CountryKeys.SG]: {
    currencyCode: "SGD",
    headerLanguageCode: "en-us",
    displayName: "Singapore",
    timezone: "Singapore",
    timezoneDisplay: "Singapore",
    defaultCoordinates: {
      lat: 1.2847,
      lng: 103.861,
    },
  },
  [CountryKeys.BR]: {
    currencyCode: "BRL",
    headerLanguageCode: "pt-br",
    displayName: "Brazil",
    timezone: "America/Sao_Paulo",
    timezoneDisplay: "São Paulo",
    defaultCoordinates: {
      lat: -23.533773,
      lng: -46.62529,
    },
  },
  [CountryKeys.MY]: {
    currencyCode: "MYR",
    headerLanguageCode: "ms-MY",
    displayName: "Malaysia",
    timezone: "Asia/Kuala_Lumpur",
    timezoneDisplay: "Kuala Lumpur",
    defaultCoordinates: {
      lat: 3.139,
      lng: 101.6869,
    },
  },
  [CountryKeys.TH]: {
    currencyCode: "THB",
    headerLanguageCode: "th",
    displayName: "Thailand",
    timezone: "Asia/Bangkok",
    timezoneDisplay: "Bangkok",
    defaultCoordinates: {
      lat: 13.7563,
      lng: 100.5018,
    },
  },
  [CountryKeys.PH]: {
    currencyCode: "PHP",
    headerLanguageCode: "en-us",
    displayName: "Philippines",
    timezone: "Asia/Manila",
    timezoneDisplay: "Manila",
    defaultCoordinates: {
      lat: 14.5995,
      lng: 120.9842,
    },
  },
  [CountryKeys.ID]: {
    currencyCode: "IDR",
    headerLanguageCode: "id",
    displayName: "Indonesia",
    timezone: "Asia/Jakarta",
    timezoneDisplay: "Jakarta",
    defaultCoordinates: {
      lat: -6.1751,
      lng: 106.865,
    },
  },
  [CountryKeys.CO]: {
    currencyCode: "COP",
    headerLanguageCode: "es",
    displayName: "Colombia",
    timezone: "America/Bogota",
    timezoneDisplay: "Bogota",
    defaultCoordinates: {
      lat: 4.710989,
      lng: -74.072092,
    },
  },
  [CountryKeys.DE]: {
    currencyCode: "EUR",
    headerLanguageCode: "de",
    displayName: "Germany",
    timezone: "Europe/Berlin",
    timezoneDisplay: "Berlin",
    defaultCoordinates: {
      lat: 52.520008,
      lng: 13.404954,
    },
  },
  [CountryKeys.IT]: {
    currencyCode: "EUR",
    headerLanguageCode: "it",
    displayName: "Italy",
    timezone: "Europe/Rome",
    timezoneDisplay: "Rome",
    defaultCoordinates: {
      lat: 41.902782,
      lng: 12.496366,
    },
  },
  [CountryKeys.ES]: {
    currencyCode: "EUR",
    headerLanguageCode: "es",
    displayName: "Spain",
    timezone: "Europe/Madrid",
    timezoneDisplay: "Madrid",
    defaultCoordinates: {
      lat: 40.416775,
      lng: -3.70379,
    },
  },
  [CountryKeys.FR]: {
    currencyCode: "EUR",
    headerLanguageCode: "fr",
    displayName: "France",
    timezone: "Europe/Paris",
    timezoneDisplay: "Paris",
    defaultCoordinates: {
      lat: 48.856613,
      lng: 2.352222,
    },
  },
  [CountryKeys.NL]: {
    currencyCode: "EUR",
    headerLanguageCode: "nl",
    displayName: "Netherlands",
    timezone: "Europe/Amsterdam",
    timezoneDisplay: "Amsterdam",
    defaultCoordinates: {
      lat: 52.370216,
      lng: 4.895168,
    },
  },
  [CountryKeys.HK]: {
    currencyCode: "HKD",
    headerLanguageCode: "zh-hk",
    displayName: "Hong Kong",
    timezone: "Asia/Hong_Kong",
    timezoneDisplay: "Hong Kong",
    defaultCoordinates: {
      lat: 22.3193,
      lng: 114.1694,
    },
  },
  [CountryKeys.JP]: {
    currencyCode: "JPY",
    headerLanguageCode: "ja",
    displayName: "Japan",
    timezone: "Asia/Tokyo",
    timezoneDisplay: "Tokyo",
    defaultCoordinates: {
      lat: 35.6762,
      lng: 139.6503,
    },
  },
  [CountryKeys.PA]: {
    currencyCode: "USD",
    headerLanguageCode: "en-us",
    displayName: "Panama",
    timezone: "America/Panama",
    timezoneDisplay: "Panama",
    defaultCoordinates: {
      lat: 8.9824,
      lng: -79.5199,
    },
  },
  [CountryKeys.AT]: {
    currencyCode: "EUR",
    headerLanguageCode: "de",
    displayName: "Austria",
    timezone: "Europe/Vienna",
    timezoneDisplay: "Vienna",
    defaultCoordinates: {
      lat: 48.2082,
      lng: 16.3738,
    },
  },
  [CountryKeys.HU]: {
    currencyCode: "HUF",
    headerLanguageCode: "hu",
    displayName: "Hungary",
    timezone: "Europe/Budapest",
    timezoneDisplay: "Budapest",
    defaultCoordinates: {
      lat: 47.4979,
      lng: 19.0402,
    },
  },
  [CountryKeys.VE]: {
    currencyCode: "USD",
    headerLanguageCode: "es",
    displayName: "Venezuela",
    timezone: "America/Caracas",
    timezoneDisplay: "Caracas",
    defaultCoordinates: {
      lat: 10.4806,
      lng: -66.9036,
    },
  },
  [CountryKeys.TW]: {
    currencyCode: "TWD",
    headerLanguageCode: "zh-tw",
    displayName: "Taiwan",
    timezone: "Asia/Taipei",
    timezoneDisplay: "Taipei",
    defaultCoordinates: {
      lat: 25.032969,
      lng: 121.565418,
    },
  },
}
