import { TFunction } from "i18next"
import { ErrorNotification, SuccessNotification } from "./NotificationTemplate.component"

const openCancelOrderSuccess = (t: TFunction) => {
  SuccessNotification(
    t("common.notification.orderCancelled"),
    t("common.notification.orderCancelledSuccess"),
    "topRight"
  )
}

const openStoreAddedSuccess = (t: TFunction) => {
  SuccessNotification(
    t("common.notification.addedNewPickupLocation"),
    t("common.notification.addedNewPickupLocationSuccess"),
    "topRight"
  )
}

const openStoreEditedSuccess = (t: TFunction) => {
  SuccessNotification(
    t("common.notification.storeUpdated"),
    t("common.notification.storeUpdatedSuccess"),
    "topRight"
  )
}

const openStoreDeletedSuccess = (t: TFunction) => {
  SuccessNotification(
    t("common.notification.storeDeleted"),
    t("common.notification.storeDeletedSuccess"),
    "topRight"
  )
}

const openStoreError = (t: TFunction) => {
  ErrorNotification(
    t("common.notification.somethingWentWrong"),
    t("common.notification.somethingWentWrongDetails"),
    "topRight"
  )
}

const openPaymentDeletedSuccess = (t: TFunction) => {
  SuccessNotification(
    t("common.notification.paymentDeleted"),
    t("common.notification.paymentDeletedSuccess"),
    "topRight"
  )
}

const openDefaultPaymentSuccess = (t: TFunction) => {
  SuccessNotification(
    t("common.notification.defaultPaymentSet"),
    t("common.notification.defaultPaymentSetSuccess"),
    "topRight"
  )
}

const openDefaultPaymentFailed = (t: TFunction) => {
  SuccessNotification(
    t("common.notification.defaultPaymentFailed"),
    t("common.notification.defaultPaymentSetFailed"),
    "topRight"
  )
}

export {
  openCancelOrderSuccess,
  openStoreAddedSuccess,
  openStoreEditedSuccess,
  openStoreDeletedSuccess,
  openStoreError,
  openPaymentDeletedSuccess,
  openDefaultPaymentSuccess,
  openDefaultPaymentFailed,
}
