import { Route } from "@/types/routes"
import { ACTIVE_ORDERS_PAGE_PATH, LOGIN_PAGE_PATH, ONBOARDING_PAGE_PATH } from "@/constants/routes"

export const scanRoute: Route = {
  isPublic: false,
  shouldRedirect: ({ isAuth, hasOrg, sandboxEnabled }) =>
    !isAuth || !hasOrg || sandboxEnabled,
  redirectTo: ({ hasOrg, isAuth, query, sandboxEnabled }) => {
    if (isAuth) {
      if (!hasOrg) {
        return { newPath: ONBOARDING_PAGE_PATH, queryParams: query }
      }
      if (sandboxEnabled) {
        return { newPath: ACTIVE_ORDERS_PAGE_PATH, queryParams: query }
      }
    }
    return { newPath: LOGIN_PAGE_PATH, queryParams: query }
  },
}
