import { Driver } from "@/types/drivers.types"
import { atom, selector } from "recoil"

export const driversAtom = atom({
  key: "driversAtom",
  default: [] as Driver[],
})

export const driversSelector = selector({
  key: "driversSelector",
  get: ({ get }) => {
    const drivers = get(driversAtom)

    return [...drivers]?.sort((a, b) => {
      return a.name < b.name ? -1 : 1
    })
  },
})
