export enum OrderStatusesTypes {
  SUBMITTED = "submitted",
  CREATED = "created",
  TO_PICKUP = "to_pickup",

  PICKED_UP = "picked_up",
  IN_PROGRESS = "in_progress",
  DELIVERED = "delivered",
  CANCELLED = "cancelled",

  TO_DROPOFF = "to_dropoff",
  RETURNING = "returning",
  RETURNED = "returned",
  AT_HUB = "at_hub",

  FAILED = "failed",
  DELAYED = "delayed",

  DRIVER_REJECTED_EVENT = "driver_rejected_event",

  EMPTY = "",
}

export const SORTED_STATUSES = [
  OrderStatusesTypes.TO_PICKUP,
  OrderStatusesTypes.PICKED_UP,
  OrderStatusesTypes.TO_DROPOFF,
  OrderStatusesTypes.AT_HUB,
  OrderStatusesTypes.RETURNING,
  OrderStatusesTypes.RETURNED,
  OrderStatusesTypes.DELIVERED,
  OrderStatusesTypes.CANCELLED,
]
