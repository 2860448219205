import { useRecoilState } from "recoil"
import { defaultUserState, userAtom } from "@/atoms/userAtom"
import axios from "@/modules/shared/AxiosInterceptor"
import { IUser } from "@/types/user.types"
import { getUserProfile } from "@/api/users"
import { deleteActiveOrg, logoutJwtUser, setActiveOrg } from "@/utils/localStorage"

import { AxiosResponse } from "axios"
import { getLock } from "@/modules/shared/AuthLock"
import { useSandboxRouter } from "./useSandboxRouter"
import { INVITATION_PAGE_PATH } from "@/constants/routes"
import { getStores } from "@/api/stores"
import { isEmpty } from "lodash"
import { useDispatchRule } from "./useDispatchRule"
import { useDrivers } from "./useDrivers"
import { useTeams } from "./useTeams"

import { useZones } from "./useZones"
import { useDriverSettings } from "./useDriverSettings"
import { getVersion } from "@/api/version"
import { useOrgUsers } from "./useOrgUsers"
import { BASKIT_BRANDING } from "@/modules/shared/ThemeProvider/InjectBranding"
import { useClients } from "./useClients"
import { LOCAL_STORAGE_DATE_KEY } from "@/modules/profile/GeneralSettings/components/DateFormatSelect"
import { DATE_FORMAT_REGIONS } from "@/utils/datetime"

interface IUseUser {
  logout: () => void
  hydrateUserAccount: () => Promise<void>
  verifyAppVersion: () => Promise<void>
  fetchUserProfile: (
    orgName?: string,
    isNewUser?: boolean
  ) => Promise<AxiosResponse<IUser, any> | void>
  fetchStores: () => Promise<void>
  user: IUser
}

const useUser = (preventHydratation: boolean = false): IUseUser => {
  const { shutdownIntercom } = require("next-intercom")
  const [user, setUser] = useRecoilState(userAtom)
  const { fetchZones } = useZones()
  const { fetchDrivers } = useDrivers()
  const { fetchTeams } = useTeams()
  const { fetchClients } = useClients()
  const { fetchOrgUsers } = useOrgUsers()
  const { fetchDispatchRules } = useDispatchRule()
  const { fetchDriverSettings } = useDriverSettings()
  const { sandboxRouter } = useSandboxRouter()

  const fetchStores = async () => {
    try {
      const { data } = await getStores()
      const { stores } = data
      if (!isEmpty(stores)) {
        setUser((user) => ({
          ...user,
          stores: stores?.filter((store) => store.latitude && store.longitude),
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchUserProfile = async (
    orgName?: string,
    isNewUser?: boolean
  ): Promise<AxiosResponse<IUser, any> | void> => {
    try {
      if (!isNewUser) {
        await verifyAppVersion()
      }

      const response = await getUserProfile()

      const { data } = response

      if (!data) {
        setUser({ ...defaultUserState, userFetched: true })
        return undefined
      }

      const preferredDateFormat = localStorage?.getItem(LOCAL_STORAGE_DATE_KEY)
      const validStores = data?.stores?.filter((store) => store.latitude && store.longitude)
      const defaultOrgId = data?.orgs?.find((org) => org.name === orgName)?.id || data?.org?.id

      if (defaultOrgId) {
        setActiveOrg(defaultOrgId)
      } else {
        deleteActiveOrg()
      }

      setUser((userState) => ({
        ...defaultUserState,
        ...data,
        // Avoid overriding baskit branding injection
        ...(userState.customSubdomain === "baskit"
          ? { preference: { ...data?.preference, branding: BASKIT_BRANDING } }
          : {}),
        customSubdomain: userState.customSubdomain,
        stores: validStores,
        userFetched: true,
        newVersionAvailable: userState.newVersionAvailable,
        preferredDateFormat: (preferredDateFormat || DATE_FORMAT_REGIONS.EU) as DATE_FORMAT_REGIONS,
      }))

      if (!preventHydratation && data.org?.id) {
        await hydrateUserAccount(data?.org?.id)
      }

      return response
    } catch (error: any) {
      if (error?.response?.status === 403) {
        deleteActiveOrg()
        delete axios.defaults.headers.common["x-org-id"]
        await fetchUserProfile("")
        return
      }
      setUser({
        ...user,
        userFetched: true,
      })
      return
    }
  }

  const verifyAppVersion = async () => {
    getVersion()
      .then((data) => {
        const version = window.localStorage.getItem("version")
        if (!!data?.version && version !== data?.version) {
          setUser((userState) => ({ ...userState, newVersionAvailable: data.version }))
          window.localStorage?.setItem("version", data?.version)
        }
      })
      .catch((err) => console.log(err))
  }

  const hydrateUserAccount = async (targetOrgId?: string) => {
    fetchTeams(true)
    fetchDrivers(true, targetOrgId)
    fetchClients(true)
    fetchOrgUsers()
    fetchZones()
    fetchDispatchRules()
    fetchDriverSettings()
  }

  const logout = async () => {
    const lock = getLock()

    lock.logout({
      returnTo:
        sandboxRouter?.pathname === INVITATION_PAGE_PATH
          ? `${window.location.origin}${sandboxRouter?.asPath}`
          : `${window.location.origin}/login`,
    })

    logoutJwtUser()
    setUser({ ...defaultUserState, userFetched: true })
    shutdownIntercom()
    delete axios.defaults.headers.common["Authorization"]
  }

  return {
    logout,
    user,
    hydrateUserAccount,
    fetchUserProfile,
    fetchStores,
    verifyAppVersion,
  }
}

export { useUser }
